import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSeekerEmail } from '../store';
import { useNavigate } from 'react-router-dom';
import seekerFAQ from './seekerFAQ';
import axios from 'axios';
import { useWeb3ModalProvider, useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers5/react';

import  toast  from 'react-hot-toast';

export default function LoginHeader() {

  const navigate = useNavigate();

  const email = useSelector((state) => state.seeker_email);
  console.log("Email Logged In2222222", email)

  const dispatch = useDispatch();


  // const signIn = useSignIn();


  const { disconnect } = useDisconnect();

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [isConnectwallet, setConnectwallet] = useState(false);
  console.log("address, chainId, isConnected", address, chainId, isConnected);


  const Logout = () => {


    dispatch(setSeekerEmail(""));
    window.location = '/';
  };


  const [isDropdown, setDropdown] = useState(false);
  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownVisible(!dropdownVisible);
  };



  const [verifystep, setSeekerverifystep] = useState('');
  const [SeekerWalletAddress, setSeekerWalletAddress] = useState('')

  // console.log("SeekerWalletAddress", SeekerWalletAddress)





  useEffect(() => {
    const fetchData_seeker = async (email) => {
      try {
        const response = await axios.get(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${email}`);
        console.log("response", response);
        const responseData = response.data;

        if (responseData && responseData.data && responseData.data.length > 0) {


          const data = responseData.data[0];
          //console.log("dattaaaa", data.original_walletAddress);



          if (data) {
            setSeekerverifystep(data.verify_step);
            setSeekerWalletAddress(data.original_walletAddress);
          }


        } else {
          console.error('No data found in response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    if (email) {
      fetchData_seeker(email);
    }
  }, [email]);



  // update wallet address

  const handleWallet = async () => {
    if (isConnectwallet) {
      disconnect();
      setConnectwallet(false);
      console.log("Wallet disconnected");
    } else {
      setConnectwallet(true);
      try {
        if (!isConnected) {
          toast.error('Please connect your wallet');
          return;
        }

        if (address && (!SeekerWalletAddress || SeekerWalletAddress.trim() === '')) {
          await axios.post('https://ex.d5art.com/api/seeker_update_wallet_address', {
            email,
            walletAddress: address,
          });

          setSeekerWalletAddress(address);
          setConnectwallet(true);
          toast.success('Wallet connected successfully');
        } else {
          toast.info('Wallet is already connected');
        }
      } catch (error) {
        console.error('Error connecting wallet:', error);
        toast.error('Error connecting wallet');
      }
    }
  };

  useEffect(() => {
    if (SeekerWalletAddress) {
      setConnectwallet(false);
    }
  }, [SeekerWalletAddress]);

  const [ExpandSkill, setExpandSkill] = useState(false)
  const [ExpandCourse, setExpandCourse] = useState(false)

  const handleExpandSkill = () => {
    setExpandSkill(!ExpandSkill);
  };

  const handleExpandCourse = () => {
    setExpandCourse(!ExpandCourse);
  };

  // Only open the hovered button's dropdown
  const handleMouseEnterCourse = () => {
    setExpandCourse(true);
  };

  const handleMouseLeaveCourse = () => {
    setExpandCourse(false);
  };

  const handleMouseEnterSkill = () => {
    setExpandSkill(true);
  };

  const handleMouseLeaveSkill = () => {
    setExpandSkill(false);
  };





  return (
    <div>
      <nav className='flex p-7 lg:px-10 font-poppins'>
        <div className='w-[85%] lg:w-[15%]'>
          <div className='w-[80%]'>
            <a href='https://d5art.com/'>  <img src='/assets/d5art_white.png' className='h-10'></img> </a>
          </div>
        </div>
        <div className='w-[15%] lg:w-[85%] hidden lg:block'>
          <div className='flex'>
            <div className='w-[80%] relative text-gray-300 font-bold flex justify-center gap-12 mt-3  '>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
               href='https://d5art.com/'>Home</a>
              <div>
              <div className='relative' onMouseLeave={() => { handleMouseLeaveCourse(); }}>
              <a href='https://edu.d5art.com'>
                <button
                  className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
                  onClick={handleExpandCourse}
                  onMouseEnter={handleMouseEnterCourse}
                >
                  Courses
                  <span className='ml-2'>&#9660;</span> {/* Unicode down arrow */}
                </button>
                </a>
                <div
                  className={`absolute z-20 -left-[75%] mt-2 rounded-md bg-black/75 backdrop-blur-sm w-[10rem] transition-all duration-700 overflow-y-auto 
          ${ExpandCourse ? 'max-h-[300px] border border-white/30' : 'max-h-0'}`}
                >
                  <ul className="p-5 text-sm text-center flex flex-col gap-2 divide-transparent">
                  <a href='https://edu.d5art.com/Courses' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Explore Courses</a>
                    <a href='https://edu.d5art.com/dashboard' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Learning Dashboard</a>
                    <a href='https://edu.d5art.com/dashboard?tab=certificate' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Certificate</a>
                    <a href='https://edu.d5art.com/dashboard?tab=wallet' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Wallet</a>
                    <a href='https://edu.d5art.com/dashboard?tab=result' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Assessment</a>
                    
                  </ul>
                </div>
              </div>
              </div>

              <div className='relative'  onMouseLeave={() => { handleMouseLeaveSkill(); }}>
                <a href='/RMS/Seeker/skillarenalist'>
                <button
                  className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
                  onClick={handleExpandSkill}
                  onMouseEnter={handleMouseEnterSkill}
                >
                  Skill Exchange
                  <span className='ml-2'>&#9660;</span> {/* Unicode down arrow */}
                </button>
                </a>
                <div
                  className={`absolute z-20 -left-6 mt-2 rounded-md bg-black/75 backdrop-blur-sm w-[10rem] transition-all duration-700 overflow-y-auto 
                    ${ExpandSkill ? 'max-h-[300px] border border-white/30' : 'max-h-0'}`}
                >
                  <ul className="p-5 text-sm text-center flex flex-col gap-2 divide-transparent">
                 
                  <a href='/RMS/Seeker/SeekerDashboard?tab=nft' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>My NFT</a>
                    <a href='/RMS/Seeker/SeekerDashboard?tab=offers' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>My Offers</a>
                    <a href='/RMS/Seeker/SeekerDashboard?tab=chat' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Chat Window</a>
                    <a href='/RMS/Seeker/SeekerDashboard?tab=wallet' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Wallet</a>
                  </ul>
                </div>
              </div>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='https://ex.d5art.com/jordan-event' >Events</a>
              {/* <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/ex/seeker/faq'>FAQ</a> */}
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/event/start-assessment'>Know Yourself</a>
              <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='https://d5art.com/dna-home'>DNA</a>
            </div>

            <div className='flex-justify-end w-[20%] '>
              <div className="relative">
                <button className="flex py-2  text-white" onClick={handleDropdownToggle}>
                  <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
                  <div className='absolute -translate-x-2 -translate-y-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div>{email.substr(0, 10) + "...."}
                  <i className="fa-solid fa-chevron-down pl-3 mt-1"></i>
                </button>

                {dropdownVisible && (
                  <div className="absolute top-full z-20 left-0 mt-2 bg-white border border-gray-300 rounded-md shadow-md animate__animated animate__fadeInUp ">
                    <div className='py-2 px-5 w-[13rem] rounded-md bg-black text-white'>
                      {verifystep === 'step5' && (
                        <a href='/RMS/Seeker/SeekerDashboard' className='text-center'>
                          <i className="fa-solid fa-user pr-3"></i> Dashboard
                        </a>
                      )}
                      {(verifystep === 'RMSstep1' || verifystep === 'step1' || verifystep === 'step2' || verifystep === 'step3' || verifystep === 'step4' || verifystep === null || verifystep === '') && (
                        <a href='/updatedetails' className='text-center'>
                          <i className="fa-solid fa-user pr-3"></i> Update Details
                        </a>
                      )}
                      {(verifystep === 'RMSstep1' || verifystep === 'step1' || verifystep === 'step2' || verifystep === 'step3' || verifystep === 'step4' || verifystep === null || verifystep === '') && (
                        <a href='/event/start-assessment' className='text-center'>
                          <i className="fa-solid fa-user pr-3"></i> Know Yourself
                        </a>
                      )}
                           {(verifystep === 'RMSstep1' || verifystep === 'step1' || verifystep === 'step2' || verifystep === 'step3' || verifystep === 'step4' || verifystep === null || verifystep === '') && (
                        <a href='https://d5art.com/dna-home' className='text-center'>
                          <i className="fa-solid fa-user pr-3"></i> DNA
                        </a>
                      )}
                      {(!SeekerWalletAddress || SeekerWalletAddress.trim() === '') && (
                        <button onClick={handleWallet} className='text-center'>
                          <i className='fa-solid fa-wallet pr-3'></i>Connect wallet
                        </button>
                      )}
                      <button onClick={Logout} className='text-center'>
                        <i className='fa-solid fa-door-open pr-3'></i>Log out
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {isConnectwallet && (
          <div className="z-20 w-full fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm ">
            <div className='w-[90%] lg:w-[35%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700  lg:mt-0 animate__animated animate__fadeInUp' >
              <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={() => setConnectwallet(false)}></i></div>
              <div className='w-[100%]  px-5 pb-5 text-white grid content-around'>

                <p className=' text-lg font-bold text-center mt-3'>Connect Wallet :
                </p>
                <div className='flex justify-center mt-3' >
                  <w3m-button balance="hide" />
                </div>

              </div>
            </div>
          </div>
        )}
        <div className='w-[15%] table lg:hidden grid content-center'>
          <div className='flex justify-center '>
            <button onClick={handleDropdown}>
              <button className="p-3 rounded-full border border-sky-500 mr-3"></button>
              <div className='absolute -translate-y-10 -translate-x-2'><img className='h-[3rem]' src='/assets/image 90.png'></img></div>
            </button>

            {isDropdown && (
              <div className='dropdown-content text-black z-20 w-[10rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp'>
                <div className='flex flex-col gap-3 bg-black text-center text-white rounded-lg p-5'>
                  <a href='https://ex.d5art.com/'>Home</a>
                  <a href='https://edu.d5art.com/' className=''>Courses</a>
                  <a href='/RMS/Seeker/SkillArena'>Skill Exchange</a>
                  <a href='https://ex.d5art.com/jordan-event' className=''>Events</a>
                  {/* <a href='/ex/seeker/faq' className=''>FAQ</a> */}
                  <a href='/event/start-assessment' className=''>Know Yourself</a>
                  <a href='https://d5art.com/dna-home' className=''>DNA</a>
                  {verifystep === 'step5' && (
                    <a href='/RMS/Seeker/SeekerDashboard' className='text-center'>
                      <i className="fa-solid fa-user pr-3"></i> Dashboard
                    </a>
                  )}
                  {(verifystep === 'RMSstep1' || verifystep === 'step1' || verifystep === 'step2' || verifystep === 'step3' || verifystep === 'step4' || verifystep === null || verifystep === '') && (
                    <a href='/updatedetails' className='text-center'>
                      <i className="fa-solid fa-user pr-3"></i> Update Details
                    </a>
                  )}
                  {(verifystep === 'RMSstep1' || verifystep === 'step1' || verifystep === 'step2' || verifystep === 'step3' || verifystep === 'step4' || verifystep === null || verifystep === '') && (
                    <a href='/event/start-assessment' className='text-center'>
                      <i className="fa-solid fa-user pr-3"></i> Know Yourself
                    </a>
                  )}
                      {(verifystep === 'RMSstep1' || verifystep === 'step1' || verifystep === 'step2' || verifystep === 'step3' || verifystep === 'step4' || verifystep === null || verifystep === '') && (
                    <a href='https://d5art.com/dna-home' className='text-center'>
                      <i className="fa-solid fa-user pr-3"></i> DNA 
                    </a>
                  )}
                  {(!SeekerWalletAddress || SeekerWalletAddress.trim() === '') && (
                    <button onClick={handleWallet} className='text-center'>
                      <i className='fa-solid fa-wallet pr-3'></i>Connect wallet
                    </button>
                  )}
                  <button onClick={Logout} className='text-center'>
                    <i className='fa-solid fa-door-open pr-3'></i>Log out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>

    </div>
  );
}