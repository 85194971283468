// import React, { useEffect, useState } from 'react';

// export default function ProblemStatement() {
//     const [activeIndex, setActiveIndex] = useState(0);

//     useEffect(() => {
//         const timer = setInterval(() => {
//             setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
//         }, 7000);

//         return () => clearInterval(timer);
//     }, []);

//     const Paragraph = [
//         { text: "A strong drive to communicate thoughts and emotions through artistic mediums such as writing, design, or performance." },
//         { text: "A natural ability to think outside the box and generate innovative ideas." },
//         { text: "A vivid imagination that fuels creative problem-solving and storytelling." },
//         { text: "A preference for open-ended tasks and environments where autonomy is encouraged." },
//         { text: "A deep connection to emotions, which often inspires your artistic endeavors." }
//     ];

//     return (
//         <div className='section-3 relative'>
//             <img src='/assets/C2.png' className='absolute -top-10 left-[15%] w-8 h-8 fa-bounce'></img>
//             <img src='/assets/C1.png' className='absolute top-28 right-5 lg:right-[10%] w-8 h-8 fa-bounce'></img>
//             <h1 className="text-3xl font-semibold">Characteristics</h1>
//             <p className='mt-7 leading-9'>Characteristics are distinguishing traits, qualities, or features that define an individual, object, or concept. They shape identity, behavior, and perception, often influencing functionality, interactions, and value in diverse contexts.</p>
//             <div className="w-full flex flex-col lg:flex-row md:justify-between gap-3 lg:gap-7 mt-7">
//                 {['Creative Expression', 'Originality', 'Imaginative Thinking', 'Flexibility', 'Emotionally Expressive'].map((label, index) => (
//                     <div
//                         key={index}
//                         className={`cursor-pointer group h-[10rem] lg:h-[27.5rem] flex items-center rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${activeIndex === index
//                             ? "lg:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[18rem] lg:h-[27.5rem]"
//                             : "lg:w-[20%] h-[2rem] lg:h-[27.5rem] border-0"
//                             }`}
//                         style={{ backgroundImage: `url('/assets/Others/${index + 1}.png')` }}
//                     >
//                         <div className={`w-full ${activeIndex === index ? "" : "bg-black/70 h-full flex items-center"}`}>
//                             <div
//                                 className={`duration-500 ${activeIndex === index ? "bg-black/70" : "lg:rotate-90"
//                                     } text-center text-white text-sm md:text-lg font-bold py-2 md:py-5 w-full`}
//                             >
//                                 {label}
//                                 {activeIndex === index && (
//                                     <p className="text-sm mt-2 font-medium">{Paragraph[index].text}</p>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }


import React, { useEffect, useState } from 'react';

export default function ProblemStatement({ keyCharacteristics }) {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % keyCharacteristics.length);
        }, 7000);

        return () => clearInterval(timer);
    }, [keyCharacteristics.length]);

    return (
        <div className='section-3 relative'>
            <img src='/assets/C2.png' className='absolute -top-10 left-[15%] w-8 h-8 fa-bounce' alt='Decoration' />
            <img src='/assets/C1.png' className='absolute top-28 right-5 lg:right-[10%] w-8 h-8 fa-bounce' alt='Decoration' />
            <h1 className="text-3xl font-semibold">Characteristics</h1>
            <p className='mt-7 leading-9'>
                Characteristics are distinguishing traits, qualities, or features that define an individual, object, or concept. 
                They shape identity, behavior, and perception, often influencing functionality, interactions, and value in diverse contexts.
            </p>
            <div className="w-full flex flex-col lg:flex-row md:justify-between gap-3 lg:gap-7 mt-7">
                {keyCharacteristics.map((characteristic, index) => {
                    const [label, text] = characteristic.split(':').map(item => item.trim());
                    return (
                        <div
                            key={index}
                            className={`cursor-pointer group h-[10rem] lg:h-[27.5rem] flex items-center rounded-3xl shadow-md bg-center bg-no-repeat bg-cover group-hover:transition-transform duration-500 ease-in-out ${
                                activeIndex === index
                                    ? "lg:w-[60%] opacity-100 border-0 border-gray-900 h-[10rem] md:h-[18rem] lg:h-[27.5rem]"
                                    : "lg:w-[20%] h-[2rem] lg:h-[27.5rem] border-0"
                            }`}
                            style={{ backgroundImage: `url('/assets/Others/${index + 1}.png')` }}
                        >
                            <div className={`w-full ${activeIndex === index ? "" : "bg-black/70 h-full flex items-center"}`}>
                                <div
                                    className={`duration-500 ${activeIndex === index ? "bg-black/70" : "lg:rotate-90"
                                    } text-center text-white text-sm md:text-lg font-bold py-2 md:py-5 w-full`}
                                >
                                    {label}
                                    {activeIndex === index && (
                                        <p className="text-sm mt-2 font-medium">{text}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
