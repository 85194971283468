import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Moralis from 'moralis';
import Header from './LoginHeader';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Loader from '../loaderdemo';
import ErrorPage from '../please_wait_page';
import Footer from '../Footer';
import { REACT_APP_MORALIS_APIKEY, REACT_APP_MORALIS_CHAIN, REACT_APP_MORALIS_NFT_ADDRESS } from '../Config'

export default function UserDetails() {
    const [nftItems, setNftItems] = useState([]);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const [formattedSetNames, setFormattedSetNames] = useState([]);
    const [result, setResult] = useState('');

    const [loading, setLoading] = useState(true);

    const location = useLocation();


    const moralis_apiKey = REACT_APP_MORALIS_APIKEY;
    const moralis_chainId = REACT_APP_MORALIS_CHAIN;
    const moralis_nft_contract_address = REACT_APP_MORALIS_NFT_ADDRESS;

    // const moralis_apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImYwOTAzODlmLWE3ZjItNGZhZS1iNDAxLWE5YWI3YzA3NTBjMCIsIm9yZ0lkIjoiMzk0Mjc5IiwidXNlcklkIjoiNDA1MTQ0IiwidHlwZUlkIjoiZTQ4MjYxNjMtZTg4NS00NmU4LWEwNDEtYTFhMzhjN2Q0OTUxIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTcwNDcwMTgsImV4cCI6NDg3MjgwNzAxOH0.KMmhpeBrILB5HEOPd6oWS-1h2rgAMPrGsM659oyDnrE";
    // const moralis_chainId = "0x13882";
    // const moralis_nft_contract_address = "0x202b138730602a87b69b89Ee9eA3E2ad48d8D7ec";

    const fetchData = async () => {
        const params = new URLSearchParams(location.search);
        const tokenId = params.get('tokenId');
        // console.log('Token ID:', tokenId);

        try {
            //  console.log('Fetching data...');
            await Moralis.start({
                apiKey: moralis_apiKey

            });
            const response = await Moralis.EvmApi.nft.getContractNFTs({
                "chain": moralis_chainId,
                "format": "decimal",
                "address": moralis_nft_contract_address
            });


            const metadata = JSON.parse(response.raw.result[0].metadata);
            const ipfsImage = metadata.image.split('://')[1];
            //  console.log('IPFS Image:', ipfsImage);
            const temp = response.raw.result
            // console.log('Response:', response.raw.result);
            setNftItems(response.raw.result);


            const filteredItems = tokenId
                ? temp.filter(item => item.token_id === tokenId)
                : [];
            console.log('Filtered Items:', filteredItems);

            setItems(filteredItems);

            setLoading(false);
        } catch (error) {
            if (error && error.code === 401) {
                setLoading(true);
            } else {
                setLoading(false);
            }

            console.error('Error fetching data:', error);
        }
    };




    const getImageFromMetadata = (metadata) => {
        try {
            const parsedMetadata = JSON.parse(metadata);
            return parsedMetadata.image || "images/default-image.jpg";
        } catch (error) {
            console.error("Error parsing metadata:", error);
            return "images/default-image.jpg";
        }
    };


    useEffect(() => {
        const fetchDataAndItem = async () => {
            await fetchData();
        };
        fetchDataAndItem();
    }, []);


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };



    const [hoveredCourse, setHoveredCourse] = useState(null);

    const handleMouseEnter = (courseName) => {
        setHoveredCourse(courseName);
    };

    const handleMouseLeave = () => {
        setHoveredCourse(null);
    };



    if (loading) {
        return <Loader />;
    }

    return (
        <div className='font-poppins bg-[#373737] '>
            <Header />

            {/* Conditional rendering for loader and blank page when their is no response from moralis */}
            {
                loading ? (
                    <Loader />
                ) : items.length === 0 ? (
                    // <ErrorPage />
                    <Loader />

                ) :
                    (

                        items.map((item, index) => {
                            const metadata = JSON.parse(item.metadata);
                            const ipfsImage = metadata.image.split('://')[1];





                            const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'skills_percentage');
                            const skills = skillsAttributes.map(attr => attr.value);
                            //   console.log('skills', skills);

                            const skillsdescriptionAttributes = metadata.attributes.filter(attr => attr.trait_type === 'skills_description');
                            const skills_description = skillsdescriptionAttributes.map(attr => attr.value);
                            //    console.log('skills_description', skills_description);

                            const prefered_rollsAttribute = metadata.attributes.filter(attr => attr.trait_type === 'prefered_roll');
                            const prefered_rolls = prefered_rollsAttribute.map(attr => attr.value);
                            //   console.log('prefered_roll', prefered_rolls);

                            const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');


                            const experience_roleAttributes = metadata.attributes.filter(attr => attr.trait_type === 'experience_role');
                            const experience_roles = experience_roleAttributes.map(attr => attr.value);

                            const first_experience_role = experience_roles.slice(0)[0];
                            const middle_experience_role = experience_roles.slice(1)[0];
                            const last_experience_role = experience_roles.slice(-1)[0];


                            const projectAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project');
                            const project = projectAttributes.map(attr => attr.value);
                            //   console.log('projectAttributes', project);

                            const project_languageAttributes = metadata.attributes.filter(attr => attr.trait_type === 'project_language_used');
                            const project_language_used = project_languageAttributes.map(attr => attr.value);
                            //   console.log('project_language_used', project_language_used);

                            const projectDescription = metadata.attributes.filter(attr => attr.trait_type === 'about_project');
                            const project_description = projectDescription.map(attr => attr.value);
                            //   console.log('projectAttributes', project_description);




                            const certificate_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'certificate');
                            const certificate = certificate_Attributes.map(attr => attr.value);
                            console.log("certificate", certificate);


                            const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'experience');
                            const experience = experience_Attributes.map(attr => attr.value);
                            // console.log('experience123123', experience);


                            const edu_sslc_inst_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_sslc_inst');
                            const edu_sslc_inst = edu_sslc_inst_Attributes.map(attr => attr.value);

                            const edu_sslc_perc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_sslc_perc');
                            const edu_sslc_perc = edu_sslc_perc_Attributes.map(attr => attr.value);

                            const edu_sslc_year_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_sslc_year');
                            const edu_sslc_year = edu_sslc_year_Attributes.map(attr => attr.value);

                            const edu_sslc_desc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_sslc_description');
                            const edu_sslc_desc = edu_sslc_desc_Attributes.map(attr => attr.value);

                            //
                            const edu_hsc_inst_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_hsc_inst');
                            const edu_hsc_inst = edu_hsc_inst_Attributes.map(attr => attr.value);

                            const edu_hsc_perc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_hsc_perc');
                            const edu_hsc_perc = edu_hsc_perc_Attributes.map(attr => attr.value);

                            const edu_hsc_year_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_hsc_year');
                            const edu_hsc_year = edu_hsc_year_Attributes.map(attr => attr.value);

                            const edu_hsc_desc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_hsc_description');
                            const edu_hsc_desc = edu_hsc_desc_Attributes.map(attr => attr.value);

                            //

                            const edu_cgpa_inst_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_cgpa_inst');
                            const edu_cgpa_inst = edu_cgpa_inst_Attributes.map(attr => attr.value);

                            const edu_cgpa_perc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_cgpa_perc');
                            const edu_cgpa_perc = edu_cgpa_perc_Attributes.map(attr => attr.value);

                            const edu_cgpa_year_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_cgpa_year');
                            const edu_cgpa_year = edu_cgpa_year_Attributes.map(attr => attr.value);

                            const edu_cgpa_desc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_cgpa_description');
                            const edu_cgpa_desc = edu_cgpa_desc_Attributes.map(attr => attr.value);

                            //

                            const edu_mba_inst_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_mba_inst');
                            const edu_mba_inst = edu_mba_inst_Attributes.map(attr => attr.value);

                            const edu_mba_perc_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_mba_perc');
                            const edu_mba_perc = edu_mba_perc_Attributes.map(attr => attr.value);

                            const edu_mba_year_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'edu_mba_year');
                            const edu_mba_year = edu_mba_year_Attributes.map(attr => attr.value);

                            const project_external_url_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'project_external_url');
                            const project_external_url = project_external_url_Attributes.map(attr => attr.value);

                            const seeker_name_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'seeker_name');
                            const seeker_name = seeker_name_Attributes.map(attr => attr.value);

                            const percentage = 50;



                            const redirect = (url) => {
                                if (!url.startsWith('http://') && !url.startsWith('https://')) {

                                    url = 'https://' + url;
                                }

                                window.location.href = url;
                            };


                            const platform_skillsAttributes = metadata.attributes.filter(attr => attr.trait_type === 'platform_skills');
                            const platform_skills = platform_skillsAttributes.map(attr => attr.value);


                            const platform_certificatesAttributes = metadata.attributes.filter(attr => attr.trait_type === 'platform_certificates');
                            const platform_certificates = platform_certificatesAttributes.map(attr => attr.value);



                            const getCourseScore = (courseName) => {

                                const flattenedCertificates = platform_certificates.flat();

                                console.log("Flattened certificates:", flattenedCertificates);


                                const certificate = flattenedCertificates.find(cert => cert.course_name === courseName);
                                console.log("certificate....", certificate);

                                return certificate ? certificate.score : null;
                            };





                            //  console.log("platform_skills", platform_skills)
                            console.log("platform_certificates", platform_certificates)





                            const combinedSkills = [...skillsAttributes.map(attr => attr.value), ...platform_skillsAttributes.map(attr => attr.value)];

                            console.log("combined skills", combinedSkills);

                            const normalSkills = [...skillsAttributes.map(attr => attr.value)];
                            const platformSkills = [...platform_skillsAttributes.map(attr => attr.value)];

                            const groupedPlatformSkills = platformSkills.reduce((acc, skill) => {
                                const skillDetails = typeof skill === 'string' ? JSON.parse(skill) : skill;

                                skillDetails.forEach((item) => {
                                    const courseName = item.course || 'Unknown Course';
                                    if (!acc[courseName]) {
                                        acc[courseName] = [];
                                    }
                                    acc[courseName].push(item);
                                });
                                return acc;
                            }, {});

                            return (
                                <React.Fragment key={index}>
                                    <div className='Details flex justify-center bg-[#191919] text-white' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                                        <div className='w-[90%] md:flex py-5 md:py-10'>
                                            <div className='md:w-[50%] p-5 md:p-10'>
                                                <h1 className='font-bold'>Hi!</h1>
                                                <h1 className='font-bold text-3xl mt-5'>My Name Is {seeker_name}</h1>
                                                <p className='text-sm mt-5 font-bold'>{last_experience_role}</p>
                                                <p className='text-xs mt-5'>{metadata.description}</p>
                                                <div className='flex gap-5 mt-5'>
                                                    {/* <i className='fa-brands fa-linkedin text-3xl'></i>
                                            <i className='fa-brands fa-x-twitter text-3xl'></i> */}

                                                </div>
                                            </div>
                                            <div className='md:w-[50%]'>
                                                <div>
                                                    {/* <img className='w-[50%]' src='/avatar/D5art/avatar6.png'></img> */}
                                                    {/* <img src={getImageFromMetadata(item.metadata)}></img> */}

                                                    <img src={getImageFromMetadata(item.metadata).includes("ipfs.io")
                                                        ? getImageFromMetadata(item.metadata).replace(
                                                            "ipfs.io",
                                                            "fabc-d5art.infura-ipfs.io"
                                                        )
                                                        : getImageFromMetadata(item.metadata)
                                                    } />


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='MySkills flex justify-center bg-[#303030] text-white' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                                        <div className='w-[85%] flex-col py-10'>
                                            <div className='md:flex'>
                                                <div className='md:w-[50%] md:p-10'>
                                                    <h1 className='text-3xl font-bold'>My skills 🚀 </h1>
                                                    <p className='text-xs mt-5'>{ }</p>

                                                </div>

                                                <div className='md:w-[50%] md:px-20 py-10'>
                                                    {skillsAttributes.map((attr, index) => {
                                                        try {
                                                            const skillDetails = JSON.parse(attr.value);

                                                            if (Array.isArray(skillDetails) && skillDetails.length > 0) {
                                                                return (
                                                                    <div key={index}>
                                                                        {skillDetails.map((skill, subIndex) => (
                                                                            <div key={subIndex}>
                                                                                <p className='uppercase flex justify-between'>
                                                                                    <span>{skill.skill}</span>
                                                                                    <span className='mr-10'>{skill.percentage}%</span>
                                                                                </p>
                                                                                <div className='py-3'>
                                                                                    <div className='h-2 rounded w-full bg-gray-100'>
                                                                                        <div className='h-2 bg-yellow-500 rounded' style={{ width: `${skill.percentage}%` }}></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}

                                                                        {Object.keys(groupedPlatformSkills).map((courseName, index) => {
                                                                            const courseScore = getCourseScore(courseName);
                                                                            console.log("courseScore", courseScore)

                                                                            return (
                                                                                <div key={index}>
                                                                                    <div className='flex justify-between'>
                                                                                        <p
                                                                                            className='font-bold  cursor-pointer uppercase flex justify-between w-full'
                                                                                            onMouseEnter={() => handleMouseEnter(courseName)}
                                                                                            onMouseLeave={handleMouseLeave}
                                                                                        >
                                                                                            {courseName}
                                                                                            <span className='mr-3'>
                                                                                                {courseScore ? `${courseScore}%` : 'N/A'} {/* Display the dynamic score */}
                                                                                            </span>
                                                                                        </p>

                                                                                        {/* Optionally display the image and progress bar */}
                                                                                        <div>
                                                                                            <img src='/assets/D5ART_Fav icon.png' alt='Course logo' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='py-3 '>
                                                                                        <div className='h-2 rounded w-full bg-gray-100'>
                                                                                            <div
                                                                                                className='h-2 bg-yellow-500 rounded'
                                                                                                style={{ width: `${courseScore || 0}%` }}  // Use score as width
                                                                                            />
                                                                                        </div>
                                                                                    </div>


                                                                                    {/* Conditionally display platform skills on hover */}
                                                                                    {hoveredCourse === courseName && (
                                                                                        <div>
                                                                                            {groupedPlatformSkills[courseName].map((item, subIndex) => {
                                                                                                const skillName = item.skill || item.name;
                                                                                                const percentage = item.percentage || item.skillPoints;

                                                                                                if (!skillName) {
                                                                                                    console.warn(`Undefined platformSkillName at subIndex ${subIndex}`, item);
                                                                                                    return null;
                                                                                                }

                                                                                                const matchedSkill = formattedSetNames.find(name => name === skillName);
                                                                                                const shouldDisplayImage = matchedSkill && (result[subIndex] === 'PASS' || result[subIndex] === null);

                                                                                                return (
                                                                                                    <div key={subIndex}>
                                                                                                        <div className='flex justify-between '>
                                                                                                            <div>
                                                                                                                <p className='font-bold mt-2 text-lg text-[#FFBA00]'>
                                                                                                                    {skillName}{' '}
                                                                                                                    <span className='text-[#0EA4A9]'>
                                                                                                                        - {percentage}{' '}
                                                                                                                        {item.percentage ? '%' : 'points'}
                                                                                                                    </span>
                                                                                                                </p>
                                                                                                            </div>

                                                                                                            {/* Display image if condition matches */}
                                                                                                            {shouldDisplayImage && (
                                                                                                                <div className='flex justify-between'>
                                                                                                                    <img src='/assets/D5ART_Fav icon.png' alt='Skill logo' />
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>

                                                                                                        {/* Progress bar */}
                                                                                                        {/* <div className='px-3 py-2'>
                                                                                                <div className='h-2 w-full bg-gray-900 rounded'>
                                                                                                    <div
                                                                                                        className='h-2 rounded bg-gradient-to-r from-[#13BDB3] to-[#0E14A9]'
                                                                                                        style={{ width: `${percentage}%` }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div> */}

                                                                                                    </div>
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                );
                                                            }
                                                        } catch (error) {
                                                            console.error('Error parsing skill data:', error);
                                                            return null; // Skip rendering if parsing fails
                                                        }
                                                        return null; // Skip rendering if skillDetails is null or empty
                                                    })}
                                                </div>
                                            </div>


                                            <div className='md:px-10'>
                                                <h1 className='text-2xl font-bold'>Certifications 🏅</h1>
                                                <div className='flex gap-5 md:py-10'>
                                                    {/* Normal Certificates */}
                                                    {certificate.map((certDetails, index) => {
                                                        let certificateDetails;
                                                        try {
                                                            // Check if certDetails is already an object
                                                            certificateDetails = typeof certDetails === 'string' ? JSON.parse(certDetails) : certDetails;
                                                        } catch (error) {
                                                            console.error('Error parsing certificate:', certDetails, error);
                                                            return null;
                                                        }

                                                        if (certificateDetails && certificateDetails.name && certificateDetails.source) {
                                                            return (
                                                                <div key={index} className='md:w-[23%] p-5'>
                                                                    <div className='flex justify-center'>
                                                                        <div className='bg-white rounded-full'>
                                                                            <img src='/assets/badge.png' alt='Certificate Badge' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='text-center'>
                                                                        <p className='text-lg mt-5'>{certificateDetails.name}</p>
                                                                        <p className='text-xs mt-5'>Certificate obtained via, {certificateDetails.source}.</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            console.error('Certificate data is missing required fields:', certificateDetails);
                                                            return null;
                                                        }
                                                    })}

                                                    {/* Platform Certificates */}
                                                    {platform_certificates.map((platformCertDetails, index) => {
                                                        let platformCertificateDetails;
                                                        try {
                                                            // Check if platformCertDetails is already an object
                                                            platformCertificateDetails = typeof platformCertDetails === 'string' ? JSON.parse(platformCertDetails) : platformCertDetails;
                                                            console.log("platformCertificateDetails", platformCertificateDetails);
                                                        } catch (error) {
                                                            console.error('Error parsing platform certificate:', platformCertDetails, error);
                                                            return null;
                                                        }

                                                        // Check if platformCertificateDetails is an array and iterate over it
                                                        return platformCertificateDetails.map((cert, certIndex) => {
                                                            if (cert.course_name && cert.link && cert.score) {
                                                                return (
                                                                    <div key={certIndex} className='md:w-[23%] p-5'>
                                                                        <div className='flex justify-center'>
                                                                            <div className='bg-white rounded-full'>
                                                                                <img src='/assets/badge.png' alt='Certificate Badge' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='text-center'>
                                                                            <p className='text-lg mt-5'>{cert.course_name}</p>
                                                                            <p className='text-xs mt-5'>Certificate obtained via, D5Art.</p>
                                                                            <p className='text-xs mt-5'>
                                                                                {/* Certificate link:  */}
                                                                                <a href={cert.link} target='_blank' rel='noopener noreferrer' className='text-blue-500'>
                                                                                    View Certificate
                                                                                </a>
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                );
                                                            } else {
                                                                console.error('Platform certificate data is missing required fields:', cert);
                                                                return null;
                                                            }
                                                        });
                                                    })}
                                                </div>
                                            </div>







                                        </div>
                                    </div>
                                    <div className='EXPERIENCES. flex justify-center bg-[#191919] text-white' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                                        <div className='w-[90%] py-7'>
                                            <h1 className='text-3xl font-bold text-center mb-10'>EXPERIENCES ⏳ </h1>
                                            {experience.map((exp, index) => {
                                                try {
                                                    const experienceDetails = JSON.parse(exp);
                                                    if (experienceDetails && experienceDetails.companyname && experienceDetails.from && experienceDetails.to && experienceDetails.role &&
                                                        experienceDetails.companyname.trim() !== '' && experienceDetails.from.trim() !== '' && experienceDetails.to.trim() !== '' && experienceDetails.role.trim() !== '') {
                                                        return (
                                                            <div key={index} className='flex'>
                                                                <div className='w-[50%] flex justify-end px-10'>
                                                                    <div className='grid content-around'>
                                                                        <p className='text-end'>{experienceDetails.role}<br />{experienceDetails.companyname}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='h-20 w-[1px] bg-gray-100'>
                                                                    <div className='h-full w-3 grid content-around -translate-x-1.5'>
                                                                        <div className='p-1.5 rounded-full bg-yellow-500'></div>
                                                                    </div>
                                                                </div>
                                                                <div className='w-[50%] flex justify-start px-10'>
                                                                    <div className='grid content-around'>
                                                                        <p>{experienceDetails.from} - {experienceDetails.to}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                } catch (error) {
                                                    console.error('Error parsing experience data:', error);
                                                    return null; // Skip rendering if parsing fails
                                                }
                                            })}
                                        </div>
                                    </div>

                                    <div className='EDUCATION.. flex justify-center bg-[#303030] text-white' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                                        <div className='w-[90%] md:py-10'>
                                            <h1 className='text-3xl font-bold text-center'>EDUCATION 🎓</h1>
                                            <div className='md:flex justify-center py-10'>
                                                {edu_sslc_inst && edu_sslc_year && edu_sslc_perc && edu_sslc_desc && edu_cgpa_inst && edu_cgpa_year && edu_cgpa_perc && edu_cgpa_desc && (
                                                    <div className='md:w-[80%] px-5 md:px-10'>
                                                        <h1>Completed the most recent Education at <font color="yellow">{edu_sslc_inst}</font> in the year <font color="yellow">{edu_sslc_year}</font> and secured <font color="yellow">{edu_sslc_perc}%</font>.</h1>
                                                        <div className='py-3 pb-5'>
                                                            <div className='w-32 h-[2px] bg-[#0EA4A9]'></div>
                                                        </div>
                                                        <p className='text-xs text-gray-400'>{edu_sslc_desc}</p>

                                                        {/* <h1 className='mt-14'>Completed College at <font color="yellow">{edu_cgpa_inst}</font> in the year <font color="yellow">{edu_cgpa_year}</font> and secured <font color="yellow">{edu_cgpa_perc}%</font>.</h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32 h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'>{edu_cgpa_desc}</p>  */}
                                                    </div>
                                                )}

                                                {/* {edu_hsc_inst && edu_hsc_year && edu_hsc_perc && edu_hsc_desc && edu_mba_inst && edu_mba_year && edu_mba_perc && (
                                            <div className='md:w-[40%] px-5 md:px-10 mt-5 md:mt-0'>
                                                <h1>Completed Higher Secondary School at <font color="yellow">{edu_hsc_inst}</font> in the year <font color="yellow">{edu_hsc_year}</font> and secured <font color="yellow">{edu_hsc_perc}%</font>.</h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32 h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'>{edu_hsc_desc}</p>


                                            </div>
                                        )} */}

                                            </div>
                                        </div>
                                    </div>


                                    <div className='Recent project flex justify-center bg-[#191919] text-white pb-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                                        <div className='w-[90%] py-10'>
                                            <h1 className='text-3xl font-bold text-center uppercase text-gray-300'>Recent project 💻</h1>
                                            <Slider {...settings}>
                                                <div className='part-1 md:flex justify-center py-10'>
                                                    <div className='md:w-[100%] flex flex-row gap-7'>
                                                        {project.map((project, index) => (
                                                            (project && project.trim() !== '' && index === 0) && (
                                                                <div key={index} className='flex md:flex justify-around gap-5'>
                                                                    <div className='w-[50%] gap-4 grid content-between'>
                                                                        <h1 className='font-bold text-2xl'>{project}</h1>
                                                                        <p className='italic text-xm 2xl:text-base'>{project_description}</p>
                                                                        <div>
                                                                            <button className='bg-white/10 px-1' onClick={() => redirect(project_external_url[index])}>
                                                                                <i className="fa-solid fa-arrow-right-long text-2xl -rotate-45 "></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-[30%]'>
                                                                        <div><img src='/assets/web-development-programmer.png' alt='Project screenshot' /></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ))}
                                                    </div>
                                                </div>
                                                {project.slice(1).map((project, index) => (
                                                    <div key={index} className='part-2 md:flex justify-center py-10'>
                                                        <div className='md:w-[100%] flex flex-row gap-7'>
                                                            {(project && project.trim() !== '') && (
                                                                <div className='flex md:flex justify-around gap-5'>
                                                                    <div className='w-[50%] gap-4 grid content-between'>
                                                                        <h1 className='font-bold text-2xl'>{project}</h1>
                                                                        <p className='italic text-xm 2xl:text-base'>{project_description}</p>
                                                                        <div>
                                                                            <button className='bg-white/10 px-1' onClick={() => redirect(project_external_url[index + 1])}>
                                                                                <i className="fa-solid fa-arrow-right-long text-2xl -rotate-45 "></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className='w-[30%] '>
                                                                        <div><img src='/assets/web-development-programmer.png' alt='Project screenshot' /></div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}

                                            </Slider>

                                        </div>
                                    </div>







                                </React.Fragment>
                            );
                        })
                    )}
            <Footer />
        </div>
    )
}