


import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
//import Header from './Header';
import Moralis from 'moralis';
import { ethers } from 'ethers';
// import Web3 from 'web3';
import LoginHeader from './LoginHeader';
import Footer from '../Footer';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setRecruiterEmail } from '../store';
import Select from 'react-select';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../loaderdemo';
import ErrorPage from '../please_wait_page';
import Skillslist from '../Seeker/Skills.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import CryptoJS from 'crypto-js';
import { REACT_APP_SEEKER_SECRETKEY, REACT_APP_RECRUITER_SECRETKEY, REACT_APP_MORALIS_APIKEY, REACT_APP_MORALIS_CHAIN, REACT_APP_MORALIS_NFT_ADDRESS } from '../Config';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import SkillSlider from '../Seeker/SkillsSlider';
import PreferredRoles from '../Seeker/PreferredRoles.json'


export default function SkillArenalist() {
    const [starCount, setStarCount] = useState(0);
    const [nftItems, setNftItems] = useState([]);
    const [userInfo, setUserInfo] = useState({});

    const [loading, setLoading] = useState(true);
    const [balance, setBalance] = useState(null);

    const email = useSelector((state) => state.recruiter_email);
    //  //console.log("Email Logged In", email)

    const dispatch = useDispatch();


    // ------------- skill search


    const [selectedSkill, setSelectedSkill] = React.useState([]);
    const [inputValueSkill, setInputValueSkill] = React.useState('');

    const handleSkill = (selectedOption) => {
        if (selectedSkill.length < 6) {
            setSelectedSkill([...selectedSkill, selectedOption]);
        }
        setInputValueSkill('');
    };
    const removeOption2 = (index) => {
        const updatedOptions = [...selectedSkill];
        updatedOptions.splice(index, 1);
        setSelectedSkill(updatedOptions);
    };



    // ----------  skill search bar

    const [selectedSkillSearch, setSelectedSkillSearch] = React.useState([]);
    const [inputValueSkillSearch, setInputValueSkillSearch] = React.useState('');

    const handleSkillSearch = (selectedOption) => {
        if (selectedSkillSearch.length < 6) {
            setSelectedSkillSearch([...selectedSkillSearch, selectedOption]);
        }
        setInputValueSkillSearch('');
    };
    const removeOption2Search = (index) => {
        const updatedOptions = [...selectedSkillSearch];
        updatedOptions.splice(index, 1);
        setSelectedSkillSearch(updatedOptions);
    };


    // -------------Preferred Role

    const [selectedPreferredRole, setSelectedPreferredRole] = React.useState([]);
    const [inputValuePreferredRole, setInputValuePreferredRole] = React.useState('');

    const handlePreferredRole = (selectedOption) => {
        if (selectedPreferredRole.length < 6) {
            setSelectedPreferredRole([...selectedPreferredRole, selectedOption]);
        }
        setInputValuePreferredRole('');
    };

    const removeOptionPreferredRole = (index) => {
        const updatedOptions = [...selectedPreferredRole];
        updatedOptions.splice(index, 1);
        setSelectedPreferredRole(updatedOptions);
    };




    // --------------- work mode 

    const [workModeQuery, setWorkModeQuery] = useState('');
    const [selectedWorkModes, setSelectedWorkModes] = useState([]);

    const handleWorkModeChange = (selectedOption) => {
        const selectedMode = selectedOption.value;
        if (selectedMode && !selectedWorkModes.includes(selectedMode)) {
            setSelectedWorkModes([...selectedWorkModes, selectedMode]);
        }
        setWorkModeQuery('');
    };

    const removeSelectedWorkMode = (index) => {
        const updatedWorkModes = selectedWorkModes.filter((_, i) => i !== index);
        setSelectedWorkModes(updatedWorkModes);
    };



    const SkillOptions = Skillslist;
    const preferredRoleOptions = PreferredRoles;



    const workMode = [
        { value: 'Part Time', label: 'Part Time' },
        { value: 'Full Time', label: 'Full Time' },
        { value: 'Freelance', label: 'Freelance' },
    ];

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '0px solid #0a3840',
            backgroundColor: '#374151',
            borderRadius: '30px',
            boxShadow: '0px',
            color: 'white',
            paddingLeft: '8px',
            outline: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'lightgray',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        menu: (provided, state) => ({
            ...provided,
            background: '#000', // Color of the typed input text
        }),
    };
    // const customStylesSearch = {
    //     control: (provided, state) => ({
    //         ...provided,
    //         border: '1px solid lightgrey',
    //         backgroundColor: '#171717',
    //         borderRadius: '5px',
    //         boxShadow: 'none',
    //         color: 'white',
    //         paddingLeft: '8px',
    //         outline: 'none !important',
    //         ring: 'none'
    //     }),
    //     placeholder: (provided, state) => ({
    //         ...provided,
    //         color: 'lightgrey',
    //         fontSize: '12px',
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: state.isSelected ? '#08f' : '#090909',
    //         color: state.isSelected ? '#fff' : '#fff', // Color of the option text
    //     }),
    //     singleValue: (provided, state) => ({
    //         ...provided,
    //         color: '#fff', // Color of the selected option text
    //     }),
    //     input: (provided, state) => ({
    //         ...provided,
    //         color: '#fff', // Color of the typed input text
    //     }),
    //     menu: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: '#000',
    //         border: '1px solid #ffffff60',
    //         borderRadius: '5px'
    //     }),

    // };

    const customStylesSearch = {
        control: (provided, state) => ({
            ...provided,
            border: '0px',
            backgroundColor: 'transparent',
            borderRadius: '5px',
            boxShadow: 'none',
            color: 'white',
            paddingLeft: '8px',
            outline: 'none !important',
            ring: 'none'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'lightgrey',
            fontSize: '12px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#000',
            border: '1px solid #ffffff60',
            borderRadius: '5px'
        }),

    };

    const incrementStar = () => {
        if (starCount < 5) {
            setStarCount(starCount + 1);
        }
    };

    const decrementStar = () => {
        if (starCount > 0) {
            setStarCount(starCount - 1);
        }
    };

    const customStylesSearchForPreferredRole = {
        control: (provided, state) => ({
            ...provided,
            border: '0px',
            backgroundColor: 'transparent',
            borderRadius: '5px',
            boxShadow: 'none',
            color: 'black',
            paddingLeft: '8px',
            outline: 'none !important',
            ring: 'none'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'grey',
            fontSize: '12px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'black', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: 'black', // Color of the typed input text
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#000',
            border: '1px solid #ffffff60',
            borderRadius: '5px'
        }),

    };

    const location = useLocation();
    // const history = Link();
    const { search } = location;
    const params = new URLSearchParams(search);
    const userEmail = params.get('userEmail');


    const moralis_apiKey = REACT_APP_MORALIS_APIKEY;
    const moralis_chainId = REACT_APP_MORALIS_CHAIN;
    const moralis_nft_contract_address = REACT_APP_MORALIS_NFT_ADDRESS;


    // old fetch method without cursor
    // const fetchData = async () => {
    //     try {
    //         await Moralis.start({
    //             apiKey: moralis_apiKey
    //         });

    //         const response = await Moralis.EvmApi.nft.getContractNFTs({
    //             "chain": moralis_chainId,
    //             "format": "decimal",
    //             "address": moralis_nft_contract_address
    //         });

    //         const metadata = JSON.parse(response.raw.result[0].metadata);
    //         const ipfsImage = metadata.image.split('://')[1];
    //         //   //console.log(ipfsImage);

    //         //  //console.log(response.raw.result);
    //         setNftItems((prevItems) => [...prevItems, ...response.raw.result]);



    //         setLoading(false);
    //     } catch (error) {
    //         if (error && error.code === 401) {
    //             setLoading(true); // Show loader for 401 error code
    //         } else {
    //             setLoading(false);
    //         }
    //         console.error('Error fetching data:', error);
    //     }
    // };

    // new method - limit 100
    // const fetchData = async () => {
    //     try {
            
    //         let response = await axios.get(`https://deep-index.moralis.io/api/v2.2/nft/${moralis_nft_contract_address}?chain=${moralis_chainId}&format=decimal`, {
    //             headers: {
    //                 accept: 'application/json',
    //                 'X-API-Key': moralis_apiKey
    //             }
    //         });
    //         const metadata = JSON.parse(response.data.result[0].metadata);
    //         const ipfsImage = metadata.image.split('://')[1];
    //         //   //console.log(ipfsImage);

    //         //  //console.log(response.raw.result);
    //         setNftItems((prevItems) => [...prevItems, ...response.data.result]);



    //         setLoading(false);
    //     } catch (error) {
    //         if (error && error.code === 401) {
    //             setLoading(true); // Show loader for 401 error code
    //         } else {
    //             setLoading(false);
    //         }
    //         console.error('Error fetching data:', error);
    //     }
    // };

    // cursor method
    // const fetchData = async (cursor = null) => {
    //     try {
    //         await Moralis.start({
    //             apiKey: moralis_apiKey
    //         });

    //         let hasMore = true;
    //         let allResults = [];

    //         while (hasMore) {
    //             const response = await Moralis.EvmApi.nft.getContractNFTs({
    //                 chain: moralis_chainId,
    //                 format: "decimal",
    //                 address: moralis_nft_contract_address,
    //                 cursor: cursor // Add cursor for pagination
    //             });

    //             // Merge new results with allResults
    //             allResults = [...allResults, ...response.raw.result];

    //             // If there's more data (i.e., cursor), continue fetching
    //             if (response.raw.cursor) {
    //                 cursor = response.raw.cursor;
    //             } else {
    //                 hasMore = false; // No more data to fetch
    //             }
    //         }

    //         // Assuming metadata for all results should be handled
    //         if (allResults.length > 0) {
    //             const metadata = JSON.parse(allResults[0].metadata);
    //             const ipfsImage = metadata.image.split('://')[1];
    //             // You can log or use the `ipfsImage` as needed
    //             // //console.log(ipfsImage);
    //         }

    //         // Set the final list of all results to the state
    //         setNftItems((prevItems) => [...prevItems, ...allResults]);
    //         setLoading(false);

    //     } catch (error) {
    //         if (error && error.code === 401) {
    //             setLoading(true); // Show loader for 401 error code
    //         } else {
    //             setLoading(false);
    //         }
    //         console.error('Error fetching data:', error);
    //     }
    // };

    // const fetchData = async () => {
    //     try {
    //         let response = await axios.get(`https://deep-index.moralis.io/api/v2.2/nft/${moralis_nft_contract_address}?chain=${moralis_chainId}&format=decimal`, {
    //             headers: {
    //                 accept: 'application/json',
    //                 'X-API-Key': moralis_apiKey
    //             }
    //         });
    //         setNftItems((prevItems) => [...prevItems, ...response.data.result]);
    //         setLoading(false);
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         if (error && error.code === 401) {
    //             setLoading(true);
    //         } else {
    //             setLoading(false);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     if (moralis_nft_contract_address) {
    //         fetchData();
    //     }
    // }, [moralis_nft_contract_address, moralis_apiKey, moralis_chainId]);


    // useEffect(() => {
    //     fetchData();
    // }, []);


// cursor method latest
    const fetchData = async () => {
        try {
            await Moralis.start({ apiKey: moralis_apiKey });
    
            let cursor = null;
            let allResults = [];
            let hasMore = true;
    
            while (hasMore) {
                const response = await Moralis.EvmApi.nft.getContractNFTs({
                    chain: moralis_chainId,
                    format: "decimal",
                    address: moralis_nft_contract_address,
                    limit: 100, // Max limit per request
                    cursor: cursor, // Pagination cursor
                });
    
                allResults = [...allResults, ...response.raw.result];
    
                // Check if there's more data
                if (response.raw.cursor) {
                    cursor = response.raw.cursor; // Update cursor
                } else {
                    hasMore = false; // Stop fetching when no more data
                }
            }
    
            // Process metadata if needed
            if (allResults.length > 0) {
                try {
                    const metadata = JSON.parse(allResults[0].metadata);
                    const ipfsImage = metadata.image?.split('://')[1];
                    // console.log(ipfsImage);
                } catch (err) {
                    console.error("Error parsing metadata:", err);
                }
            }
    
            // Set all NFTs at once
            setNftItems(allResults);
            setLoading(false);
    
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    };
    
    useEffect(() => {
        if (moralis_nft_contract_address && nftItems.length === 0) {
            fetchData();
        }
    }, [moralis_nft_contract_address, moralis_apiKey, moralis_chainId]);
    

    

    const getImageFromMetadata = (metadata) => {
        try {
            const parsedMetadata = JSON.parse(metadata);
            return parsedMetadata.image || "images/default-image.jpg";
        } catch (error) {
            console.error("Error parsing metadata:", error);
            return "images/default-image.jpg";
        }
    };

    // check balance

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ex.d5art.com/api/fetch_recruiter_transactions?recruiter_email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                //console.log('fetch_seeker_transactions', data.data);

                if (response.ok) {
                    const transactions = data.data;

                    const creditedTransactions = data.data.filter(item => item.status === 'Credited');
                    const debitedTransactions = data.data.filter(item => item.status === 'Debited');

                    // Sum the amounts for both credited and debited transactions
                    const totalCredited = creditedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount || 0), 0);
                    const totalDebited = debitedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount || 0), 0);

                    // -------  Calculate balance  -------
                    const balance = totalCredited - totalDebited;

                    setBalance(balance);

                    // //console.log("balance",balance)

                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);

    const handleButtonClick = async (tokenIdParam) => {
        try {

            if (balance <= 1) {
                // toast.error(`Please check the balance.\nCurrent balance: ${balance}`);

                toast.error(
                    <div>
                        <p>Please check the balance.</p>
                        <p style={{ color: 'red' }}>Current balance: {balance}</p>
                    </div>
                );

                // setTimeout(() => {
                //   window.location.reload();
                // }, 3000); 
                return;
            }

            //const Recruiter_secretKey = "Recruiter@20#31%456&$#234asd3485690567@@#$%#%#$@@*&^%$E$#$%#";

            const Recruiter_secretKey = REACT_APP_RECRUITER_SECRETKEY;
            let encryptedTokenId = CryptoJS.AES.encrypt(tokenIdParam, Recruiter_secretKey).toString();
            // //console.log("encryptedTokenId",tokenIdParam);
            const newPath = `/RMS/Recruiter/Skillmarket?tokenId=${encodeURIComponent(encryptedTokenId)}`;
            window.location.href = newPath;

            const selectedNFT = nftItems.find(item => item.token_id === tokenIdParam);
            const metadata = JSON.parse(selectedNFT.metadata);

            const seeker_nftId = metadata.name;

            const email_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'seeker_email');
            const seeker_email = email_typeAttribute.value;
            //   //console.log("seekeremail", seeker_email);
            const seekerEmail = seeker_email;

            // call the function to transfer token to admin and save split in the db
            await pay_per_click(email, seekerEmail, seeker_nftId, tokenIdParam);

            // Call the function to save email and token ID to the database
            await saveEmailAndTokenToDatabase(email, seekerEmail, tokenIdParam);
        } catch (error) {
            console.error(error);
        }
    };

    const [ViewProfilePopup, setViewProfilePopup] = useState(false);
    const [selectedTokenId, setSelectedTokenId] = useState(null);

    // const handleViewProfile = (tokenId) => {
    //     setSelectedTokenId(tokenId);
    //     setViewProfilePopup(!ViewProfilePopup);
    // };

    const handleViewProfile = async (tokenId, seekerEmail, recruiterEmail, nftId) => {
        setSelectedTokenId(tokenId);

        try {

            const selectedNFT = nftItems.find(item => item.token_id === tokenId);
            const metadata = JSON.parse(selectedNFT.metadata);

            const seeker_nftId = metadata.name;

            const email_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'seeker_email');
            const seeker_email = email_typeAttribute.value;
            //   //console.log("seekeremail", seeker_email);
            const seekerEmail = seeker_email;
            //console.log("Sending data:", { seekerEmail, email, tokenId, seeker_nftId });

            const response = await axios.post('https://ex.d5art.com/api/checkSeeker', {
                seekerEmail,
                email,
                tokenId,
                seeker_nftId
            });

            //console.log("Server response:", response.data);

            if (response.data.exists) {
                // If record exists, redirect to the next page
                //const Recruiter_secretKey = "Recruiter@20#31%456&$#234asd3485690567@@#$%#%#$@@*&^%$E$#$%#";
                const Recruiter_secretKey = REACT_APP_RECRUITER_SECRETKEY;
                let encryptedTokenId = CryptoJS.AES.encrypt(tokenId, Recruiter_secretKey).toString();
                const newPath = `/RMS/Recruiter/Skillmarket?tokenId=${encodeURIComponent(encryptedTokenId)}`;
                window.location.href = newPath;
            } else {
                // If record does not exist, show the popup
                setViewProfilePopup(true);
            }
        } catch (error) {
            console.error('Error checking viewProfile:', error);
        }
    };




    const saveEmailAndTokenToDatabase = async (email, seekerEmail, tokenId) => {
        try {

            const response = await axios.post('https://ex.d5art.com/api/save_seeker_email_for_appearance', { email, seekerEmail, tokenId });
            //  //console.log('Email and token ID saved to the database:', response.data);
        } catch (error) {
            console.error('Error saving email and token ID to the database:', error);
        }
    };


    const pay_per_click = async (email, seekerEmail, tokenId, seeker_nftId) => {
        try {

            const response = await axios.post('https://ex.d5art.com/api/pay_per_click', { email, seekerEmail, tokenId, seeker_nftId });
            //  //console.log('Email and token ID saved to the database:', response.data);
        } catch (error) {
            console.error('Error saving email and token ID to the database:', error);
        }
    };

    const closeViewProfilePopup = () => {
        setViewProfilePopup(false);
    };




    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/checkSeeker?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                //  //console.log('5555', data.data[0].avatar);


                if (response.ok) {


                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);


    ////////////

    const [searchQuery, setSearchQuery] = useState('');
    // const [workModeQuery, setWorkModeQuery] = useState('');
    const [minExperience, setMinExperience] = useState(0);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // const handleWorkModeChange = (e) => {
    //     setWorkModeQuery(e.target.value);
    // };

    const ExperienceOption = [
        { value: '0', label: 'Fresher' },
        ...Array.from({ length: 20 }, (_, index) => ({
            value: (index + 1).toString(),
            label: (index + 1).toString(),
        }))
    ];

    const handleExperienceChange = (selectedOption) => {
        setMinExperience(parseInt(selectedOption.value));
    };

    const filteredItems = nftItems.filter((item) => {

        // if (item.contractAddress !== '0x797fFC09444EAe34C1700948E41ae185577aFd83') {
        //     return false;
        // }


        // const metadata = JSON.parse(item.metadata);
        // const itemName = metadata.name?.toLowerCase() || '';

        let metadata;
        try {
            metadata = item.metadata ? JSON.parse(item.metadata) : null;
        } catch (error) {
            console.error('Error parsing metadata for item:', item, error);
            return false; // Skip this item if metadata is not valid
        }

        if (!metadata || !metadata.attributes) {
            return false; // Skip this item if metadata or attributes are missing
        }

        const itemName = metadata.name ? metadata.name.toLowerCase() : '';


        // const workModeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');
        // const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'total_experience_years');
        // const experience = experience_Attributes.map(attr => parseInt(attr.value)).filter(exp => !isNaN(exp) && exp !== undefined);
        // const hasValidExperience = experience.some(exp => exp >= minExperience || exp === 0 || exp === null);


        const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'total_experience_years');
        const experience = experience_Attributes.map(attr => parseInt(attr.value)).filter(exp => !isNaN(exp) && exp !== undefined);
        // const hasValidExperience = experience.some(exp => exp >= minExperience);
        const hasValidExperience = experience.some(exp => exp >= minExperience || (minExperience === 0 && exp === 0));



        // const workModeValue = workModeAttribute?.value?.toLowerCase() || '';


        const workModeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');
        const workModeValue = workModeAttribute?.value?.toLowerCase() || '';


        const selectedSkills = selectedSkill.map(skill => skill.label);
        ////console.log("select", metadata.attributes);


        // const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'skills_used');
        // const skills = skillsAttributes.flatMap(attr => JSON.parse(attr.value));

        const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'skills_used');
        const skills = skillsAttributes.flatMap(attr => {
            // Check if attr.value is already an object
            return typeof attr.value === 'string' ? JSON.parse(attr.value) : attr.value;
        });

        const hasSelectedSkills = selectedSkills.every(skill => skills.includes(skill));
        // //console.log("select1",hasSelectedSkills);

        const selectedSkillsSearch = selectedSkillSearch.map(skill => skill.label);
        const hasSelectedSkillsSearch = selectedSkillsSearch.every(skill => skills.includes(skill));

        // Preferred Role Logic
        const preferredRoleAttributes = metadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'prefered_roll');
        const preferredRoles = preferredRoleAttributes.flatMap(attr => {
            return typeof attr.value === 'string' ? JSON.parse(attr.value) : attr.value;
        });

        const selectedPreferredRoles = selectedPreferredRole.map(role => role.label);
        const hasSelectedPreferredRole = selectedPreferredRoles.every(role => preferredRoles.includes(role));


        // console.log("hasSelectedPreferredRole",hasSelectedPreferredRole)

        return itemName.includes(searchQuery.toLowerCase()) &&
            // (!workModeQuery || (workModeValue.includes(workModeQuery.toLowerCase()))) 
            (selectedWorkModes.length === 0 || selectedWorkModes.some(mode => workModeValue.includes(mode.toLowerCase()))) &&
            hasValidExperience && hasSelectedSkills && hasSelectedSkillsSearch && hasSelectedPreferredRole;
    });

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute z-10 top-[50%] transform -translate-y-[70%] left-3"
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronLeft} className="cursor-pointer text-yellow-500 font-bold " />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute top-[50%] transform -translate-y-[70%] right-3"
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronRight} className="cursor-pointer text-yellow-500 font-bold" />
            </div>
        </div>
    );

    const sliderSettings = {
        dots: false, // Enable navigation dots
        infinite: true, // Enable infinite looping
        speed: 500, // Transition speed
        slidesToShow: 1, // Show 3 slides at a time (can adjust this as needed)
        slidesToScroll: 1, // Scroll 1 slide at a time
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };


    if (loading) {
        return <Loader />;
    }



    return (
        <div>
            {
                //  loading ? (
                //     <Loader />
                // ) : filteredItems.length === 0 ? (

                //         <ErrorPage />

                // ) : 
                (
                    <div className='font-poppins bg-[#101010]  '>
                        <LoginHeader />
                        <Toaster />
                        <div className='Content1 pb-20' style={{ backgroundImage: 'url("/assets/FABC.png")' }}  >
                            <div className='flex justify-center'>
                                <h1 className='text-center text-3xl font-bold'
                                    style={{
                                        backgroundClip: 'text',
                                        WebkitBackgroundClip: 'text',
                                        color: 'transparent',
                                        backgroundImage: 'linear-gradient(to right, #31989B, #4A5CA4)',
                                    }}>Skill Realm</h1>
                            </div>
                            <div className='flex justify-center'>
                                <div className='w-[90%] pt-10 text-white '>
                                    <div className='rounded-full bg-gray-300 flex relative'>
                                        {/* <input className='w-full outline-none px-10 py-2 text-black w-[95%] rounded-full'
                                            placeholder='Search for Skill Id'
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        /> */}

                                        {/* <input className='w-full outline-none px-10 py-2 text-black w-[95%] rounded-full'
                                            placeholder='Search for Skill Id'
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />

                                        <i class="text-black fa-solid fa-magnifying-glass absolute top-[50%] translate-y-[-50%] right-3"></i> */}

                                        {/* <Select className=' outline-none w-[100%] '
                                            inputValueSkill={inputValueSkillSearch}
                                            onInputChange={(value) => setInputValueSkillSearch(value)}
                                            value={selectedSkillSearch}
                                            onChange={handleSkillSearch}
                                            options={SkillOptions}
                                            placeholder='Search for Keywords (eg: React, Solidity.....)'
                                            styles={customStylesSearch}

                                        /> */}

                                        <Select className=' outline-none bg-white border border-sky-500  text-black w-[100%] rounded-full shadow shadow-[0_0_4px_#fff,inset_0_0_4px_#fff,0_0_7px_#08f,0_0_7px_#08f,0_0_4px_#08f]'
                                            inputValueSkill={inputValuePreferredRole}
                                            onInputChange={(value) => setInputValuePreferredRole(value)}
                                            value={selectedPreferredRole}
                                            onChange={handlePreferredRole}
                                            options={preferredRoleOptions}
                                            placeholder='Search for Keywords (eg: Blockchain Engineer, Web3 Analyst.....)'
                                            styles={customStylesSearchForPreferredRole}

                                        />

                                    </div>

                                    <div className="">
                                        <ul className='grid grid-cols-3 gap-3 md:gap-3 md:flex text-center mt-3'>
                                            {selectedPreferredRole.map((option, index) => (
                                                <li className='rounded-full truncate text-xs flex justify-around text-black px-3 py-1 bg-white' key={index}>
                                                    <span className='lg:block hidden'>
                                                        {option.label}
                                                    </span>
                                                    <span className='block lg:hidden'>
                                                        {option.label.length > 8 ? `${option.label.substring(0, 8)}...` : option.label}
                                                    </span>
                                                    <span
                                                        className='ml-1 '
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => removeOptionPreferredRole(index)}>
                                                        &#x2715;
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>

                                    {/* <div className="">
                                        <ul className='grid grid-cols-3 gap-3 md:gap-3 md:flex text-center mt-3'>
                                            {selectedSkillSearch.map((option, index) => (
                                                <li className='rounded-full truncate text-xs flex justify-around text-black px-3 py-1 bg-white' key={index}>
                                                    <span className='lg:block hidden'>
                                                        {option.label}
                                                    </span>
                                                    <span className='block lg:hidden'>
                                                        {option.label.length > 8 ? `${option.label.substring(0, 8)}...` : option.label}
                                                    </span>
                                                    <span
                                                        className='ml-1 '
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => removeOption2Search(index)}>
                                                        &#x2715;
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>

                                    </div> */}

                                    <p className='md:flex justify-between mt-5 text md:text-xs 2xl:text-base font-bold'>
                                        <p className='md:flex gap-7'>
                                            <p>Experience: <span className='ml-2 text-[#0087a5]'>{minExperience || '0-1'} Years </span></p>
                                            <p>Location: <span className='ml-2 text-[#0087a5]'>Any</span></p>
                                            <p>Contract type: <span className='ml-2 text-[#0087a5]'>{selectedWorkModes.length > 0 ? selectedWorkModes.join(', ') : 'Any'}</span></p>
                                            <p>D5art Passport: <span className='ml-2 text-[#0087a5]'>{nftItems.length || 'none'}</span></p>
                                        </p>
                                        <p>Sort by: <span className='ml-2 text-[#0087a5]'>Relevance</span></p>
                                    </p>
                                    <div className='grid grid-cols-2  lg:flex lg:flex-row items-start md:items-center gap-5 mt-3'>
                                        <div className='md:w-[20%]'>
                                            <h2 className='text-sm 2xl:text-base font-bold'>Work mode:</h2>
                                            <Select
                                                className="bg-gray-700 text-white outline-none rounded-full w-full mt-1"
                                                value={workModeQuery}
                                                onChange={handleWorkModeChange}
                                                options={workMode}
                                                styles={customStyles}
                                                placeholder="Select Work Mode"
                                            />

                                        </div>
                                        <div className='md:w-[20%]'>
                                            <h2 className='text-sm 2xl:text-base font-bold'>Experience:</h2>
                                            {/*<input type='range'
                                                className='w-full'
                                                min='0'
                                                max='20'
                                                value={minExperience}
                                                onChange={handleExperienceChange}
                                            />*/}
                                            <Select
                                                className='mt-1'
                                                options={ExperienceOption}
                                                value={ExperienceOption.find(option => option.value === minExperience?.toString()) || null} // Safely handle undefined values
                                                onChange={handleExperienceChange}
                                                styles={customStyles}
                                                placeholder='Experience'
                                            />

                                        </div>

                                        <div className='md:w-[20%]'>
                                            <h2 className='text-sm 2xl:text-base font-bold'>skills:</h2>
                                            <Select
                                                className="bg-gray-700 text-white outline-none rounded-full w-full mt-1"
                                                inputValueSkill={inputValueSkillSearch}
                                                onInputChange={(value) => setInputValueSkillSearch(value)}
                                                value={selectedSkillSearch}
                                                onChange={handleSkillSearch}
                                                options={SkillOptions}
                                                placeholder='Search for Keywords'
                                                styles={customStylesSearch}
                                            />

                                        </div>

                                        {/* <div className='md:w-[20%]'>
                                            <h2 className='text-sm 2xl:text-base font-bold'>Preferred role:</h2>
                                            <Select
                                               className="bg-gray-700 text-white outline-none rounded-full w-full mt-1"
                                               inputValue={inputValuePreferredRole}
                                               onInputChange={(value) => setInputValuePreferredRole(value)}
                                               value={selectedPreferredRole}
                                               onChange={handlePreferredRole}
                                               options={preferredRoleOptions}
                                               placeholder='Search for Preferred role'
                                               styles={customStylesSearch}
                                            />

                                        </div> */}

                                        {/* <div className='md:w-[20%]'>
                                            <p className='font-bold'>Search result: <span className='ml-2 text-[#0087a5] '>{nftItems.length}</span></p>
                                        </div> */}
                                    </div>

                                    {/* to remove the selected skills, work mode and preffered role  */}
                                    <div className='flex justify-start mt-3'>
                                        <ul className='flex justify-start gap-3'>
                                            {selectedWorkModes.map((option, index) => (
                                                <li className='rounded-full text-xs text-black px-3 py-1 bg-white' key={index}>
                                                    {option}
                                                    <span
                                                        className='ml-1'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => removeSelectedWorkMode(index)}
                                                    >
                                                        &#x2715;
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="">
                                        <ul className='grid grid-cols-3 gap-3 md:gap-3 md:flex text-center mt-3'>
                                            {selectedSkillSearch.map((option, index) => (
                                                <li className='rounded-full truncate text-xs flex justify-around text-black px-3 py-1 bg-white' key={index}>
                                                    <span className='lg:block hidden'>
                                                        {option.label}
                                                    </span>
                                                    <span className='block lg:hidden'>
                                                        {option.label.length > 8 ? `${option.label.substring(0, 8)}...` : option.label}
                                                    </span>
                                                    <span
                                                        className='ml-1 '
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => removeOption2Search(index)}>
                                                        &#x2715;
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>


                                    {/*
                                    <div className='lg:flex gap-7 md:h-screen'>
                                        <div className='lg:w-[30%] flex'>
                                            <div className='w-full rounded-2xl border border-sky-800 mt-5 p-5 pt-6 2xl:pt-12'>
                                                <h1 className='font-bold text-xl 2xl:text-2xl'>All Filter:</h1>
                                                <div className='mt-3 px-3'>
                                                    <h2 className='text-sm 2xl:text-base font-bold'>Work mode:</h2>
                                                    <div className="">
                                                        <ul className='grid grid-cols-2 text-center gap-3 mt-3'>
                                                            {selectedWorkModes.map((option, index) => (
                                                                <li className='rounded-full text-xs text-black px-3 py-1 bg-white' key={index}>
                                                                    {option}
                                                                    <span
                                                                        className='ml-1'
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => removeSelectedWorkMode(index)}
                                                                    >
                                                                        &#x2715;
                                                                    </span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>

                                                   
                                                    <p className='flex justify-between text-sm'><p>Fresher</p><p>{minExperience || '10+'}   years</p></p>
                                                    <h2 className='text-sm 2xl:text-base font-bold mt-8'>Skill / Tools:</h2>
                                                    <div className='pt-2'>
                                                        <Select
                                                            inputValueSkill={inputValueSkill}
                                                            onInputChange={(value) => setInputValueSkill(value)}
                                                            value={selectedSkill}
                                                            onChange={handleSkill}
                                                            options={SkillOptions}
                                                            placeholder='Add Skills'
                                                            styles={customStyles}

                                                        />
                                                        <div className="h-[3rem] overflow-x-scroll">
                                                            <ul className='flex text-center gap-3 mt-3'>
                                                                {selectedSkill.map((option, index) => (
                                                                    <li className='rounded-full text-xs text-black px-3 py-1 bg-white' key={index}>
                                                                        {option.label}
                                                                        <span
                                                                            className='ml-1'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => removeOption2(index)}>
                                                                            &#x2715;
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    

                                                </div>
                                            </div>
                                        </div>
                                    </div>
*/}

                                    {filteredItems.length === 0 ? (
                                        // Show image if there are no skills 
                                        <div>
                                            <img src="/assets/no_result_found.png" alt="no projects" className="mx-auto md:w-[20%]" />
                                        </div>
                                    ) : (
                                        <div className=' skill_sets pt-5 gap-4 lg:gap-7'>
                                            {filteredItems.map((item, index) => {
                                                const metadata = JSON.parse(item.metadata);
                                                // //console.log('metadata:',metadata);
                                                const ipfsImage = metadata.image.split('://')[1];
                                                ////console.log('name', metadata.name);
                                                ////console.log('token id', item.token_id);

                                                const locationAttribute = metadata.attributes.find(attr => attr.trait_type === 'location');
                                                ////console.log('location', locationAttribute ? locationAttribute.value : 'Not specified');

                                                const skillsAttributes = metadata.attributes.filter(attr => attr.trait_type.trim().toLowerCase() === 'skills_used');
                                                //console.log("Skills Attributes:", skillsAttributes);

                                                const skills = skillsAttributes.map(attr => attr.value);


                                                // const experienceAttribute = metadata.attributes.find(attr => attr.trait_type === 'experience');
                                                // ////console.log('experience', experienceAttribute ? experienceAttribute.value : 'Not specified');


                                                const experience_Attributes = metadata.attributes.filter(attr => attr.trait_type === 'total_experience_years');
                                                const experience = experience_Attributes.map(attr => attr.value);
                                                //console.log('experience123123', experience);


                                                //const  project_noAttribute = metadata.attributes.find(attr => attr.trait_type === 'project_no');
                                                ////console.log('project', project_noAttribute ? project_noAttribute.value : 'Not specified');

                                                const project_noAttribute = metadata.attributes.find(attr => attr.trait_type === 'project_no');
                                                const projectValue = project_noAttribute && project_noAttribute.value !== undefined ? project_noAttribute.value : 0;

                                                const prefered_rollsAttribute = metadata.attributes.filter(attr => attr.trait_type === 'prefered_roll');
                                                const prefered_rolls = prefered_rollsAttribute.map(attr => attr.value);
                                                //console.log('prefered_roll', prefered_rolls);
                                                ////console.log('prefered roll', preferedAttribute ? preferedAttribute.value : 'Not specified');

                                                const tokenAttribute = metadata.attributes.find(attr => attr.trait_type === 'token_id');
                                                ////console.log('toekn', tokenAttribute ? tokenAttribute.value : 'Not specified');

                                                const contract_typeAttribute = metadata.attributes.find(attr => attr.trait_type === 'contract_type');
                                                ////console.log('contract_type', contract_typeAttribute ? contract_typeAttribute.value : 'Not specified');

                                                const slicedName = metadata && typeof metadata.name === 'string' ? metadata.name.slice(0, 7) : '';

                                                return (
                                                    <div>
                                                        <div key={index} className='bg-gradient-to-br from-[#0E2DA7] to-[#0EA4A9] p-[2px] rounded-2xl duration-500 hover:scale-105'>
                                                            <div className='bg-[#191919] h-full rounded-2xl'>
                                                                <div className='py-3 px-5 bg-gradient-to-b from-[#AA979724] to-[#008CFF24] h-full rounded-2xl '>
                                                                    <div className='relative'>
                                                                        <h1 className='text-lg my-auto 2xl:text-xl font-bold  text-center'>Skill ID : <span className='text-[#0EA4A9]'>{slicedName}</span></h1>
                                                                        <button onClick={() => handleViewProfile(item.token_id)} className='absolute top-1 right-0 px-3 py-[2px] rounded-full bg-[#00768B] uppercase text-xs 2xl:text-sm'>
                                                                            <p>View</p>
                                                                        </button>
                                                                    </div>
                                                                    <div className='flex flex-col md:flex-row'>
                                                                        <div className='md:w-[50%]  m-auto'>
                                                                            {/* <div>
                                                                                <img className='' src={getImageFromMetadata(item.metadata)}></img>
                                                                            </div> */}

<div>
                                                                            <div>
                                                                                <img
                                                                                    className=""
                                                                                    src={
                                                                                        getImageFromMetadata(item.metadata).includes("ipfs.io")
                                                                                            ? getImageFromMetadata(item.metadata).replace(
                                                                                                "ipfs.io",
                                                                                                "fabc-d5art.infura-ipfs.io"
                                                                                            )
                                                                                            : getImageFromMetadata(item.metadata)
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        
                                                                        </div>
                                                                        <div className='md:w-[50%]'>
                                                                            <div className=' flex flex-row gap-3 justify-between text md:text-sm 2xl:text-base py-5'>
                                                                                <p><i class="fa-solid fa-briefcase mr-2"></i>{experience} years</p>
                                                                                <p><i class="fa-solid fa-diagram-project mr-2"></i>Projects: {projectValue}</p>
                                                                            </div>
                                                                            <div className='text-center text-[#16A34A] pb-3 font-bold text-'>
                                                                                <p>{contract_typeAttribute ? contract_typeAttribute.value : 'Not specified'}</p>
                                                                            </div>
                                                                            <div className=''>
                                                                                {/*
                                                                            {skills.map((skillString, index) => {
                                                                                try {
                                                                                    // Parse the JSON string into an array of skills
                                                                                    const skillsArray = JSON.parse(skillString);

                                                                                    // Render buttons for each skill in the array
                                                                                    return skillsArray.map((skill, skillIndex) => (
                                                                                        <div key={`${index}-${skillIndex}`}>
                                                                                            <button
                                                                                                className='mb-1 px-3 py-2 text-sm text-center rounded-full bg-white/20 uppercase'
                                                                                            >
                                                                                                {skill.length > 12 ? `${skill.substring(0, 12)}...` : skill}
                                                                                            </button>
                                                                                        </div>
                                                                                    ));
                                                                                } catch (error) {
                                                                                    console.error("Error parsing skill:", error);
                                                                                    return null; // Return null if there's an error parsing the JSON
                                                                                }
                                                                            })}*/}
                                                                                <SkillSlider skills={skills} />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='bg-[#5E5F63] border border-[#838383] rounded-3xl px-5 mt-3 py-2'>
                                                                        <p className='font-bold md:text-sm 2xl:text-base uppercase text-white/50 text-center mb-2 tracking-wider'>Preferred Role:</p>
                                                                        <div className='text-sm 2xl:text-base '>
                                                                            {prefered_rollsAttribute && prefered_rollsAttribute.length > 0 ? (
                                                                                // Check if any roles exist inside the prefered_rollsAttribute
                                                                                prefered_rollsAttribute.some((prefered_roll) => prefered_roll.value && JSON.parse(prefered_roll.value).length > 0) ? (
                                                                                    <Slider {...sliderSettings} className='w-[95%] mx-auto'>
                                                                                        {prefered_rollsAttribute.map((prefered_roll, index) => {
                                                                                            if (prefered_roll.value) {
                                                                                                try {
                                                                                                    const preferedRoles = JSON.parse(prefered_roll.value);
                                                                                                    return preferedRoles.map((preferedRole, roleIndex) => (
                                                                                                        <div key={`${index}-${roleIndex}`}>
                                                                                                            <div className='uppercase truncate pl-7 pr-5 md:py-1 text-center text-bold bg-[#2B2B2B] rounded-full'>{preferedRole}</div>
                                                                                                        </div>
                                                                                                    ));
                                                                                                } catch (error) {
                                                                                                    console.error('Error parsing preferred role data:', error);
                                                                                                    return null;
                                                                                                }
                                                                                            } else {
                                                                                                return null;
                                                                                            }
                                                                                        })}
                                                                                    </Slider>
                                                                                ) : (
                                                                                    <div className='uppercase truncate pl-7 pr-5 md:py-1 text-center bg-[#2B2B2B] rounded-full'>No preferred role</div>
                                                                                )
                                                                            ) : (
                                                                                <div className='uppercase truncate pl-7 pr-5 md:py-1 text-center bg-[#2B2B2B] rounded-full'>No preferred role</div>
                                                                            )}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {ViewProfilePopup && (
                                                            <div className="z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black/5">
                                                                <div className='w-[95%] md:w-[30%] p-3 bg-black rounded-3xl shadow-sm shadow-sky-700 border border-sky-700 animate__animated animate__fadeIn'>
                                                                    <p className='text-end'><FontAwesomeIcon icon={faXmark} className='cursor-pointer' onClick={closeViewProfilePopup}></FontAwesomeIcon></p>
                                                                    <div className='flex'>
                                                                        <div className=' flex justify-center items-center w-[45%]'><img src='/assets/viewProfile.png'></img></div>
                                                                        <div className='w-[55%] grid content-center'>
                                                                            <p className='text-center '>Pay 1 D5art token to view this profile</p>
                                                                            <div className='mt-3 flex justify-center'>
                                                                                <button className='font-play text-sm px-5 py-2 border border-sky-700 shadow-sm shadow-sky-700 rounded-lg text-white' onClick={() => handleButtonClick(selectedTokenId)} >
                                                                                    Confirm
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                );
                                            })}


                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            <Footer />
        </div>
    )
}