
import { Routes, Route, Navigate, BrowserRouter as Router } from 'react-router-dom';
import React, { Suspense } from 'react';
import ProtectedRoute from './ProtectedRoute'

import Home from './Home2'; 
import Register from './Register2';
import SkillArena from './SkillArena';
import Congrats from './Congrats';

import SeekerRegistration from './SeekerRegistration2';
import ValidatorRegistration from './ValidatorRegistration2';

import Faq from './Faq';


//Seeker

import SeekerHome from './Seeker/SeekerHome';

import SeekerLoginHeader from './Seeker/LoginHeader';
import SeekerSkillmarket from './Seeker/skillmarket3';
import SeekerUserDetails from './Seeker/UserDetails';
import SeekerSkillArenalist from './Seeker/SeekerSkillArenalist2';
import SeekerSkillArena from './Seeker/SeekerSkillArena';

import SeekerChatuser from './Seeker/Chatuser2';
import HollandAssessment from './JordanEvent/Assessment/Assessment';
import HollandAssessmentResult from './JordanEvent/Assessment/AssessmentResult/Result';

// seeker marketplace from db

import DbSeekerSkillArenalist from './Seeker/DbSeekerSkillArenalist';
import DbSeekerSkillmarket from './Seeker/DbSkillmarket';

import SeekerAssessment from './Seeker/Assessment';

import EditProfile from './Seeker/EditProfile';


import SeekerFAQ from './Seeker/seekerFAQ';

import Seeker_update_registration from './Seeker/seeker_update_registration';

import Seeker_update_registration2 from './Seeker/seeker_update_registration2';
import Seeker_update_registration3 from './Seeker/seeker_update_registration3';




// recruiter

import RecruiterHome from './Recruiter/RecruiterHome';
import RecruiterHeader from './Recruiter/Header';
import RecruiterLoginHeader from './Recruiter/LoginHeader';
import RecruiterSkillmarket from './Recruiter/recruitermarketplace3';
import RecruiterUserDetails from './Recruiter/UserDetails';
import RecruiterSkillArenalist from './Recruiter/RecruiterSkillArenalist2';
import RecruiterSkillArena from './Recruiter/RecruiterSkillArena';

import RecruiterFAQ from './Recruiter/recruiterFAQ';




import RecruiterRecruiterDashboard from './Recruiter/Recruiter2Dashboard';

 // Validator 


import ValidatorHeader from './Validator/ValidatorHeader';
import ValidatorHome from './Validator/ValidatorHome';
import ValidatorCreate from './Validator/validatorCreate';
import ValidatorMangeQA from './Validator/validatorManageQA';
import ValidatorResultQA from './Validator/validatorResultQA';
import ValidatorValidateQA from './Validator/validatorValidateQA';
import ValidatorWalletPage from './Validator/ValidatorWalletpage';
import ValidatorDashboard from './Validator/validatorDashboard';

import ValidatorFAQ from './Validator/validatorFAQ';


// Admin

import AdminLogin from './Admin/AdminLogin';
import AdminJobSeekerList from './Admin/SeekerList';
import AdminJobSeeker from './Admin/JobSeeker';
import AdminRecruiterList from './Admin/RecruiterList';
import AdminTicket from './Admin/AdminTicket';
import AdminDashboard from './Admin/AdminDashboard';
import AdminTokenManagement from './Admin/AdminTokenManagement';


import Demo from './demo';


import ErrorPage from './please_wait_page';
import Spinner from "./Seeker/Spinners";
import Loader from './Assessment_loader';
//import { Spinner } from '@material-tailwind/react';

//DNA
import DnaHome from './DNA/DnaHome';

//Event
import EventHome from './JordanEvent/Pages/Home';
import AddTeamMembers from './JordanEvent/Pages/AddTeamMembers';
import Instruction from './JordanEvent/Pages/Instruction';
import Assessment from './JordanEvent/Pages/Assessment';
import TeamMembers from './JordanEvent/Pages/TeamMembers';
import UpdateTeamMembers from './JordanEvent/Pages/UpdateTeamMembers';

import SyncEmail from './SyncEmail';

function Routers() {
    return (
      <Routes>
        <Route path='/syncemail' element={<SyncEmail />} />
      <Route path="/" element={<Home />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/SkillArena" element={<SkillArena />} />
      <Route path="/Congrats" element={<Congrats />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/event/start-assessment" element={<HollandAssessment />} />
      <Route path="/jordan-event/assessment-result" element={<HollandAssessmentResult />} />


      <Route path="/SeekerRegistration" element={<SeekerRegistration />} />
      <Route path="/ValidatorRegistration" element={<ValidatorRegistration />} />

      {/* Seeker */}
      <Route path="/RMS/Seeker/" element={<ProtectedRoute role="seeker"><SeekerHome /></ProtectedRoute>} />

      <Route path="/RMS/Seeker/LoginHeader" element={<SeekerLoginHeader />} />
      <Route path="/RMS/Seeker/SkillArena" element={<ProtectedRoute role="seeker"><SeekerSkillArena /></ProtectedRoute>} />
      <Route path="/RMS/Seeker/SkillArenalist" element={<ProtectedRoute role="seeker"><SeekerSkillArenalist /></ProtectedRoute>} />
      <Route path="/RMS/Seeker/Skillmarket" element={<ProtectedRoute role="seeker"><SeekerSkillmarket /></ProtectedRoute>} />
      <Route path="/RMS/Seeker/UserDetails" element={<ProtectedRoute role="seeker"><SeekerUserDetails /></ProtectedRoute>} />
      <Route path="/RMS/Seeker/SeekerDashboard" element={<ProtectedRoute role="seeker"><SeekerChatuser /></ProtectedRoute>} />
     
      <Route path="/RMS/Seeker/Assessment" element={<ProtectedRoute role="seeker"><SeekerAssessment /></ProtectedRoute>} />
      <Route path="/RMS/Seeker/EditProfile" element={<ProtectedRoute role="seeker"><EditProfile /></ProtectedRoute>} />
      
      {/* <Route path="/updatedetails" element={<ProtectedRoute role="seeker"><Seeker_update_registration /></ProtectedRoute>} /> */}
      <Route path="/ex/seeker/faq" element={<ProtectedRoute role="seeker"><SeekerFAQ /></ProtectedRoute>} />

      <Route path="/updatedetails" element={<ProtectedRoute role="seeker"><Seeker_update_registration3 /></ProtectedRoute>} />
      <Route path="/updatedetails/geminiAi" element={<ProtectedRoute role="seeker"><Seeker_update_registration2 /></ProtectedRoute>} />
      {/* <Route path="/updatedetails/chatgpt" element={<ProtectedRoute role="seeker"><Seeker_update_registration3 /></ProtectedRoute>} /> */}


      <Route path="/RMS/Seeker/db/SkillArenalist" element={<ProtectedRoute role="seeker"><DbSeekerSkillArenalist /></ProtectedRoute>} />
      <Route path="/RMS/Seeker/db/Skillmarket" element={<ProtectedRoute role="seeker"><DbSeekerSkillmarket /></ProtectedRoute>} />


      {/* Recruiter */}
      <Route path="/RMS/Recruiter/" element={<ProtectedRoute role="recruiter"><RecruiterHome /></ProtectedRoute>} />
      <Route path="/RMS/Recruiter/Header" element={<ProtectedRoute role="recruiter"><RecruiterHeader /></ProtectedRoute>} />
      <Route path="/RMS/Recruiter/LoginHeader" element={<RecruiterLoginHeader />} />
      <Route path="/RMS/Recruiter/SkillArena" element={<ProtectedRoute role="recruiter"><RecruiterSkillArena /></ProtectedRoute>} />
      <Route path="/RMS/Recruiter/SkillArenalist" element={<ProtectedRoute role="recruiter"><RecruiterSkillArenalist /></ProtectedRoute>} />
      <Route path="/RMS/Recruiter/Skillmarket" element={<ProtectedRoute role="recruiter"><RecruiterSkillmarket /></ProtectedRoute>} />
      <Route path="/RMS/Recruiter/UserDetails" element={<ProtectedRoute role="recruiter"><RecruiterUserDetails /></ProtectedRoute>} />
      <Route path="/ex/recruiter/faq" element={<ProtectedRoute role="recruiter"><RecruiterFAQ /></ProtectedRoute>} />
      <Route path="/RMS/Recruiter/RecruiterDashboard" element={<ProtectedRoute role="recruiter"><RecruiterRecruiterDashboard /></ProtectedRoute>} />

      {/* Validator */}
      <Route path="/RMS/Validator/Header" element={<ProtectedRoute role="validator"><ValidatorHeader /></ProtectedRoute>} />
      <Route path="/RMS/Validator/" element={<ProtectedRoute role="validator"><ValidatorHome /></ProtectedRoute>} />
      <Route path="/RMS/Validator/Create" element={<ProtectedRoute role="validator"><ValidatorCreate /></ProtectedRoute>} />
      <Route path="/RMS/Validator/MangeQA" element={<ProtectedRoute role="validator"><ValidatorMangeQA /></ProtectedRoute>} />
      <Route path="/RMS/Validator/ResultQA" element={<ProtectedRoute role="validator"><ValidatorResultQA /></ProtectedRoute>} />
      <Route path="/RMS/Validator/ValidateQA" element={<ProtectedRoute role="validator"><ValidatorValidateQA /></ProtectedRoute>} />
      <Route path="/RMS/Validator/Wallet" element={<ProtectedRoute role="validator"><ValidatorWalletPage /></ProtectedRoute>} />
      <Route path="/RMS/Validator/validatorDashboard" element={<ProtectedRoute role="validator"><ValidatorDashboard /></ProtectedRoute>} />
      <Route path="/ex/validator/faq" element={<ProtectedRoute role="validator"><ValidatorFAQ /></ProtectedRoute>} />

      {/* Admin */}
      <Route path="/RMS/admin/login" element={<AdminLogin />} />
      <Route path="/RMS/Admin/SeekerList" element={<ProtectedRoute role="admin"><AdminJobSeekerList /></ProtectedRoute>} />
      <Route path="/RMS/Admin/SeekerDetails" element={<ProtectedRoute role="admin"><AdminJobSeeker /></ProtectedRoute>} />
      <Route path="/RMS/Admin/RecruiterList" element={<ProtectedRoute role="admin"><AdminRecruiterList /></ProtectedRoute>} />
      <Route path="/rms/admin/ticket" element={<ProtectedRoute role="admin"><AdminTicket /></ProtectedRoute>} />
      <Route path="/rms/admin/dashboard" element={<ProtectedRoute role="admin"><AdminDashboard /></ProtectedRoute>} />
      <Route path="/rms/admin/token" element={<ProtectedRoute role="admin"><AdminTokenManagement /></ProtectedRoute>} />

      {/* Miscellaneous */}
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/Spinner" element={<Spinner />} />
      <Route path="/loader" element={<Loader />} />
      <Route path="*" element={<ErrorPage />} />

      {/* DNA */}
      <Route path="/dna_home" element={<DnaHome/>} />

      {/* Event */}
      <Route path="/jordan-event" element={<ProtectedRoute role="seeker"><EventHome /></ProtectedRoute>} />
      <Route path="/jordan-event/add-teammembers" element={<ProtectedRoute role="seeker"><AddTeamMembers /></ProtectedRoute>} />
      <Route path="/jordan-event/instruction" element={<ProtectedRoute role="seeker"><Instruction /></ProtectedRoute>} />
      <Route path="/jordan-event/start-assessment" element={<ProtectedRoute role="seeker"><Assessment /></ProtectedRoute>} />
      <Route path="/jordan-event/team-members" element={<ProtectedRoute role="seeker"><TeamMembers /></ProtectedRoute>} />
      <Route path="/jordan-event/update-team-members" element={<ProtectedRoute role="seeker"><UpdateTeamMembers /></ProtectedRoute>} />

  </Routes>
  );
}

 export default Routers;




// import React, { Suspense } from 'react';
// import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

// const ProtectedRoute = React.lazy(() => import('./ProtectedRoute'));
// const Home = React.lazy(() => import('./Home'));
// const Register = React.lazy(() => import('./Register'));
// const SkillArena = React.lazy(() => import('./SkillArena'));
// const Congrats = React.lazy(() => import('./Congrats'));
// const SeekerRegistration = React.lazy(() => import('./SeekerRegistration'));
// const ValidatorRegistration = React.lazy(() => import('./ValidatorRegistration'));
// const Faq = React.lazy(() => import('./Faq'));

// // Seeker
// const SeekerHome = React.lazy(() => import('./Seeker/SeekerHome'));
// const SeekerHeader = React.lazy(() => import('./Seeker/Header'));
// const SeekerLoginHeader = React.lazy(() => import('./Seeker/LoginHeader'));
// const SeekerSkillmarket = React.lazy(() => import('./Seeker/SeekerSkillmarket'));
// const SeekerUserDetails = React.lazy(() => import('./Seeker/UserDetails'));
// const SeekerSkillArenalist = React.lazy(() => import('./Seeker/SeekerSkillArenalist'));
// const SeekerSkillArena = React.lazy(() => import('./Seeker/SeekerSkillArena'));
// const SeekerChatuser = React.lazy(() => import('./Seeker/Chatuser'));
// const SeekerMyNFT = React.lazy(() => import('./Seeker/MyNFT'));
// const SeekerMyOffers = React.lazy(() => import('./Seeker/MyOffers'));
// const SeekerAssessment = React.lazy(() => import('./Seeker/Assessment'));
// const EditProfile = React.lazy(() => import('./Seeker/EditProfile'));
// const UpdateRegisterDetails = React.lazy(() => import('./Seeker/update_register_details'));
// const SeekerFAQ = React.lazy(() => import('./Seeker/seekerFAQ'));
// const SeekerUpdateRegistration = React.lazy(() => import('./Seeker/seeker_update_registration'));

// // Recruiter
// const RecruiterHome = React.lazy(() => import('./Recruiter/RecruiterHome'));
// const RecruiterHeader = React.lazy(() => import('./Recruiter/Header'));
// const RecruiterLoginHeader = React.lazy(() => import('./Recruiter/LoginHeader'));
// const RecruiterSkillmarket = React.lazy(() => import('./Recruiter/RecruiterSkillmarket'));
// const RecruiterUserDetails = React.lazy(() => import('./Recruiter/UserDetails'));
// const RecruiterSkillArenalist = React.lazy(() => import('./Recruiter/RecruiterSkillArenalist'));
// const RecruiterSkillArena = React.lazy(() => import('./Recruiter/RecruiterSkillArena'));
// const RecruiterFAQ = React.lazy(() => import('./Recruiter/recruiterFAQ'));
// const RecruiterDashboard = React.lazy(() => import('./Recruiter/RecruiterDashboard'));

// // Validator
// const ValidatorHeader = React.lazy(() => import('./Validator/ValidatorHeader'));
// const ValidatorHome = React.lazy(() => import('./Validator/ValidatorHome'));
// const ValidatorCreate = React.lazy(() => import('./Validator/validatorCreate'));
// const ValidatorMangeQA = React.lazy(() => import('./Validator/validatorManageQA'));
// const ValidatorResultQA = React.lazy(() => import('./Validator/validatorResultQA'));
// const ValidatorValidateQA = React.lazy(() => import('./Validator/validatorValidateQA'));
// const ValidatorWalletPage = React.lazy(() => import('./Validator/ValidatorWalletpage'));
// const ValidatorDashboard = React.lazy(() => import('./Validator/validatorDashboard'));
// const ValidatorFAQ = React.lazy(() => import('./Validator/validatorFAQ'));

// // Admin
// const AdminLogin = React.lazy(() => import('./Admin/AdminLogin'));
// const AdminJobSeekerList = React.lazy(() => import('./Admin/SeekerList'));
// const AdminJobSeeker = React.lazy(() => import('./Admin/JobSeeker'));
// const AdminRecruiterList = React.lazy(() => import('./Admin/RecruiterList'));
// const AdminTicket = React.lazy(() => import('./Admin/AdminTicket'));
// const AdminDashboard = React.lazy(() => import('./Admin/AdminDashboard'));
// const AdminTokenManagement = React.lazy(() => import('./Admin/AdminTokenManagement'));

// // Miscellaneous
// const Demo = React.lazy(() => import('./demo'));
// const ErrorPage = React.lazy(() => import('./please_wait_page'));
// const Spinner = React.lazy(() => import('./Spinners'));

// function Routers() {
//     return (
//         <Suspense fallback={<Spinner />}>
        
                // <Routes>
                //     <Route path="/" element={<Home />} />
                //     <Route path="/Register" element={<Register />} />
                //     <Route path="/SkillArena" element={<SkillArena />} />
                //     <Route path="/Congrats" element={<Congrats />} />
                //     <Route path="/faq" element={<Faq />} />
                //     <Route path="/SeekerRegistration" element={<SeekerRegistration />} />
                //     <Route path="/ValidatorRegistration" element={<ValidatorRegistration />} />

                //     {/* Seeker */}
                //     <Route path="/RMS/Seeker/" element={<ProtectedRoute role="seeker"><SeekerHome /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/Header" element={<ProtectedRoute role="seeker"><SeekerHeader /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/LoginHeader" element={<SeekerLoginHeader />} />
                //     <Route path="/RMS/Seeker/SkillArena" element={<ProtectedRoute role="seeker"><SeekerSkillArena /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/SkillArenalist" element={<ProtectedRoute role="seeker"><SeekerSkillArenalist /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/Skillmarket" element={<ProtectedRoute role="seeker"><SeekerSkillmarket /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/UserDetails" element={<ProtectedRoute role="seeker"><SeekerUserDetails /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/SeekerDashboard" element={<ProtectedRoute role="seeker"><SeekerChatuser /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/MyNFT" element={<ProtectedRoute role="seeker"><SeekerMyNFT /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/MyOffers" element={<ProtectedRoute role="seeker"><SeekerMyOffers /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/Assessment" element={<ProtectedRoute role="seeker"><SeekerAssessment /></ProtectedRoute>} />
                //     <Route path="/RMS/Seeker/EditProfile" element={<ProtectedRoute role="seeker"><EditProfile /></ProtectedRoute>} />
                //     <Route path="/rms/seeker/updatedetails" element={<ProtectedRoute role="seeker"><UpdateRegisterDetails /></ProtectedRoute>} />
                //     <Route path="/updatedetails" element={<ProtectedRoute role="seeker"><SeekerUpdateRegistration /></ProtectedRoute>} />
                //     <Route path="/ex/seeker/faq" element={<ProtectedRoute role="seeker"><SeekerFAQ /></ProtectedRoute>} />

                //     {/* Recruiter */}
                //     <Route path="/RMS/Recruiter/" element={<ProtectedRoute role="recruiter"><RecruiterHome /></ProtectedRoute>} />
                //     <Route path="/RMS/Recruiter/Header" element={<ProtectedRoute role="recruiter"><RecruiterHeader /></ProtectedRoute>} />
                //     <Route path="/RMS/Recruiter/LoginHeader" element={<RecruiterLoginHeader />} />
                //     <Route path="/RMS/Recruiter/SkillArena" element={<ProtectedRoute role="recruiter"><RecruiterSkillArena /></ProtectedRoute>} />
                //     <Route path="/RMS/Recruiter/SkillArenalist" element={<ProtectedRoute role="recruiter"><RecruiterSkillArenalist /></ProtectedRoute>} />
                //     <Route path="/RMS/Recruiter/Skillmarket" element={<ProtectedRoute role="recruiter"><RecruiterSkillmarket /></ProtectedRoute>} />
                //     <Route path="/RMS/Recruiter/UserDetails" element={<ProtectedRoute role="recruiter"><RecruiterUserDetails /></ProtectedRoute>} />
                //     <Route path="/ex/recruiter/faq" element={<ProtectedRoute role="recruiter"><RecruiterFAQ /></ProtectedRoute>} />
                //     <Route path="/RMS/Recruiter/RecruiterDashboard" element={<ProtectedRoute role="recruiter"><RecruiterDashboard /></ProtectedRoute>} />

                //     {/* Validator */}
                //     <Route path="/RMS/Validator/Header" element={<ProtectedRoute role="validator"><ValidatorHeader /></ProtectedRoute>} />
                //     <Route path="/RMS/Validator/" element={<ProtectedRoute role="validator"><ValidatorHome /></ProtectedRoute>} />
                //     <Route path="/RMS/Validator/Create" element={<ProtectedRoute role="validator"><ValidatorCreate /></ProtectedRoute>} />
                //     <Route path="/RMS/Validator/MangeQA" element={<ProtectedRoute role="validator"><ValidatorMangeQA /></ProtectedRoute>} />
                //     <Route path="/RMS/Validator/ResultQA" element={<ProtectedRoute role="validator"><ValidatorResultQA /></ProtectedRoute>} />
                //     <Route path="/RMS/Validator/ValidateQA" element={<ProtectedRoute role="validator"><ValidatorValidateQA /></ProtectedRoute>} />
                //     <Route path="/RMS/Validator/Wallet" element={<ProtectedRoute role="validator"><ValidatorWalletPage /></ProtectedRoute>} />
                //     <Route path="/RMS/Validator/validatorDashboard" element={<ProtectedRoute role="validator"><ValidatorDashboard /></ProtectedRoute>} />
                //     <Route path="/ex/validator/faq" element={<ProtectedRoute role="validator"><ValidatorFAQ /></ProtectedRoute>} />

                //     {/* Admin */}
                //     <Route path="/RMS/admin/login" element={<AdminLogin />} />
                //     <Route path="/RMS/Admin/SeekerList" element={<ProtectedRoute role="admin"><AdminJobSeekerList /></ProtectedRoute>} />
                //     <Route path="/RMS/Admin/SeekerDetails" element={<ProtectedRoute role="admin"><AdminJobSeeker /></ProtectedRoute>} />
                //     <Route path="/RMS/Admin/RecruiterList" element={<ProtectedRoute role="admin"><AdminRecruiterList /></ProtectedRoute>} />
                //     <Route path="/rms/admin/ticket" element={<ProtectedRoute role="admin"><AdminTicket /></ProtectedRoute>} />
                //     <Route path="/rms/admin/dashboard" element={<ProtectedRoute role="admin"><AdminDashboard /></ProtectedRoute>} />
                //     <Route path="/rms/admin/token" element={<ProtectedRoute role="admin"><AdminTokenManagement /></ProtectedRoute>} />

                //     {/* Miscellaneous */}
                //     <Route path="/error" element={<ErrorPage />} />
                //     <Route path="/demo" element={<Demo />} />
                //     <Route path="/Spinner" element={<Spinner />} />
                // </Routes>
        
//         </Suspense>
//     );
// }

// export default Routers;
