import React, { useEffect, useState, useCallback } from 'react';
import Header from './LoginHeader';
import './App.css';
import Select from 'react-select';
import { ethers } from 'ethers';

import abi from './abi.json';
import Moralis from 'moralis';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useLocation, Link, NavLink } from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import ApexChart from './ApexChart';

import { format } from 'date-fns';
import { toast, Toaster } from 'react-hot-toast';


import Loader from '../loaderdemo';
import AssessmentLoader from '../Assessment_loader';
import PaymentLoader from '../Payment_loader';
import Footer from '../Footer';
import ErrorPage from '../please_wait_page';
import { avatar } from '@material-tailwind/react';
import CryptoJS from 'crypto-js';
import CurrencyList from '../CurrencyList.json';



export default function Chatuser() {
    const email = useSelector((state) => state.seeker_email);
    //   console.log("Email Logged dasboard 2222222", email)

    const dispatch = useDispatch();

    const Logout = () => {


        dispatch(setSeekerEmail(""));
        window.location = '/';
    };

    //loader
    const [loading, setLoading] = useState(false);
    const [assessmentLoader, setAssessmentLoader] = useState(false);
    const [paymentLoader, setPaymentLoader] = useState(false);


    const [address, setAddress] = useState('');
    const [contract, setContract] = useState('');
    const [balance, setBalance] = useState(null);
    const [status, setStatus] = useState('');
    const [selectedTab, setSelectedTab] = useState('');

    const [mappedTransfers, setMappedTransfers] = useState([]);

    const [data, setData] = useState([]);
    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setclosedCount] = useState(0);
    const [resolvedCount, setResolvedCount] = useState(0);

    const [interestName, setInterestName] = useState('');
    const [interestEmail, setInterestEmail] = useState('');
    const [interestContractType, setInterestContractType] = useState('');
    const [receivedName, setReceivedName] = useState('');
    const [receivedEmail, setReceivedEmail] = useState('');
    const [receivedContractType, setReceivedContractType] = useState('');

    const [interestDateCreated, setInterestDateCreated] = useState('');
    const [interestCount, setinterestCount] = useState('');

    const [totalEmailCount, setTotalEmailCount] = useState('');
    const [totalAppearanceCount, setTotalAppearanceCount] = useState('0');


    const [likedTokenId, setLikedTokenId] = useState('');

    const [seekerTokenId, setSeekerTokenId] = useState('');
    const [seekerWalletAddress, setSeekerWalletAddress] = useState('');
    const [seekerNameFromDB, setSeekerNameFromDB] = useState('');
    const [seekerContractTypeFromDB, setSeekerContractTypeFromDB] = useState('');
    const [seekerAvatar, setSeekerAvatar] = useState('');
    const [seekerAmount, setSeekerAmount] = useState('');



    //  console.log("", seekerWalletAddress)


    const [interestData, setInterestData] = useState([]);
    const [interestData1, setInterestData1] = useState([]);

    const [seekerOfferDetails, setSeekerOfferDetails] = useState([]);
    const [recruiterOfferEmail, setRecruiterOfferEmail] = useState('');
    const [SeekerOfferStatus, setSeekerOfferStatus] = useState('');
    const [SeekerSigendOfferStatus, setSeekerSignedOfferStatus] = useState('');


    //  console.log("seeker avatar", interestData)

    const [nftItems, setNftItems] = useState([]);

    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);


    const [token_id, setToken_id] = useState('');

    const [metadataName, setMetadataName] = useState('');


    const location = useLocation();

    const [displayedTransactions, setDisplayedTransactions] = useState([]);

    const [creditData, setCreditData] = useState([]);
    const [debitData, setDebitData] = useState([]);
    const [months, setMonths] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);


    const [insufficientBalance, setInsufficientBalance] = useState(false);

    const [validationStatus, setValidationStatus] = useState(null);
    const [formattedSetNames, setFormattedSetNames] = useState([]);
    const [result, setResult] = useState('');







    // div 1   my nft



    const [userData, setUserData] = useState(null);
    const [hoveredCourse, setHoveredCourse] = useState(null);
    const [hoveredPercentage, setHoveredPercentage] = useState(null);
    const [tooltip, setTooltip] = useState({ visible: false, percentage: 0, position: { top: 0, left: 0 } });
    const [hoveredSkillPercentage, setHoveredSkillPercentage] = useState(null);


    useEffect(() => {
        const fetchData_seeker = async (email) => {
            try {
                const response = await axios.get(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${email}`);
                //  console.log("response", response);
                const responseData = response.data;

                if (responseData && responseData.data && responseData.data.length > 0) {
                    setUserData(responseData.data[0]);
                    //  console.log("setuserdata", responseData.data[0]);
                    const data = responseData.data[0];
                    console.log("dattaaaa", data);



                    if (data) {
                        setSeekerAmount(data.amount)
                        setSeekerWalletAddress(data.walletAddress);
                    }

                    if (data.token_Id) {
                        setSeekerTokenId(data.token_Id);
                        //  setSeekerWalletAddress(data.walletAddress);
                        setSeekerNameFromDB(data.name);
                        setSeekerContractTypeFromDB(data.contract_type);
                        setSeekerAvatar(data.avatar)
                        // setSeekerAmount(data.amount)
                    } else {
                        console.error('Data format error: token_Id not found in response data');
                    }
                } else {
                    console.error('No data found in response');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData_seeker(email);
        }
    }, [email]);

    // console.log("userData333333333", userData);

    // to show hover percentage on progress bar
    const handleMouseEnter = (courseName) => {
        setHoveredCourse(courseName);
    };

    const handleMouseLeave = () => {
        setHoveredCourse(null);
    };


    const handleMouseOver = (e, percentage) => {
        const rect = e.target.getBoundingClientRect();
        setTooltip({
            visible: true,
            percentage,
            position: {
                top: rect.top + window.scrollY - 30, // Position above the bar
                left: rect.left + window.scrollX + rect.width / 2 // Position at the center of the bar
            }
        });
    };

    const handleMouseOut = () => {
        setTooltip({ visible: false });
    };

    const handleMouseEnterCourse = (courseScore) => {
        setHoveredPercentage(courseScore);
    };

    const handleMouseLeaveCourse = () => {
        setHoveredPercentage(null);
    };



    if (userData && typeof userData.platform_skills === 'string') {
        userData.platform_skills = JSON.parse(userData.platform_skills);
    }

    console.log('userData.platform_skills:', userData?.platform_skills);

    const groupedPlatformSkills = userData?.platform_skills?.reduce((acc, skill) => {
        const courseName = skill.course || 'Unknown Course';
        if (!acc[courseName]) {
            acc[courseName] = [];
        }
        acc[courseName].push(skill);
        return acc;
    }, {});


    console.log('Grouped Platform Skills:', groupedPlatformSkills);

    const getCourseScore = (courseName) => {
        let certificates = userData?.platform_certificates;

        // Check the type of certificates
        console.log("certificates before processing:", certificates);

        // If certificates is a string, parse it
        if (typeof certificates === 'string') {
            try {
                certificates = JSON.parse(certificates);
            } catch (error) {
                console.error("Error parsing certificates:", error);
                return null; // or handle the error as needed
            }
        }

        // Check and log the type
        console.log("certificates after processing:", certificates);
        console.log("Is certificates an array?", Array.isArray(certificates)); // Check if it's an array

        // Proceed if it's an array
        if (!Array.isArray(certificates)) {
            console.error("Expected certificates to be an array, but got:", certificates);
            return null;
        }

        const certificate = certificates.find(cert => cert.course_name === courseName);
        console.log("Found Certificate:", certificate);

        return certificate ? certificate.score : null;
    };


















    // raise a ticket


    const [formData, setFormData] = useState({
        mailId: email,
        subject: '',
        category: '',
        description: ''
    });

    const handleChange1 = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://ex.d5art.com/api/SeekerRaiseTickets', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {

                console.log('Ticket submitted successfully');
                toast.success('Ticket Submitted Successfully');
                window.location.reload();
            } else {

                console.error('Error submitting ticket');
            }
        } catch (error) {
            console.error('Error submitting ticket:', error);
        }
    };


    useEffect(() => {
        const fetchData1 = async () => {
            try {
                // const mail_id = "createsomething100@gmail.com";
                const mail_id = email;
                const response = await fetch(`https://ex.d5art.com/api/SendDataSeekerRaiseTickets/?mail_id=${mail_id}`);
                console.log('3333333333', mail_id)
                const result = await response.json();
                //console.log('666666', result)

                const openIssues = result.data.filter(item => item.status === 'Open');
                const openCount = openIssues.length;

                const closedIssues = result.data.filter(item => item.status === 'Closed');
                const closedCount = closedIssues.length;

                //   console.log('Number of Open Issues:', openCount);
                //  console.log('Number of Closed Issues:', closedCount);


                setOpenCount(openCount);
                setclosedCount(closedCount);
                setData(result);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        fetchData1();
    }, [])


    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5); // Initialize entitiesPerPage in state


    const indexOfLastItem = currentPage * entitiesPerPage;
    const indexOfFirstItem = indexOfLastItem - entitiesPerPage;
    const currentData = Array.isArray(data.data) ? data.data.slice(indexOfFirstItem, indexOfLastItem) : [];
    //  console.log("currentdata", currentData)
    const emptyRowCount = entitiesPerPage - currentData.length;
    // console.log("emptyRowCount", emptyRowCount)

    const handleNextClickTicket = () => {
        if (currentPage < Math.ceil(data.data.length / entitiesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClickTicket = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset currentPage when entitiesPerPage changes
    };


    // connections

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/fetch_details_for_connections?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                //  console.log('555566666', data.data);

                const receivedEmail = 'received_email:';
                const totalCount = data.data.length;


                setTotalEmailCount(totalCount);

                //   console.log('123213213', totalCount)

                if (response.ok) {

                    data.data.forEach(item => {
                        //  console.log('555566666777', item.token_id);


                        setInterestName(item.interest_name);
                        setInterestEmail(item.interest_email);
                        setInterestContractType(item.interest_contract_type);
                        setReceivedName(item.received_name);
                        setReceivedEmail(item.received_email);
                        setReceivedContractType(item.received_contract_type);
                        setInterestDateCreated(item.created_at);
                        setinterestCount(item.interest_count);
                        setLikedTokenId(item.token_id);

                    });
                }

                if (response.ok) {
                    const mappedData = data.data.map(item => ({
                        interestName: item.interest_name,
                        interestEmail: item.interest_email,
                        interestContractType: item.interest_contract_type,
                        receivedName: item.received_name,
                        receivedEmail: item.received_email,
                        receivedContractType: item.received_contract_type,
                        interestDateCreated: item.created_at,
                        interestCount: item.interest_count,
                        tokenId: item.token_id,
                        avatar: item.received_avatar
                    }));

                    setInterestData(mappedData);

                    //    console.log("seeker avatar", mappedData)
                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);



    // interest received

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/fetch_details_interest_received?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                //   console.log('5555666667777777', data.data);

                const receivedEmail = 'received_email:';
                const totalCount = data.data.length;


                setTotalEmailCount(totalCount);

                //  console.log('123213213', totalCount)

                if (response.ok) {

                    data.data.forEach(item => {
                        //  console.log('555566666777', item.like_given_token_id);



                    });
                }

                if (response.ok) {
                    const mappedData = data.data.map(item => ({
                        interestName: item.interest_name,
                        interestEmail: item.interest_email,
                        interestContractType: item.interest_contract_type,
                        receivedName: item.received_name,
                        receivedEmail: item.received_email,
                        receivedContractType: item.received_contract_type,
                        interestDateCreated: item.created_at,
                        interestCount: item.interest_count,
                        tokenId: item.like_given_token_id,
                        receivedAvatar: item.received_avatar,
                    }));

                    setInterestData1(mappedData);

                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);








    // offer letter

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/OfferLetter?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                //   console.log('99999999', data);
                //   console.log('99999999', data.data);


                const recruiter_offer_email = data.data.map(item => item.Recuiter_email);


                //  console.log('000000000000', data.data[0].Recuiter_email);

                if (response.ok) {

                    setSeekerOfferDetails(data.data);
                    setRecruiterOfferEmail(data.data[0].Recuiter_email);
                    setSeekerOfferStatus(data.data[0].offer_status)
                    setSeekerSignedOfferStatus(data.data[0].signed_offerLetter)


                } else {
                    console.error('Error fetching data:', data.error);
                }




            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);




    const [activeFilter, setActiveFilter] = useState('All');

    const filteredOffers = seekerOfferDetails.filter((recruiter) => {
        if (activeFilter === 'All') {
            return true;
        } else {
            return recruiter.offer_status === activeFilter;
        }
    });







    const [isOpenPopup, setOpenPopup] = useState(false);

    const TogglePopup = () => {
        if (balance >= 0) {
            setOpenPopup(!isOpenPopup)
        } else {
            //  console.log("Insufficient balance");
            setInsufficientBalance(true);
        }
    }
    const [isOpenPopup2, setOpenPopup2] = useState(false);

    const ToggleValue = () => {
        setOpenPopup2(!isOpenPopup2);
    };


    const [selectedSkill, setSelectedSkill] = useState(null);
    const handleChange = (selectedOption) => {
        setSelectedSkill(selectedOption);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid black' : '1px solid black', // Black border on hover
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#d5d5d5',
            borderRadius: '10px',
            color: '#000',
            placeholder: {
                color: '#000',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#000', // Color of the selected option text
        }),
    };



    const [isOpenNavbar, setOpenNavbar] = useState(false);
    const handleOpenNavbar = () => {
        setOpenNavbar(!isOpenNavbar)
    }

    const [activeTab, setActiveTab] = useState('nft');
    const [active, setActive] = useState('div1');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tabParam = queryParams.get('tab');

        if (tabParam) {
            handleTabClick(tabParam);
        }
    }, [location]);

    const handleTabClick = (tab) => {
        setActiveTab(tab === activeTab ? null : tab);
        if (tab === 'user') {
            handleAnchorClick('div3');
        } else {
            switch (tab) {
                case 'offers':
                    handleAnchorClick('div2');
                    break;
                case 'chat':
                    handleAnchorClick('div4');
                    break;
                case 'wallet':
                    handleAnchorClick('div5');
                    break;
                case 'Ticket':
                    handleAnchorClick('div6');
                    break;
                case 'Test':
                    handleAnchorClick('div7');
                    break;
                default:
                    handleAnchorClick('div1');
            }
        }
    };

    const handleAnchorClick = (tab) => {
        setActive(tab);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyles = {
        width: '100%',
        paddingTop: '10px',
        color: 'white',
        //backgroundImage: windowWidth <= 767 ? 'none' : 'url("/assets/Skillid.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',

    };

    const iconMap = {
        'nft': (
            <svg className="w-5 h-5 mr-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
            </svg>
        ),
        'offers': (
            <i className="fa-solid fa-envelope-open-text text-xl mr-5"></i>
        ),
        'user': (
            <i className="fa-solid fa-users text-xl mr-5"></i>
        ),
        'chat': (
            <i className="fa-regular fa-message text-xl mr-5"></i>
        ),
        'wallet': (
            <i className="fa-solid fa-wallet text-xl mr-5"></i>
        ),
        'Ticket': (
            <i className="fa-solid fa-question text-xl mr-5"></i>
        ),
        'Test': (
            <i class="fa-solid fa-clipboard-check text-xl mr-5"></i>
        ),
        'Signout': (
            <i className="fa-solid fa-person-through-window text-xl mr-5"></i>
        )
    };

    const TabButton = ({ label, tab }) => {
        return (
            <button
                className={`flex pl-12 w-full py-2.5  ${tab === activeTab ? 'inner-shadow' : ''}`}
                onClick={() => handleTabClick(tab)}
            >
                {iconMap[tab]}
                <span className="ml-2">{label}</span>
            </button>
        );
    };

    const [isAlterchanges, setAlterchanges] = useState(false);
    const handleAlterchanges = () => {
        setAlterchanges(!isAlterchanges)
    }
    const [summary, setSummary] = useState('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.');
    const [RollEnter, setRoll1] = useState('UI Interface Designer');
    const [SkillEnter, setSkill1] = useState('UI Interface Designer');
    const [OrganisationEnter, setOrganisation1] = useState('FABC LLC');
    const [FromToEnter, setFromTo1] = useState('Jan 23 - Aug 23')

    const handleModify = () => {
        setSummary(document.getElementById('summaryTextarea').value);
        setRoll1(document.getElementById('Roll1').value);
        setSkill1(document.getElementById('Skill1').value);
        setOrganisation1(document.getElementById('Organisation1').value);
        setFromTo1(document.getElementById('FromTo1').value);

    };
    const handleAlterAndModify = () => {
        handleAlterchanges();
        handleModify();
    };
    const [texts, setTexts] = useState([]);
    const [inputText, setInputText] = useState('');

    const handleAddText = () => {
        if (inputText.trim() !== '' && texts.length < 3) {
            setTexts((prevTexts) => [...prevTexts, inputText]);
            setInputText('');
        }
    };

    const handleRemoveText = (index) => {
        setTexts((prevTexts) => prevTexts.filter((_, i) => i !== index));
    };

    //

    const [active5, setActive5] = useState('div5');
    const [startIndex, setStartIndex] = useState(0);
    const transactionsPerPage = 5;

    const handleNextClick = () => {
        setStartIndex(prevIndex => prevIndex + transactionsPerPage);
    };

    const handlePrevClick = () => {
        setStartIndex(prevIndex => Math.max(0, prevIndex - transactionsPerPage));
    };




    const filteredTransactions = mappedTransfers
        .filter(transaction =>
            (transaction.toAddress === seekerWalletAddress.toLocaleLowerCase() || transaction.fromAddress === seekerWalletAddress.toLocaleLowerCase()) &&
            transaction.value_decimal !== null
        );

    const uniqueTransactionHashes = new Set();
    const uniqueTransactions = [];

    filteredTransactions.forEach(transaction => {
        if (!uniqueTransactionHashes.has(transaction.transaction_hash)) {
            uniqueTransactionHashes.add(transaction.transaction_hash);
        }
        uniqueTransactions.push(transaction);
    });

    // const displayedTransactions = uniqueTransactions
    //     .slice(startIndex, startIndex + transactionsPerPage);



    //  console.log("displayedTransactions", displayedTransactions)

    // console.log()
    const [seekerEmail, setSeekerEmail] = useState('');
    const [recruiterEmail, setRecruiterEmail] = useState('');



    //  view offer letter 

    const handleViewOffer = async () => {
        const seekerEmail = email;
        const recruiterEmail = recruiterOfferEmail;

        try {
            const response = await fetch(`https://ex.d5art.com/api/OfferLetterDownload?seeker_email=${seekerEmail}&recuiter_email=${recruiterEmail}`, {
                method: 'GET',
            });
            //   console.log("response offer", response)
            if (response.ok) {
                const blob = await response.blob();

                const url = window.URL.createObjectURL(blob);
                //   console.log("url", url);

                // Create a temporary link element
                const a = document.createElement('a');
                a.href = url;
                a.download = 'offer.pdf';
                a.click();
            } else {
                console.error('Error downloading PDF:', response.status);
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    // const handleAccept = async () => {


    //     const seekerEmail = email;
    //     const recruiterEmail = recruiterOfferEmail;

    //     try {
    //         const response = await axios.post('https://ex.d5art.com/api/seekerOfferStatus', {
    //             status: 'accepted',
    //             seeker_email: seekerEmail, 
    //             recruiter_email: recruiterEmail, 
    //             seeker_nftId: userData.nft_Id,
    //         });

    //         window.location.reload();
    //         //   console.log('Success:', response.data);
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };


    const handleAccept = async () => {
        const seekerEmail = email;
        const recruiterEmail = recruiterOfferEmail;

        const loadingToast = toast.loading('Please wait...');

        try {
            const response = await axios.post('https://ex.d5art.com/api/seekerOfferStatus', {
                status: 'accepted',
                seeker_email: seekerEmail,
                recruiter_email: recruiterEmail,
                seeker_nftId: userData.nft_Id,
            });

            toast.success('Offer accepted successfully!');

            window.location.reload();

        } catch (error) {
            console.error('Error:', error);
            toast.error('Error accepting the offer. Please try again.');
        } finally {

            toast.dismiss(loadingToast);
        }
    };


    const handleAcceptRedirect = () => {
        setTimeout(() => {
            handleAnchorClick('div2');
        }, 8000);

    }


    // const handleReject = async () => {


    //     const seekerEmail = email;
    //     const recruiterEmail = recruiterOfferEmail;

    //     try {
    //         const response = await axios.post('https://ex.d5art.com/api/seekerOfferStatus', {
    //             status: 'declined',
    //             seeker_email: seekerEmail,
    //             recruiter_email: recruiterEmail,
    //             seeker_nftId: userData.nft_Id, 
    //         });

    //         window.location.reload();
    //         //  console.log('Success:', response.data);
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };


    const handleReject = async () => {
        const seekerEmail = email;
        const recruiterEmail = recruiterOfferEmail;

        const loadingToast = toast.loading('Please wait...');

        try {
            const response = await axios.post('https://ex.d5art.com/api/seekerOfferStatus', {
                status: 'declined',
                seeker_email: seekerEmail,
                recruiter_email: recruiterEmail,
                seeker_nftId: userData.nft_Id,
            });

            toast.success('Offer rejected successfully!');

            window.location.reload();

        } catch (error) {
            console.error('Error:', error);
            toast.error('Error rejecting the offer. Please try again.');
        } finally {

            toast.dismiss(loadingToast);
        }
    };

    const handleRejectRedirect = () => {
        setTimeout(() => {
            handleAnchorClick('div2');
        }, 8000);

    }




    const handleOfferStatus = async () => {
        // const seekerEmail  ="seeker@gmail.com";
        // const recruiterEmail='batman@gmail.com';

        const seekerEmail = email;
        const recruiterEmail = recruiterOfferEmail;

        try {
            const response = await fetch(`https://ex.d5art.com/api/seekerOfferStatus?seeker_email=${seekerEmail}&recuiter_email=${recruiterEmail}`, {
                method: 'POST',
            });


            //  console.log('Response status:', response.status);

            if (response.ok) {


                // console.log('44444444444', response)
            } else {
                console.error('Error downloading PDF:', response.status);
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

















    const [isPartypopup, setPartypopup] = useState(false);
    const [isCongrats, setCongrats] = useState(false);

    const handlePartypopup = () => {
        setPartypopup(!isPartypopup)
    }


    const handleCongrats = () => {
        setCongrats(!isCongrats)
    }


    const viewProfile = () => {
        const tokenId = seekerTokenId;
        window.location = `/RMS/Seeker/userdetails?tokenId=${tokenId}`;
    };

    const EditProfile = () => {
        const Email = email;
        window.location.href = `/RMS/Seeker/EditProfile?email=${Email}`;
    };



    //


    const [isOpenPayment, setOpenPayment] = useState(false);
    const handlePaymentPopUp = () => {
        setOpenPayment(!isOpenPayment)
    }
    const [isOpenBuy1, setOpenBuy1] = useState(false);
    const [isOpenBuy2, setOpenBuy2] = useState(false);
    const [isOpenBuy3, setOpenBuy3] = useState(false);


    const [windowDimension, setWindowDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [Btn, setBtn] = useState(false);

    const detectSize = () => {
        setWindowDimension({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    const handleBtnpop = () => {
        setBtn(!Btn);
    };

    // Effect for handling resize events
    useEffect(() => {
        const handleResize = () => {
            detectSize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array


    const confettiConfig = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 6000,
        stagger: 3,
        width: '10px',
        height: '10px',
        perspective: '500px',
        colors: ['#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#9400D3'],
    };




    // payment 






    const apiKey = "0b6f16b4d0563ae6f87117706bb9c15256911c5925dfa5c4a92523e269ac5a0f";
    // const razorpayKey = "rzp_test_YrzYxp9mOwo6N9"; // test key
    const razorpayKey = "rzp_live_W0JUBprFlbC0MA"; // live key



    const fetchPrice = async (currency) => {
        if (currency === 'USD') return 1;
        const response = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=${currency}&api_key=${apiKey}`);
        return response.data[currency] || null;
    };


    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [price, setPrice] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const [paymentPopupVisible, setPaymentPopupVisible] = useState(false);
    const wallet_address = seekerWalletAddress;

    // console.log("........", wallet_address, seekerWalletAddress)

    const updatePrice = useCallback(async () => {
        try {
            const newPrice = await fetchPrice(selectedCurrency);
            if (newPrice) {
                setPrice(newPrice);
                setErrorMessage('');
            } else {
                setErrorMessage(`Conversion rate for USD to ${selectedCurrency} is not available.`);
                setPrice(1);
            }
        } catch {
            setErrorMessage('Failed to fetch the price. Please try again later.');
            setPrice(1);
        }
    }, [selectedCurrency]);

    useEffect(() => {
        updatePrice();
    }, [selectedCurrency, updatePrice]);

    const handleCurrencyChange = (event) => {
        let newCurrency;
        if (event.target) {
            newCurrency = event.target.value;
        } else if (event.value) {
            newCurrency = event.value;
        }
        setSelectedCurrency(newCurrency);
        const currency = CurrencyList.find(currency => currency.code === newCurrency);
        setCurrencySymbol(currency ? currency.symbol : newCurrency);
    };

    const handlePayment = async (amount) => {
        try {

            const orderUrl = "https://ex.d5art.com/api/orders";
            const { data } = await axios.post(orderUrl, { amount: amount * price, currency: selectedCurrency });

            //  console.log("Order data:", data);

            if (data.data) {
                initPayment(data.data, amount);
            }
        } catch (error) {
            console.error("Error during payment initiation:", error);
            toast.error("Something went wrong during payment initiation. Please try again.");
        }

    };

    const initPayment = (data, amount) => {

        console.log("data", data)
        const options = {
            key: razorpayKey,
            amount: data.amount,
            currency: data.currency,
            name: email,
            description: "LIVE Transaction",
            image: "/assets/d5art_white.png",
            order_id: data.id,
            handler: async (response) => {
                try {
                    setPaymentLoader(true);

                    //  console.log("Payment response:", response);

                    const verifyUrl = "https://ex.d5art.com/api/seeker_verify";
                    const { data: verifyData } = await axios.post(verifyUrl, {
                        ...response,
                        amount: amount,
                        wallet_address,
                        data,
                        email

                    });

                    //   console.log("Verification response:", verifyData);
                    if (verifyData.status === "success") {

                        setTimeout(() => {
                            toast.success(verifyData.message);
                        }, 3000);
                        //  setPopupMessage2('Payment verified successfully');
                        setOpenPayment(false);
                        setOpenBuy2(true);
                    } else {
                        // setPopupMessage2('Something went wrong! Please Try again.');

                        toast.error("Something went wrong during payment verification. Please try again.");
                    }
                } catch (error) {
                    console.error("Error during payment handling:", error);
                    toast.error("Something went wrong during payment handling. Please try again.");
                }
                setPaymentLoader(false);
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };







    const togglePaymentPopup = () => {
        setPaymentPopupVisible(prev => !prev);
    };

    const plans = [
        { name: 'Genesis', amount: 100, price: price * 100, imgAlt: 'Genesis Plan', textColor: '#CD7F32' },
        { name: 'Exodus', amount: 500, price: price * 500, imgAlt: 'Exodus Plan', textColor: '#C0C0C0' },
        { name: 'Zenith', amount: 1000, price: price * 1000, imgAlt: 'Zenith Plan', textColor: '#FFD700' },
    ];



    const handlePaymentCompletedButton = () => {

        // handleAnchorClick('div5');

        setTimeout(() => {
            window.location = '/RMS/Seeker/SeekerDashboard?tab=wallet';
        }, 3000);
    };





    // get cart details

    const [seekerDetails, setSeekerDetails] = useState([]);


    const [cartId, setCartId] = useState(null);

    //  console.log('888888888888', cartId);


    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/seeker_display_mycart?seeker_email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                //  console.log('66666777', data.data);

                const seeker_name = data.data.map(item => item.seeker_name);
                const tokens = data.data.map(item => item.tokens);
                const contractTypes = data.data.map(item => item.contractType);
                const seeker_email = data.data.map(item => item.seeker_email);
                const cart_id = data.data.map(item => item.id);
                //   console.log('Contract Types:111111111', cart_id);

                if (response.ok) {

                    //setSeekerName(data.data.seeker_name);

                    setSeekerDetails(data.data);


                    setCartId(cart_id);

                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);



    // chat window

    const [displayMessages, setDisplayMessages] = useState([]);
    const [typedMessage, setTypedMessage] = useState('');


    //  console.log("000000000000000", cartId);


    const chat_id = cartId;
    // console.log("000000000000000", chat_id);

    const sendMessage = async () => {

        try {
            if (!typedMessage.trim()) {
                console.log('Empty Message cannot be sent...Please type your message');
                return false;
            }

            // Determine the sender based on whether the user is a recruiter
            // const sender = recruiterEmail === sender_email ? 'recruiter' : 'seeker';

            const data = {
                chat_id: chat_id,
                message_content: typedMessage,
                sender: 'seeker'
            }
            //     console.log(data)
            await axios.post(`https://ex.d5art.com/api/sendMessage`, data).then((resp) => {
                if (resp.data.status == 'success') {
                    setTypedMessage('');
                } else {
                    console.log('something went wrong');
                    return false
                }
            })

        } catch (error) {
            console.error('Validation error:', error);
        }
    };









    useEffect(() => {

        if (chat_id) {

            const getChatMessages = async () => {
                try {
                    const response = await fetch(`https://ex.d5art.com/api/getChatMessages/${chat_id}`, {
                        method: 'GET'
                    });

                    if (response.ok) {
                        const data = await response.json();
                        if (data.status === 'success') {
                            if (Array.isArray(data.data)) {

                                setDisplayMessages(data.data);
                            } else {

                                setDisplayMessages([]);
                            }
                        } else {

                            //   console.log('Failed to fetch chat messages:', data.message);
                            setDisplayMessages([]);
                        }
                    } else {

                        console.error('Failed to fetch chat messages:', response.status);
                        setDisplayMessages([]);
                    }
                } catch (err) {

                    console.error('Error fetching chat messages:', err);
                    setDisplayMessages([]);
                }
            };


            getChatMessages();

            // Fetch messages periodically
            const intervalId = setInterval(getChatMessages, 5000);

            // Cleanup function to clear interval
            return () => clearInterval(intervalId);
        }
    }, [chat_id]);



    const convertDate = (sent_at) => {
        const createdAt = new Date(sent_at);
        const currentDate = new Date();
        const timeDifference = currentDate - createdAt;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const years = Math.floor(days / 365);

        if (seconds < 60) {
            return seconds + ' seconds ago';
        } else if (minutes < 60) {
            return minutes + ' minutes ago';
        } else if (hours < 24) {
            return hours + ' hours ago';
        } else if (days < 365) {
            return days + ' days ago';
        } else {
            return years + ' years ago';
        }

    }



    const handleViewMyInterestProfile = (tokenId) => {
        const Seeker_secretKey = process.env.REACT_APP_SEEKER_SECRETKEY;
        let encryptedTokenId = CryptoJS.AES.encrypt(String(tokenId), Seeker_secretKey).toString();

        //   console.log('view token id for interest', tokenId);
        window.location = `/RMS/Seeker/skillmarket?tokenId=${encodeURIComponent(encryptedTokenId)}`;
    };



    const handleViewInterestReceivedProfile = () => {
        interestData1.map((item, index) => {
            const tokenId = item.tokenId;
            const Seeker_secretKey = process.env.REACT_APP_SEEKER_SECRETKEY;
            let encryptedTokenId = CryptoJS.AES.encrypt(tokenId, Seeker_secretKey).toString();
            // console.log('6666666666', tokenId, encryptedTokenId);
            window.location = `/RMS/Seeker/skillmarket?tokenId=${encodeURIComponent(encryptedTokenId)}`;
        });
    };

    const [selectedSeekerName, setSelectedSeekerName] = useState('');
    const [selectedSeekerEmail, setSelectedSeekerEmail] = useState('');
    const [selectedSeekerContractType, setSelectedSeekerContractType] = useState('');
    const [selectedCartId, setSelectedCartId] = useState({ id: '' });


    const handleChatButtonClick = (seeker) => {

        console.log('Clicked Chat for:', seeker);
        handleAnchorClick('div4');
        setSelectedSeekerName(seeker);
        setSelectedSeekerEmail(seeker);
        setSelectedSeekerContractType(seeker);
        setSelectedCartId(seeker);



    };

    //



    const [selectedSeekerName1, setSelectedSeekerName1] = useState(null);
    const [offerStatus, setOfferStatus] = useState(0);

    const handleSeekerNameClick = (name) => {
        setSelectedSeekerName1(name);
    };



    const Offers = async (num) => {
        const value = num;
        try {
            const response = await fetch(`https://ex.d5art.com/api/OfferLetter?email=${email}`);
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();

            const res = data.data;
            if (value === 0) {
                setOfferStatus(0);
                setSeekerOfferDetails(res);
            }
            else if (value === 1) {

                setOfferStatus(1);
                const final = res.filter(trans => trans.offer_status === "pending");
                setSeekerOfferDetails(final);
            }
            else if (value === 2) {
                setOfferStatus(2);
                const final = res.filter(trans => trans.offer_status === "accepted");
                setSeekerOfferDetails(final);
            } else {
                console.error('Invalid value for offers:', value);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // sorting out the connections
    const [selectedTimeRange, setSelectedTimeRange] = useState('All');
    const handleTimeRangeClick1 = async (timeRange) => {
        //  console.log('Button clicked:', timeRange);

        try {
            let startDate;
            let endDate = new Date();

            switch (timeRange) {
                case 'All':
                    startDate = new Date(0);
                    break;
                case 'Last 24 Hrs':
                    startDate = new Date(Date.now() - 24 * 60 * 60 * 1000);
                    break;
                case 'Last Week':
                    startDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
                    break;
                case 'Last Month':
                    startDate = new Date(new Date().setMonth(new Date().getMonth() - 1));
                    break;
                default:
                    startDate = null;
            }

            //   console.log('startDate:', startDate);

            const response = await fetch(
                `https://ex.d5art.com/api/get_appearance_count?email=${email}&startDate=${startDate?.toISOString()}&endDate=${endDate.toISOString()}`
            );

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            const data = await response.json();
            //   console.log("data", data);
            const totalCount = data.data ? data.data.length : 0;
            setTotalAppearanceCount(totalCount);

            setSelectedTimeRange(timeRange);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        handleTimeRangeClick1('All');
    }, []);

    const connections_my = async (num) => {
        const value = num;
        try {
            const response = await fetch(`https://ex.d5art.com/api/fetch_details_for_connections?email=${email}`);
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();

            const res = data.data;
            if (value === 0) {
                setSeekerOfferDetails(res);
            }
            else if (value === 1) {
                const final = res.filter(trans => trans.offer_status === "pending");
                setSeekerOfferDetails(final);
            }
            else if (value === 2) {
                const final = res.filter(trans => trans.offer_status === "accepted");
                setSeekerOfferDetails(final);
            } else {
                console.error('Invalid value for offers:', value);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    // div 4 
    const getLastMessageTime = () => {
        if (displayMessages.length === 0) {
            return 'none';
        }

        const lastMessage = displayMessages[displayMessages.length - 1];

        const lastMessageTime = convertDate(lastMessage.sent_at);
        return lastMessageTime;
    };






























    // my assesments


    const [QuestionSets, setQuestionSets] = useState({})

    const [validatedData, setValidatedData] = useState([]);
    const [pendingData, setPendingData] = useState([]);
    const [validatorValidatedData, setValidatorValidatedData] = useState([]);


    useEffect(() => {
        const fetchResults = async () => {
            try {
                const response = await axios.get(`https://ex.d5art.com/api/fetching_results?seeker_email=${email}`);

                if (response.data.success) {
                    setValidatedData(response.data.validatedData);
                    setPendingData(response.data.pendingData);
                } else {
                    console.error('Failed to fetch results:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching results:', error);
            }
        };

        fetchResults();
    }, [email]);

    //console.log(validatedData)
    //console.log(pendingData)







    //console.log(validatedData);
    //console.log(pendingData);
    //console.log("0000000", validatorValidatedData);





    const [displayPendingResults, setDisplayPendingResults] = useState(false);
    const [displayValidatedResults, setDisplayValidatedResults] = useState(false);
    const sorting_validated_result_status_validator = async (num) => {
        const value = num;

        try {
            const response = await axios.get(`https://ex.d5art.com/api/fetching_results?seeker_email=${email}`);

            if (!response.data.success) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            const { validatedData } = response.data;

            if (value === 0) {
                setValidatorValidatedData(validatedData);
                setDisplayPendingResults(true);
                setDisplayValidatedResults(true);
            } else if (value === 1) {
                const final = validatedData.filter(trans => trans.status === "Pending");
                setValidatorValidatedData(final);
                setDisplayPendingResults(true);
                setDisplayValidatedResults(false);
            } else if (value === 2) {
                const final = validatedData.filter(trans => trans.status === "Validated");
                setValidatorValidatedData(final);
                setDisplayPendingResults(false);
                setDisplayValidatedResults(true);
            } else {
                console.error('Invalid value for offers:', value);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    // console.log(validatedData);
    // console.log(pendingData);
    //console.log("0000000", validatorValidatedData);

    useEffect(() => {
        // This will trigger the default display of all results when the component mounts
        sorting_validated_result_status_validator(0);
    }, []);



    useEffect(() => {
        const getQ_sets = async () => {
            await axios.get('https://ex.d5art.com/api/getQA_Sets').then((resp) => {
                if (resp.data.status == 'success') {
                    setQuestionSets(resp.data.data)
                }
            })
        }
        getQ_sets()
    }, [])
    //console.log("setnames", QuestionSets)






    const [isLoading, setIsLoading] = useState(false);




    const [projectDescription, setProjectDescription] = useState('');

    const [isUpload, setUpload] = useState(false);

    const handleUpload = () => {
        setUpload(!isUpload)
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const [dragOver, setDragOver] = useState(false);

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         setSelectedFile(file);
    //     }
    // };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {

            const validTypes = ['application/pdf', 'image/png', 'image/jpeg'];
            if (validTypes.includes(file.type)) {

                setSelectedFile(file);
                toast.success('File selected successfully');
            } else {

                toast.error('Invalid file type. Please upload a PDF, PNG, or JPEG.');
                setSelectedFile(null);
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            setSelectedFile(file);
        }
        setDragOver(false);
    };


    const pay_assessment_amount = async () => {
        const seekerAddress = seekerWalletAddress;
        const amount = 10;

        setAssessmentLoader(true);

        const formData = new FormData();
        formData.append('seekerAddress', seekerAddress);
        formData.append('amount', amount);
        formData.append('email', email);
        formData.append('selectedSkill', selectedSkill?.label);
        formData.append('projectDescription', projectDescription);
        if (selectedFile) {
            formData.append('certificate', selectedFile);
        }

        try {
            const response = await axios.post('https://ex.d5art.com/api/transfer_amount_to_admin_for_assessment', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            //   console.log("Response status:", response.status);

            if (response.status === 200) {
                //   console.log('Payment submitted successfully');
                window.location.href = `/RMS/Seeker/Assessment?skill=${selectedSkill?.label}`;
            } else if (response.status === 400) {
                //    console.log('Duplicate entry detected');
                toast.error("Email and question set already exist"); // Show error message if record exists
            } else {
                console.error('Error submitting payment');
                toast.error('Email and question set already exist');
            }
        } catch (error) {
            console.error('Error submitting payment:', error);
            toast.error('Email and question set already exist');
        } finally {
            setAssessmentLoader(false);
        }
    };



    const handleGetValidationStatus = async () => {
        try {
            const response = await axios.post('https://ex.d5art.com/api/getValidationStatusByEmail', { email });
            const data = response.data;
            setValidationStatus(data);


            const formattedNames = data.map(entry => entry.Set_name.substring(11));
            const results = data.map(entry => entry.result);
            const statuses = data.map(entry => entry.status);
            //    console.log("response", response)
            setFormattedSetNames(formattedNames);
            //  console.log("formattedNames", formattedNames)

            setResult(results);
            //   console.log("results", result)
            setStatus(statuses);
            //   console.log("status", status)

        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        if (email) {
            handleGetValidationStatus();
        }
    }, [email]);


    const redirectToDetailsPage = () => {
        window.location = '/RMS/Register'
    }


    // save amount 

    const [inputAmount, setInputAmount] = useState('');

    const handleInputChange = (e) => {
        setInputAmount(e.target.value);
    };



    const saveSeekerAmount = async () => {
        const data = { amount: inputAmount, email };

        if (!inputAmount) {
            toast.error('Amount is required');
            return;
        }

        //  console.log('Data to send:', data);

        try {
            const response = await fetch('https://ex.d5art.com/api/saveSeekerAmount', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            //    console.log(response);

            if (response.status === 200) {
                const responseData = await response.json();
                //    console.log('Success:', responseData);
                window.location.reload();
            } else {
                console.error('Network response was not ok:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };



    //  fetch details from the seeker transactions table


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ex.d5art.com/api/fetch_seeker_transactions?seeker_email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('fetch_seeker_transactions', data.data);

                if (response.ok) {
                    const transactions = data.data;

                    // Sort transactions by created_at in descending order
                    transactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

                    const creditedTransactions = data.data.filter(item => item.status === 'Credited');
                    const debitedTransactions = data.data.filter(item => item.status === 'Debited');

                    // Sum the amounts for both credited and debited transactions
                    const totalCredited = creditedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount || 0), 0);
                    const totalDebited = debitedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount || 0), 0);

                    // -------  Calculate balance  -------
                    const balance = totalCredited - totalDebited;


                    const currentTransactions = transactions.slice(startIndex, startIndex + transactionsPerPage);

                    const monthlyCredited = {};
                    const monthlyDebited = {};

                    const today = new Date();
                    const sixMonthsAgo = new Date();
                    sixMonthsAgo.setMonth(today.getMonth() - 6);

                    transactions.forEach(item => {
                        const transactionDate = new Date(item.created_at);
                        if (transactionDate >= sixMonthsAgo) {
                            const monthYear = transactionDate.toLocaleString('default', { year: 'numeric', month: 'short' }); // Get short month name
                            const value = parseFloat(item.amount) || 0;

                            if (item.status === "Credited") {
                                monthlyCredited[monthYear] = (monthlyCredited[monthYear] || 0) + value;
                            }

                            if (item.status === "Debited") {
                                monthlyDebited[monthYear] = (monthlyDebited[monthYear] || 0) + value;
                            }
                        }
                    });

                    // Create arrays for the last six months
                    const months = [];
                    const creditData = [];
                    const debitData = [];

                    // last six months for display
                    for (let i = 0; i < 6; i++) {
                        const monthDate = new Date();
                        monthDate.setMonth(today.getMonth() - i);
                        const monthYear = monthDate.toLocaleString('default', { year: 'numeric', month: 'short' });
                        months.unshift(monthYear); // Add to the beginning of the array for correct order
                        creditData.unshift(monthlyCredited[monthYear] || 0);
                        debitData.unshift(monthlyDebited[monthYear] || 0);
                    }


                    setBalance(balance);
                    setDisplayedTransactions(currentTransactions);
                    setCreditData(creditData);
                    setDebitData(debitData);
                    setMonths(months);
                    setDataLoaded(true);

                    console.log(`Monthly Credited:`, monthlyCredited);
                    console.log(`Monthly Debited:`, monthlyDebited);
                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email, startIndex]);


    console.log("displayedTransactions", displayedTransactions.length)



    const formatTimestamp = (timestamp) => {
        return new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).format(new Date(timestamp));
    };

    // upload offer letter 

    const [file, setFile] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);


    const handleFileOfferLetter = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUploadOfferLetter = async () => {
        if (!file) {
            toast.error('Offer Letter is required');
            return;
        }

        const seekerEmail = email;
        const recruiterEmail = recruiterOfferEmail;


        const formData = new FormData();
        formData.append('pdfFile', file);
        formData.append('Seeker_email', seekerEmail);
        formData.append('Recruiter_email', recruiterEmail);

        const toastId = toast.loading('Sending offer letter, please wait...');

        try {
            const response = await fetch('https://ex.d5art.com/api/UploadSignedOfferletterFromSeeker', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.message) {
                toast.success(data.message, { id: toastId });
                setIsUploaded(true);  // Set to true after successful upload
                window.location.reload();
            } else {
                toast.error('An error occurred while uploading the offer letter', { id: toastId });
            }

        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('Failed to upload offer letter', { id: toastId });
        } finally {
            toast.dismiss(toastId);
        }
    };


    if (loading) {
        return <Loader />;
    }

    if (assessmentLoader) {
        return <AssessmentLoader />;
    }

    if (paymentLoader) {
        return <PaymentLoader />;
    }



    return (
        <div className='font-poppins bg-[#191919]  '>



            <div className=' font-poppins pb-20 bg-cover bg-no-repeat' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                <Header />
                <Toaster />
                <div className='p-5 lg:flex  md:h-screen'>
                    <div class="hidden md:block w-[25%]">
                        <nav className='h-[39.5rem] bg-black 2xl:h-[40rem]  border border-sky-700 rounded-3xl text-white grid content-around'>
                            <div>
                                <div className='flex py-5'>
                                    <div className='w-[35%] flex justify-end'><div>
                                        {/* <img className='w-[4rem]' src='/assets/avatar.png'></img> */}
                                        <img className='w-[4rem]' src={seekerAvatar ? (seekerAvatar.startsWith('./') ? seekerAvatar.slice(1) : seekerAvatar) : ''} />

                                    </div>
                                    </div>
                                    <div className='w-[65%] pl-2 pt-3'>
                                        <h1 className='text-lg font-bold'>{seekerNameFromDB}</h1>
                                        <p className='text-xs text-gray-400'>  {seekerContractTypeFromDB}</p>

                                    </div>
                                </div>
                                <div>
                                    <TabButton label='My NFT' tab='nft' />
                                    <TabButton label='Offers' tab='offers' />
                                    <TabButton label='Connection' tab='user' />
                                    <TabButton label='Chat window' tab='chat' />
                                    <TabButton label='Wallet' tab='wallet' onClick={() => handleTabClick('wallet')} />
                                    <TabButton label='Tickets / Queries' tab='Ticket' />
                                    <TabButton label='My Assessments' tab='Test' />

                                    <div />

                                </div>
                            </div>
                            <div className=''>

                            </div>
                        </nav>
                    </div>
                    <div className='block md:hidden'>
                        <button onClick={handleOpenNavbar}>
                            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7" />
                            </svg>
                        </button>
                        {isOpenNavbar && (
                            <nav className='w-[80%] z-10 absolute border border-sky-700 rounded-3xl bg-black text-white grid content-around animate__animated animate__fadeInLeft'>
                                <div>
                                    <div className='flex pb-0 pt-5 md:py-5'>
                                        <div className='w-[35%] flex justify-end'><div>
                                            {/* <img className='w-[4rem]' src='/assets/avatar.png'></img> */}
                                            <img className='w-[4rem]' src={seekerAvatar ? (seekerAvatar.startsWith('./') ? seekerAvatar.slice(1) : seekerAvatar) : ''} />

                                        </div>
                                        </div>
                                        <div className='w-[65%] pl-2 pt-3'>
                                            <h1 className='text-lg font-bold'>{seekerNameFromDB}</h1>
                                            <p className='text-xs text-gray-400'>  {seekerContractTypeFromDB}</p>
                                        </div>
                                    </div>
                                    <div className='py-5'>
                                        <TabButton label='My NFT' tab='nft' />
                                        <TabButton label='Offers' tab='offers' />
                                        <TabButton label='Connection' tab='user' />
                                        <TabButton label='Chat window' tab='chat' />
                                        <TabButton label='Wallet' tab='wallet' />
                                        <TabButton label='Tickets / Queries' tab='Ticket' />
                                        <TabButton label='My Assessments' tab='Test' />

                                        <div />

                                    </div>
                                </div>
                                <div className=''>

                                </div>
                            </nav>
                        )}
                    </div>



                    <div id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} className='md:absolute   h-screen  md:w-[72%] right-0 top-32 scrollbar '>
                        <div className="flex flex-col md:flex-row justify-between mt-3  md:px-5">
                            <div><h1 className='font-bold text-2xl  text-white px-5 md:px-0'>My NFT</h1></div>
                            <div className='flex justify-between md:justify-end  md:gap-5'><button className='text-white px-2 md:px-5 py-2 border border-sky-700 shadow-sm shadow-sky-700 inner-shadow2 rounded-lg' onClick={ToggleValue}>Value My Profile</button>
                                <button className='text-white px-2 md:px-5 py-2  border border-sky-700 shadow-sm shadow-sky-700 inner-shadow2 rounded-lg' onClick={TogglePopup}>Take Assessment</button></div>
                        </div>

                        <div className="bg-[#101010] h-[35rem] border border-[#0EA4A96E] shadow-sm shadow-sky-700 rounded-2xl pb-5 mt-5 md:mr-5 overflow-hidden overflow-y-scroll">
                            {isLoading && (

                                <AssessmentLoader />
                                // Show loading spinner while isLoading is true
                                // <div className="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
                                //     <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-gray-900"></div>
                                // </div>
                            )}

                            {insufficientBalance && (
                                <div className="z-10 w-full fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm ">

                                    <div className='w-[95%] lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                        <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={() => setInsufficientBalance(false)}></i></div>
                                        <div className='flex md:h-[17rem] 2xl:[20rem]'>
                                            <div className='w-[40%]'>
                                                <div >
                                                    <img src='/assets/avatar.png' className=' md:absolute md:h-[22.5rem] md:-translate-y-[90px] md:-translate-x-16 2xl:-translate-x-0'></img>
                                                </div>
                                            </div>
                                            <div className='w-[60%] px-3 md:px-0 pb-3 md:pb-0  md:pr-7 text-white grid content-around gap-3 md:gap-0'>
                                                <p className='flex justify-between text-lg'><p>Wallet Balance: </p><span className='text-[#00F4FC]'>{balance}</span></p>
                                                <p className='uppercase text-sm'>Insufficient Balance! You need at least 10 FABC Tokens.</p>
                                                <div className='flex flex-col md:flex-row justify-between gap-3 md:gap-0'>
                                                    <div className='flex'>
                                                        <img src=' /assets/coin.png'></img><p className='mt-1 ml-2 font-play text-[#F29D38]'>10 FABC Tokens</p>
                                                    </div>
                                                    <div>
                                                        <NavLink to='/RMS/Seeker/SeekerDashboard' ><button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg'><button className='bg-black px-4 text-sm py-1 rounded-lg'>Add Funds</button></button>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {isOpenPopup && (
                                <div className="z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                                    <div className='w-[95%] md:w-[50%] bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 animate__animated animate__fadeIn'>
                                        <div className='flex justify-end'><i className='fa-solid fa-close text-lg text-white px-3 py-1' onClick={TogglePopup}></i></div>
                                        <div>
                                            <div className='flex justify-center'>
                                                <h1 className='text-center text-xl font-bold'
                                                    style={{
                                                        backgroundClip: 'text',
                                                        WebkitBackgroundClip: 'text',
                                                        color: 'transparent',
                                                        backgroundImage: 'linear-gradient(to right, #31989B, #4A5CA4)',
                                                    }}>Validation</h1>
                                            </div>
                                            <div className='flex justify-center'>
                                                <div className='w-[80%] flex flex-col gap-7 py-7 '>
                                                    <div>
                                                        <Select
                                                            value={selectedSkill}
                                                            onChange={handleChange}
                                                            options={QuestionSets.filter(Boolean).map((setName, index) => ({
                                                                value: index,
                                                                label: setName,
                                                            }))}
                                                            placeholder='Select the skill you want to validate'
                                                            styles={{
                                                                ...customStyles,
                                                                menu: provided => ({
                                                                    ...provided,
                                                                    maxHeight: '300px',
                                                                    overflowY: 'auto',
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='text-white flex justify-between'>
                                                        <div className='flex items-center'>
                                                            <p>Upload the Cerificate</p>
                                                        </div>
                                                        <div><button onClick={handleUpload} className='font-play text-sm px-5 py-2 border border-sky-700 shadow-sm shadow-sky-700 rounded-lg text-white'>
                                                            Upload
                                                        </button></div>
                                                    </div>
                                                    <div>
                                                        <p className='font-bold text-white'>Instructions:</p>
                                                        <p className='text-xs mt-3 text-[#A09898] uppercase'>1. Please select the skill you wish to validate.<br />
                                                            2. ensure you have the necessary tokens available in your wallet. <br />
                                                            3. proceed with the validation once you have confirmed the payment. </p>
                                                    </div>
                                                    <div className='flex justify-between'>
                                                        {/* <p className='font-play font-bold text-[#F29D38] text-lg'>10 D5ART Tokens</p> */}
                                                        <div>
                                                            <button onClick={pay_assessment_amount} className='font-play text-sm px-5 py-2 border border-sky-700 shadow-sm shadow-sky-700 rounded-lg text-white'>
                                                                Pay & Proceed
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {isUpload && (
                                <div className="z-10 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                                    <div className='w-[95%] md:w-[60%] text-white bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 animate__animated animate__fadeIn'>
                                        <div className='flex justify-end '><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handleUpload}></i></div>
                                        <div className='p-7 pt-0'>
                                            <p>Upload relevant documents or certificates </p>
                                            <div
                                                className={`mx-auto border text-[11px] text-center border-white/50 md:w-[45%] mt-3 border-dashed p-5 rounded-xl ${dragOver ? 'border-blue-500' : ''
                                                    }`}
                                                onDragOver={handleDragOver}
                                                onDragLeave={handleDragLeave}
                                                onDrop={handleDrop}
                                                onClick={() => document.getElementById('fileInput').click()}
                                            >
                                                <img src='/assets/upload.png' className='mx-auto' alt='Upload' />
                                                <input
                                                    type='file'
                                                    id='fileInput'
                                                    className='hidden'
                                                    onChange={handleFileChange}
                                                    accept='.pdf,.png,.jpeg'
                                                />
                                                {selectedFile ? (
                                                    <p className='mt-2'>Selected file: {selectedFile.name}</p>
                                                ) : (
                                                    <>
                                                        <p className='mt-2'>Drag and drop or choose a file to upload.</p>
                                                        <p>All pdf, png, jpeg types are supported</p>
                                                    </>
                                                )}
                                            </div>
                                            <p className='mt-5'>Describe your project/achievement</p>
                                            <textarea
                                                className='bg-transparent outline-none w-[100%] mt-3 rounded-xl py-2 px-5 border border-sky-700'
                                                value={projectDescription}
                                                onChange={(e) => setProjectDescription(e.target.value)}
                                            />
                                            <div className='flex justify-center mt-3'>
                                                <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg'><button className='bg-black px-4 text-sm py-1 rounded-lg' onClick={handleUpload}>Submit</button></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {isOpenPopup2 && (
                                <div className="z-10 w-full fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm ">
                                    <div className='w-[95%] lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                        <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={() => setOpenPopup2(false)}></i></div>
                                        <div className='flex md:h-[17rem] 2xl:[20rem]'>
                                            <div className='w-[40%]'>
                                                <div >
                                                    <img src='/assets/avatar.png' className='md:absolute md:h-[22.5rem] md:-translate-y-[90px] md:-translate-x-16 2xl:-translate-x-0'></img>
                                                </div>
                                            </div>
                                            <div className='w-[60%] px-3 md:px-0 pb-3 md:pb-0  md:pr-7 text-white grid content-center gap-2 md:gap-5'>
                                                <div className='flex flex-col md:flex-row justify-between'>
                                                    <div className='' flex items-center>
                                                        <p className='flex justify-between text-lg'><p>Profile Value </p><span className='text-[#00F4FC]'></span></p>
                                                    </div>
                                                    <div className='flex'>
                                                        <img src=' /assets/coin.png'></img><p className='mt-1 ml-2 font-play text-[#F29D38]'>{seekerAmount} D5ART Tokens</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <input
                                                        type='number'
                                                        className='bg-transparent outline-none w-full rounded-xl py-2 px-5 shadow-md shadow-sky-700 border border-sky-700'
                                                        value={inputAmount}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <p className='mt-2 text-sm text-center'>Hereby, you confirm the fixing of your profile value.</p>
                                                <div className="flex justify-center">
                                                    <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg'><button className='bg-black px-4 text-sm py-1 rounded-lg' onClick={saveSeekerAmount}>Confirm</button></button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}



                            <div className='flex justify-center md:h-auto'>
                                <div style={containerStyles} className='md:border border-[#0EA4A96E] rounded-2xl mx-3 md:mx-5 mt-5'>
                                    <div className='flex justify-center'>

                                        <h1 className='font-bold rounded-full border md:mt-5 px-5 py-2 border-[#FFBA00]'>
                                            D5ART ID: <span className='text-[#00F4FC]' title={userData?.nft_Id} >
                                                {userData && userData.nft_Id ? userData.nft_Id.substring(0, 10) : ''}
                                            </span>

                                        </h1>
                                    </div>



                                    <div className='flex justify-center mt-5 lg:mt-0'>
                                        <div className='flex flex-col gap-5 md:gap-0 lg:flex-row justify-center w-[100%] border md:border-0 border-gray-800 rounded-3xl py-5 md:pb-12 2xl:px-7'>
                                            <div className='md:w-[33%] pl-2'>
                                                <p className='text-[#FFBA00] text-center md:text-start font-bold uppercase text-xl'>Experience</p>
                                                <div className='mt-7 h-[425px] skill1  overflow-hidden overflow-y-scroll'>
                                                    {[userData?.experience1, userData?.experience2, userData?.experience3].map((exp, index) => {
                                                        if (exp) {
                                                            let experienceDetails;
                                                            try {
                                                              experienceDetails = JSON.parse(exp);
                                                            } catch (error) {
                                                              console.error("Error parsing experience data", error);
                                                              return null; // Skip invalid JSON
                                                            }

                                                            const { companyname, from, to, role } = experienceDetails || {};

                                                            if (
                                                              !companyname?.trim() ||
                                                              !from?.trim() ||
                                                              !to?.trim() ||
                                                              !role?.trim()
                                                            ) {
                                                              return null;
                                                            }

                                                            
                                                            return (
                                                                <div key={index} className='mt-3 skill1 overflow-hidden overflow-y-scroll'>
                                                                    <div>
                                                                        <p className='flex justify-between px-3'>
                                                                            <p className='font-bold text-sm'>{role}</p>
                                                                            <p className='text-[10px] mt-1'> {from} - {to} </p>
                                                                        </p>
                                                                        <p className='flex justify-between text-sm px-3 mt-2'>
                                                                            <p className='font-bold text-[#0EA4A9]'>{companyname}</p>
                                                                            <p className='text-[#36A90E] font-bold'>Verified</p>
                                                                        </p>
                                                                        <div className='flex justify-center py-4 '>
                                                                            <div><div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'><div className='p-1.5 rounded-full bg-white'></div></div></div>
                                                                            <div className='h-[1px] w-full bg-white'></div>
                                                                            <div><div className='bg-[#0EA4A9] p-1 rounded-full -translate-y-2.5'><div className='p-1.5 rounded-full bg-white'></div></div></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </div>
                                            </div>
                                            <div className='md:w-[30%] grid content-around'>
                                                <div className='flex  justify-center pt-10 2xl:pt-0'>
                                                    <p className='text-center text-2xl 2xl:text-3xl'>{userData?.contract_type}</p>
                                                </div>
                                                <div >
                                                    {userData && userData.avatar && (
                                                        <div>
                                                            <img className='mx-auto' src={userData.avatar.replace('./assets', '/assets')} alt="User Avatar" />
                                                            {/* <img className='mx-auto' src='/avatar/D5art/avatar1.png' alt="User Avatar" /> */}
                                                            {/* Display userData.avatar in the console */}
                                                            {console.log("User Avatar URL:", userData.avatar)}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='flex justify-center'>
                                                    <a>
                                                        {userData && (userData.is_minted === 1 || userData.is_minted === "1") ? (
                                                            <button className='flex py-2 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
                                                                <button className='p-2 rounded-full translate-y-1.5 group-hover:translate-x-[173px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] '></button>
                                                                <p className='translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out mx-6'
                                                                    onClick={viewProfile}
                                                                >View Profile</p>
                                                                <button className='p-3.5 rounded-full border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>
                                                        ) : (
                                                            <button className='flex py-2 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
                                                                <button className='p-2 rounded-full translate-y-1.5 group-hover:translate-x-[153px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] '></button>
                                                                <p className='translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out mx-6'
                                                                //onClick={EditProfile}
                                                                >

                                                                    {/* Edit */}

                                                                    Minting....
                                                                </p>
                                                                <button className='p-3.5 rounded-full border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>
                                                        )}

                                                    </a>
                                                </div>
                                            </div>
                                            <div className='md:w-[33%] pl-2'>
                                                <p className='text-[#FFBA00] text-center md:text-end font-bold uppercase text-xl'>SKILL AND EXPERTISE</p>
                                                <div className='mt-7 h-[425px] skill1  overflow-hidden overflow-y-scroll'>
                                                    {userData?.proficiency && (
                                                        <div>
                                                            {JSON.parse(userData.proficiency).map((skill, index) => {
                                                                const skillName = skill.skill;
                                                                const matchedSkillIndex = formattedSetNames.findIndex(name => name === skillName);
                                                                const shouldDisplayImage = matchedSkillIndex !== -1 && (result[matchedSkillIndex] === 'PASS' || result[matchedSkillIndex] === null);

                                                                return (
                                                                    <div key={index}>
                                                                        <div className='flex justify-between'>
                                                                            <div>
                                                                                <p className='font-bold mt-2 text-lg text-[#FFBA00]'>{skill.skill}</p>
                                                                            </div>
                                                                            {shouldDisplayImage && <div><img src='/assets/D5ART_Fav icon.png' alt='Skill logo' /></div>}
                                                                        </div>
                                                                        <div className='py-3'>
                                                                            <div className='relative h-2 w-full bg-gray-900 rounded'>
                                                                                <div
                                                                                    className='w-40 h-2 rounded bg-gradient-to-r from-[#13BDB3] to-[#0E14A9]'
                                                                                    style={{ width: `${skill.percentage}%` }}
                                                                                    onMouseEnter={() => setHoveredPercentage(skill.percentage)}
                                                                                    onMouseLeave={() => setHoveredPercentage(null)}
                                                                                />
                                                                                {hoveredPercentage === skill.percentage && (
                                                                                    <span className='absolute left-0 transform -translate-y-full bg-gray-800 text-white text-sm p-1 rounded'>
                                                                                        {skill.percentage}%
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}

                                                            {/* Ensure groupedPlatformSkills is not null/undefined */}
                                                            {groupedPlatformSkills && Object.keys(groupedPlatformSkills).map((courseName, courseIndex) => {
                                                                const courseScore = getCourseScore(courseName);

                                                                return (
                                                                    <div key={courseIndex}>
                                                                        <div className='flex justify-between text-[#FFBA00]'>
                                                                            <p
                                                                                className='font-bold cursor-pointer uppercase flex justify-between w-full mt-2'
                                                                                onMouseEnter={() => handleMouseEnter(courseName)}
                                                                                onMouseLeave={handleMouseLeave}
                                                                            >
                                                                                {courseName}
                                                                                {hoveredPercentage === courseScore && (
                                                                                    <span className='absolute left-0 transform -translate-y-full bg-gray-800 text-white text-sm p-1 rounded'>
                                                                                        {courseScore ? `${courseScore}%` : 'N/A'}
                                                                                    </span>
                                                                                )}
                                                                            </p>
                                                                            <div>
                                                                                <img src='/assets/D5ART_Fav icon.png' alt='Course logo' />
                                                                            </div>
                                                                        </div>
                                                                        <div className='py-3'>
                                                                            <div className='h-2 rounded w-full bg-gray-900'>
                                                                                <div className='h-2 bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] rounded' style={{ width: `${courseScore || 0}%` }} />
                                                                            </div>
                                                                        </div>

                                                                        {hoveredCourse === courseName && (
                                                                            <div>
                                                                                {groupedPlatformSkills[courseName].map((item, subIndex) => {
                                                                                    const skillName = item.name;
                                                                                    const percentage = item.points;

                                                                                    const matchedSkill = formattedSetNames.find(name => name === skillName);
                                                                                    const shouldDisplayImage = matchedSkill && (result[subIndex] === 'PASS' || result[subIndex] === null);

                                                                                    return (
                                                                                        <div key={subIndex}>
                                                                                            <div className='flex justify-between'>
                                                                                                <p className='font-bold mt-2 text-lg text-[#FFBA00]'>
                                                                                                    {skillName} <span className='text-[#0EA4A9]'>- {percentage}</span>
                                                                                                </p>
                                                                                                {shouldDisplayImage && <img src='/assets/D5ART_Fav icon.png' alt='Skill logo' />}
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}













                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/*
                            <div className='flex justify-center md:-translate-y-8 mt-5 md:mt-0'>
                                <a>
                                    {userData && (userData.is_minted === 1 || userData.is_minted === "1") ? (
                                        <button className='flex py-2 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
                                            <button className='p-2 rounded-full translate-y-1.5 group-hover:translate-x-[173px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] '></button>
                                            <p className='translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out mx-6'
                                                onClick={viewProfile}
                                            >View Profile</p>
                                            <button className='p-3.5 rounded-full border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                        </button>
                                    ) : (
                                        <button className='flex py-2 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white'>
                                            <button className='p-2 rounded-full translate-y-1.5 group-hover:translate-x-[153px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] '></button>
                                            <p className='translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out mx-6'
                                            //onClick={EditProfile}
                                            >
                            Minting....
                        </p>
                        <button className='p-3.5 rounded-full border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                    </button>
                                    )}

                </a>
            </div>
*/}
                            <div className='flex justify-center text-white mt-10 md:mt-5'>
                                <div className='w-[90%] lg:flex'>
                                    <div className='lg:w-[55%]'>
                                        <h1 className='uppercase text-[#646464] text-2xl font-bold'>Summary:</h1>
                                        <p className='p-3 border  border-yellow-500 rounded-2xl uppercase text-sm'>{userData?.description} </p>
                                    </div>

                                    <div className='lg:w-[45%] 2xl:pl-16'>
                                        <h1 className='uppercase text-[#646464] text-2xl font-bold'>Prefered Role:</h1>
                                        <div className='flex flex-col md:flex-row gap-3 justify-auto md:gap-5 p-5'>
                                            {userData && userData?.jobrole && (
                                                <div className='flex flex-col  gap-3 '>
                                                    {JSON.parse(userData.jobrole).map((role, index) => (
                                                        <div key={index}>
                                                            <p className='uppercase px-3 py-2 md:py-1 text-center bg-gray-500 rounded-full'>{role}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-center text-white mt-7 '>
                                <div className='w-[90%]'>
                                    <h1 className='uppercase text-[#646464] text-2xl font-bold'>Projects:</h1>
                                    <div className='mt-7 md:flex justify-start gap-16 '>
                                        {userData && (
                                            <>
                                                {Array.from({ length: 3 }, (_, i) => i + 1).map((num) => {
                                                    const projectName = userData[`project${num}Name`];
                                                    const projectDescription = userData[`project${num}Description`];
                                                    const projectKeywords = userData[`project${num}Keywords`];

                                                    if (projectName && projectName.trim() !== '') {
                                                        return (
                                                            <div key={num} className=' bg-gradient-to-tl from-[#0EA4A9] to-[#0E2DA7] p-[2px] rounded-3xl md:w-[30%]'>
                                                                <div className='bg-[#101010] p-5 rounded-3xl'>
                                                                    <p className='text-center py-3'>{projectName}</p>
                                                                    {projectKeywords && projectKeywords !== '[]' && (
                                                                        <div className='flex justify-around pt-7 pb-5 text-xs'>
                                                                            {JSON.parse(projectKeywords).map((keyword, subIndex) => (
                                                                                <p key={subIndex} className=' py-1 rounded-lg border border-gray-700 text-center w-[28%]'>{keyword}</p>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </>
                                        )}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >




                    <div id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} className='md:absolute text-white h-screen  md:w-[72%] right-0 top-32 scrollbar overflow-hidden overflow-y-scroll'>
                        <div className='mt-3 '>
                            <h1 className='text-2xl font-bold'>My Offers:</h1>
                            <div className='mt-5 md:m-5 md:mr-16 border-2 border-gray-800 rounded-3xl p-5 bg-[#101010] h-[36rem] overflow-y-scroll overflow-hidden'>
                                <h2 className='font-bold text-lg'>Filter <i class="fa-solid fa-filter text-sm text-gray-400 ml-2"></i></h2>
                                <div className='flex gap-5 mt-5'>
                                    <button className={`text-sm text-white rounded-full bg-[#00768B] ${offerStatus === 0 ? 'bg-[#00768B]' : 'bg-opacity-50'} px-2 md:w-[12.5%] py-1`} onClick={() => Offers(0)}>All</button>
                                    <button className={`text-sm text-white rounded-full ${offerStatus === 1 ? 'bg-[#00768B]' : 'border border-[#00768B]'} px-2 md:w-[12.5%] py-1`} onClick={() => Offers(1)}>Pending</button>
                                    <button className={`text-sm text-white rounded-full ${offerStatus === 2 ? 'bg-[#00768B]' : 'border border-[#00768B]'} px-2 md:w-[12.5%] py-1`} onClick={() => Offers(2)}>Accepted</button>
                                </div>
                                <div className='w-[100%] mt-5 '>
                                    <div className='hidden md:block w-full'>
                                        <div className='w-full flex text-center '>
                                            <div className='w-[40%]'><p className=''>Organization</p></div>
                                            <div className='w-[30%]'><p className=''>Applied on</p></div>
                                            <div className='w-[15%]'><p className=''>Status</p></div>
                                            <div className='w-[15%]'><p className=''>Offer</p></div>
                                        </div>
                                    </div>
                                    {seekerOfferDetails.map((recruiter, index) => (
                                        <div key={index} className='rounded-2xl p-2 shadow-sm shadow-sky-300 flex flex-col md:flex-row gap-2 md:gap-0 mt-3 border border-sky-900'>
                                            <div className='md:w-[40%]'>
                                                <div className='flex '>
                                                    <div className='w-[20%] flex justify-center'>
                                                        <div>
                                                            {/* <img src='/assets/NO_text_logo.png' alt='Company Logo'></img> */}
                                                            <img src={recruiter.RecruiterAvatar ? (recruiter.RecruiterAvatar.startsWith('./') ? recruiter.RecruiterAvatar.slice(1) : recruiter.RecruiterAvatar) : ''} className='p-2' />
                                                        </div>
                                                    </div>
                                                    <div className='w-[80%]'>
                                                        <h1 className='text-lg'>{recruiter.organizationName}</h1>
                                                        <p className='text-gray-400'>{recruiter.industryType}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='md:w-[30%] flex items-center justify-center'><p>{new Date(recruiter.created_at).toLocaleString()} IST</p></div>

                                            <div className='text-[#FFBA00] md:w-[15%] flex items-center justify-center'><p>{recruiter.offer_status}</p></div>
                                            <div className='md:w-[15%] flex items-center justify-center'>
                                                <button
                                                    className='text-xs text-white rounded-full bg-[#00768B] px-3 py-1'
                                                    onClick={handlePartypopup}
                                                >
                                                    View Offer
                                                </button>
                                            </div>

                                        </div>
                                    ))}



                                </div>
                            </div>
                        </div>
                    </div>


                    {
                        isPartypopup && (
                            <div className="z-10 w-full fixed inset-0 flex flex-col flex-row items-center justify-center bg-black/50 backdrop-blur-sm ">
                                <div className='w-[95%] lg:w-[60%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                    <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handlePartypopup}></i></div>
                                    <div className='flex flex-col md:flex-row gap-5 md:gap-0 md:h-[24rem] 2xl:[20rem]'>
                                        <div className='w-[100%] md:w-[40%]'>
                                            <div>
                                                <img src='/assets/avatar.png' className='w-[30%] md:w-full mx-auto md:mx-0'></img>
                                            </div>
                                        </div>
                                        <div className='w-[100%] md:w-[60%] px-3 md:pr-5 text-white grid content-around'>
                                            <p className='flex justify-between text-lg'><p>Congratulations! 🎉 </p><span className='text-[#00F4FC]'>{ }</span></p>
                                            <p className='uppercase text-sm text-wrap'>We are thrilled to inform you that your offer letter has been successfully received.<br /> <br />
                                            You are now officially one of the proud NFT CV holders in D5art.<br /><br />
                                             We're excited about the next steps in this journey and look forward to working together.

</p>
                                            <div>
                                                <div className='w-full'>
                                                    <div className='flex justify-center my-2 w-full'>
                                                        <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg'>
                                                            <button className="bg-black px-4 text-sm py-1 rounded-lg" onClick={handleViewOffer} >Download Offer</button>
                                                        </button>
                                                    </div>
                                                    {SeekerSigendOfferStatus === null && (
                                                    <div className='relative mb-4 mt-4 flex gap-3'>
                                                        <div className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg w-[70%]'>
                                                            <div className='bg-black px-3 text-sm py-1 rounded-lg w-full' >
                                                                <input type="file" accept=".pdf" onChange={handleFileOfferLetter} className='w-[100%]' />
                                                            </div>
                                                        </div>

                                                        <button
                                                            className="w-[30%] bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg"
                                                            onClick={handleUploadOfferLetter}
                                                        >
                                                            <button className="w-full bg-black px-3 text-sm h-full rounded-lg">Upload</button>
                                                        </button>
                                                       
                                                    </div>
                                                      )}
                                                    {SeekerOfferStatus === 'pending' && (
                                                        <div>
                                                            <div className='flex justify-around my-4'>
                                                                <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg'>
                                                                    <button className={`bg-black px-4 text-sm py-1 rounded-lg ${SeekerSigendOfferStatus === null ? 'cursor-not-allowed' : ''}`} onClick={() => { handleBtnpop(); handleAccept(); handleAcceptRedirect(); handlePartypopup(); }}
                                                                        disabled={SeekerSigendOfferStatus === null}    // Disable until uploaded
                                                                    >Accept</button>
                                                                </button>

                                                                <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg'>
                                                                    <button className="bg-black px-4 text-sm py-1 rounded-lg" onClick={() => { handleBtnpop(); handleReject(); handleRejectRedirect(); handlePartypopup(); }} >Decline</button>
                                                                </button>
                                                            </div>
                                                            {SeekerSigendOfferStatus === null && (
                                                                <p className="text-red-500 text-sm my-3 text-center">Please upload the signed offer letter before accepting.</p>
                                                            )}
                                                        </div>
                                                    )}
                                                    <Confetti active={Btn} config={confettiConfig} />


                                                </div>


                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        )
                    }




                    <div id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} className='md:absolute  text-white md:h-screen  md:w-[72%] right-0 top-32   '>
                        <div className='mt-3 '>
                            <h1 className='text-2xl font-bold'>Connections:</h1>
                            <div className='mt-5 md:m-5 md:mr-16 border-2 border-gray-800 rounded-3xl py-5 pr-5  overflow-hidden overflow-y-scroll scrollbar bg-[#101010] h-[36rem]'>
                                <div className='md:flex'>
                                    <div className='md:w-[70%]'>
                                        <div className='flex flex-col md:flex-row gap-2 md:gap-5  pl-5 md:h-[2rem]'>
                                            <button
                                                className={`text-sm text-white rounded-full bg-[#00768B] px-7 py-1 ${selectedTimeRange === 'All' ? 'bg-[#00768B]' : 'bg-opacity-50'}`}
                                                onClick={() => handleTimeRangeClick1('All')}
                                            >
                                                All
                                            </button>
                                            <button
                                                className={`text-sm text-white rounded-full border border-[#00768B] px-7 py-1 ${selectedTimeRange === 'Last 24 Hrs' ? 'bg-[#00768B]' : 'bg-blue-87'}`}
                                                onClick={() => handleTimeRangeClick1('Last 24 Hrs')}
                                            >
                                                Last 24 Hrs
                                            </button>
                                            <button
                                                className={`text-sm text-white rounded-full border border-[#00768B] px-7 py-1 ${selectedTimeRange === 'Last Week' ? 'bg-[#00768B]' : 'bg-blue-87'}`}
                                                onClick={() => handleTimeRangeClick1('Last Week')}
                                            >
                                                Last Week
                                            </button>
                                            <button
                                                className={`text-sm text-white rounded-full border border-[#00768B] px-7 py-1 ${selectedTimeRange === 'Last Month' ? 'bg-[#00768B]' : 'bg-blue-87'}`}
                                                onClick={() => handleTimeRangeClick1('Last Month')}
                                            >
                                                Last Month
                                            </button>
                                        </div>
                                        <div className='flex justify-between p-5 bg-black rounded-r-2xl mt-5'>
                                            <div className='md:px-5'>
                                                <p className='text-2xl md:text-[40px] font-bold font-play text-center md:text-start'>{totalAppearanceCount}</p>
                                                <p className='text-[#F29D38] text-sm md:text-base text-center md:text-start'>Appearance</p>
                                            </div>
                                            <div className='h-20 w-1 blur-sm bg-gray-800 hidden md:block'></div>
                                            <div className='md:px-5'>
                                                <p className='text-2xl md:text-[40px] font-bold font-play text-center md:text-start'>{totalEmailCount}</p>
                                                <p className='text-[#F29D38] text-sm md:text-base text-center md:text-start'>Interest Received</p>
                                            </div>
                                            <div className='h-20 w-1 blur-sm bg-gray-800 hidden md:block'></div>
                                            {/* <div className='md:px-5'>
                                                        <p className='text-2xl md:text-[40px] font-bold font-play text-center md:text-start'>31</p>
                                                        <p className='text-[#F29D38] text-sm md:text-base text-center md:text-start'>Bookmarked</p>
                            </div> */}
                                        </div>
                                    </div>
                                    <div className='md:w-[30%] flex justify-center'>
                                        <div><img src='/assets/image 90.png' className='w-[11rem] h-[12.5rem]'></img></div>
                                    </div>
                                </div>
                                <div >
                                    <h1 className='flex justify-between pl-5'>
                                        <p className='font-bold'>Interest Received :</p>
                                        {/* <p>Sort by: <span className='text-[#0EA4A9]'>Recency</span></p> */}
                                    </h1>
                                    {interestData1.map((item, index) => (
                                        <div key={index} className='rounded-2xl p-2 bg-black mt-3 ml-5 '>
                                            <div className='flex flex-col gap-3 md:gap-0 md:flex-row py-5 overflow-hidden overflow-y-scroll scrollbar'>
                                                <div className='md:w-[35%]'>
                                                    <div className='flex '>
                                                        <div className='w-[20%] flex justify-center'>
                                                            <div>
                                                                <img src={item.receivedAvatar ? (item.receivedAvatar.startsWith('./') ? item.receivedAvatar.slice(1) : item.receivedAvatar) : ''} />
                                                            </div>
                                                        </div>
                                                        <div className='w-[80%]'>
                                                            <h1 className='text-lg'>{item.receivedName.slice(0, 7)}</h1>
                                                            <p className='text-gray-400'>{item.receivedContractType}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='md:w-[30%] flex items-center justify-center md:justify-start text-sm'><div className='flex-col flex gap-1'><p><i class="fa-regular fa-calendar mr-3"></i> {new Date(item.interestDateCreated).toLocaleString('en-US', {
                                                    timeZone: 'Asia/Kolkata',
                                                })} IST</p></div></div>

                                                <div className='md:w-[15%] flex items-center justify-center md:justify-start ml-auto'><button className='text-xs text-white rounded-xl bg-[#00768B] px-3 py-2' onClick={handleViewInterestReceivedProfile}>View Profile</button></div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <h1 className='flex justify-between pl-5 py-4'>
                                        <p className='font-bold'>My Watchlist :</p>
                                        {/* <p>Sort by: <span className='text-[#0EA4A9]'>Recency</span></p> */}
                                    </h1>
                                    {interestData.map((item, index) => (
                                        <div key={index} className='rounded-2xl p-2 bg-black mt-3 ml-5 overflow-hidden overflow-y-scroll'>
                                            <div className='flex flex-col gap-3 md:gap-0 md:flex-row py-5'>
                                                <div className='md:w-[35%]'>
                                                    <div className='flex '>
                                                        <div className='w-[20%] flex justify-center'>
                                                            <div>
                                                                <img src={item.avatar ? (item.avatar.startsWith('./') ? item.avatar.slice(1) : item.avatar) : ''} />
                                                            </div>
                                                        </div>

                                                        <div className='w-[80%]'>
                                                            <h1 className='text-lg'>{item.receivedName.slice(0, 7)}</h1>
                                                            <p className='text-gray-400'>{item.receivedContractType}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='md:w-[30%] flex items-center justify-center md:justify-start text-sm'><div className='flex-col flex gap-1'><p><i class="fa-regular fa-calendar mr-3"></i>   {format(new Date(item.interestDateCreated), "MMMM dd, yyyy hh:mm a")}
                                                </p></div></div>

                                                <div className='md:w-[15%] flex items-center justify-center md:justify-start ml-auto'><button className='text-xs text-white rounded-xl bg-[#00768B] px-3 py-2 ' onClick={() => handleViewMyInterestProfile(item.tokenId)}>View Profile</button></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div id='div4' style={{ display: active === 'div4' ? 'block' : 'none' }} className='md:absolute  text-white md:w-[72%] right-0 top-32 scrollbar '>
                        <div className='mt-3 h-[39rem]'>
                            <div className='flex gap-5 px-5 overflow-x-scroll overflow-hidden  h-[8rem]'>
                                {seekerDetails.map((seeker, index) => (
                                    <button className='flex-shrink-0 ' key={index} onClick={() => handleChatButtonClick(seeker)}>
                                        <div className='border-[4px] border-[rgb(14,164,169)] rounded-full p-1'>
                                            {/* <img src='/assets/image2.png' className='w-[4.5rem]' alt={`Seeker ${index}`} /> */}
                                            <img src={seeker.SeekerAvatar ? (seeker.SeekerAvatar.startsWith('./') ? seeker.SeekerAvatar.slice(1) : seeker.SeekerAvatar) : ''} className='w-[4.5rem]' alt={`Seeker ${index}`} />
                                        </div>
                                        <p className='text-center text-sm mt-2'>{(seeker.organizationName).slice(0, 7)}</p>
                                    </button>
                                ))}
                            </div>

                            <div className='mt-5 md:m-5 md:mr-16 border-2 border-gray-800 rounded-3xl p-3 md:p-5 bg-[#101010] h-[30.5rem] grid content-between'>
                                <div className='flex border-b border-gray-700 pb-5 '>
                                    <div className='w-[10%]'>
                                        <div className='px-[4px] '><div className='bg-white w-[14px] h-[14px] rounded-full absolute flex justify-center items-center'><div className='bg-green-600 w-3 h-3 rounded-full'></div></div><div>
                                            {/* <img src='/assets/image2.png' ></img> */}
                                            <img src={selectedSeekerName.RecruiterAvatar ? (selectedSeekerName.RecruiterAvatar.startsWith('./') ? selectedSeekerName.RecruiterAvatar.slice(1) : selectedSeekerName.RecruiterAvatar) : ''} />
                                        </div></div>
                                    </div>
                                    <div className='w-[60%] pl-5 flex items-center'>
                                        <div>
                                            <h1 className='text-xl font-bold'>{(selectedSeekerName.organizationName)?.slice(0, 7)}</h1>
                                            <p className='text-xs md:text-base md:flex'><p className='flex'> <div className='mx-2 w-2 h-2 rounded mt-2 bg-white'></div></p>
                                                <p> Last seen {getLastMessageTime()} </p></p>
                                        </div>
                                    </div>

                                    <div className='w-[5%] flex justify-center md:justify-start items-center ml-auto'>
                                        <button><i class="fa-solid fa-ellipsis-vertical text-[32px]"></i></button>
                                    </div>
                                </div>

                                <div className='h-[18rem] grid  overflow-hidden overflow-y-scroll scrollbar'>
                                    {displayMessages.map(message => (
                                        <div className={`flex justify-${message.sender === 'seeker' ? 'end' : 'start'}`} key={message.id}>
                                            <div> <div className={` px-5 py-2 rounded-t-xl ${message.sender === 'seeker' ? 'xl' : 'bl'} rounded-bl-xl ${message.sender === 'seeker' ? 'xl' : 'tr'} ${message.sender === 'seeker' ? 'bg-[#0EA4A9] text-white ' : ' bg-gray-200 text-black'}`}>
                                                {message.message_content}
                                            </div>
                                                <p className={`text-[10px] text-gray-300 ${message.sender === 'seeker' ? 'ml-2' : 'mr-2'}`}>
                                                    {convertDate(message.sent_at)} <i className="fa-solid fa-check-double text-lg"></i>
                                                </p></div>
                                        </div>
                                    ))}
                                </div>




                                <div className='flex'>
                                    <div className='w-[80%]'>
                                        <div className='flex bg-white/20 px-3 md:px-5 rounded-xl'>
                                            <i class="fa-solid fa-keyboard text-xl md:text-2xl mt-1 md:mt-3"></i>
                                            <input
                                                type='text'
                                                value={typedMessage}
                                                onChange={(e) => setTypedMessage(e.target.value)} // Update typedMessage state as user types
                                                className='w-full bg-transparent py-2 md:py-4 px-5 text-white outline-none'
                                                placeholder='Type your message here ...'
                                            />
                                            <i class="fa-regular fa-face-smile-beam text-xl md:text-2xl mt-1 md:mt-3"></i>
                                        </div>
                                    </div>

                                    <div className='w-[10%] flex justify-center items-center'>
                                        <i class="fa-solid fa-user-group  text-xl md:text-2xl"></i>
                                    </div>

                                    <div className='w-[10%] md:p-1'>
                                        <button className='bg-[#0EA4A9] w-full h-full text-xl md:text-2xl rounded-full md:rounded-lg' onClick={sendMessage} ><i class="fa-solid fa-paper-plane"></i></button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>




                    <div id='div5' style={{ display: active === 'div5' ? 'block' : 'none' }} className='md:absolute text-white  md:w-[72%] right-0 top-32 scrollbar '>
                        <div className='mt-3 '>
                            <h1 className='text-2xl font-bold'>Wallet:</h1>
                            <div className='mt-5 md:m-5 md:mr-16 border-2 border-gray-800 rounded-3xl p-5 bg-[#101010] '>
                                <div className='flex flex-col gap-5 md:gap-0 md:flex-row'>
                                    <div className='md:w-[55%]'>
                                        <h1 className='font-bold text-xl p-3 text-gray-300'>Recent Activities</h1>
                                        <div>
                                            {displayedTransactions.map((transaction, index) => (
                                                <div key={index} className='flex-col'>
                                                    <div className='flex py-5 px-3'>
                                                        <div className='w-[10%]'>
                                                            <div>
                                                                <img
                                                                    src={transaction.status === 'Credited' ? '/assets/Green.png' : '/assets/Red.png'}
                                                                    alt={transaction.status === 'Credited' ? 'Green' : 'Red'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='w-[70%] px-5'>
                                                            <div>
                                                                <p className='font-bold text-lg'>
                                                                    {transaction.status === 'Credited' ? 'Credited' : 'Debited'}
                                                                </p>
                                                                {transaction.created_at && (
                                                                    <p className='text-xs text-gray-500'>{formatTimestamp(transaction.created_at)}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='w-[20%]'>
                                                            <p>{transaction.amount} <span>Tokens</span></p>
                                                        </div>
                                                    </div>
                                                    {index < displayedTransactions.length - 1 && <div className='w-full h-[1px] bg-gray-800'></div>}
                                                </div>
                                            ))}
                                        </div>



                                        <div className='w-full h-[1px] bg-gray-800'></div>
                                        <div className='py-3 flex justify-between px-5'>
                                            <button className='px-5 rounded-full border border-[#0E2DA7]' onClick={handlePrevClick}>Prev</button>
                                            <button className='px-5 rounded-full border border-[#0E2DA7]' onClick={handleNextClick}>Next</button>
                                        </div>
                                    </div>
                                    <div className='md:w-[45%] grid gap-10 md:gap-0 md:content-around'>
                                        <div className='p-[1px] rounded-2xl bg-gradient-to-l from-[#0E2DA7] to-[#0EA4A9] mx-7'>
                                            <div className='flex bg-black rounded-2xl p-5'>
                                                <div className='w-[60%] flex items-center '>
                                                    <div className='pl-3 translate-y-5'>
                                                        <p className='text-xl font-bold'>{balance} Tokens</p>
                                                        <p>1 token = 1$</p>
                                                        <button className='bg-[#0893d1] px-3.5 py-2 rounded-full translate-y-6 md:translate-y-6' onClick={handlePaymentPopUp}>
                                                            <i className='fa-solid fa-plus text-xl'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='w-[40%] flex items-center '>
                                                    <div><img className='' src='/assets/d5art_white.png' alt='Logo' /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>

                                            <div className='text-black'>
                                                {dataLoaded && (
                                                    <ApexChart
                                                        creditData={creditData}
                                                        debitData={debitData}
                                                        months={months}
                                                    />
                                                )}
                                                {!dataLoaded && <p className='text-white' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                {isOpenPayment && (
                                    <div className='px-5 py-10 md:px-10 md:py-0 z-20 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 backdrop-blur-md overflow-y-auto'>
                                        {paymentLoader ? (
                                            <div className="flex justify-center items-center w-full h-full">
                                                <div className="loader"></div>
                                            </div>
                                        ) : (
                                            <div className='w-full flex justify-center gap-2'>
                                                <div className='w-[100%] md:w-[90%] h-full text-white border border-sky-700 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10'>
                                                    <div className='flex justify-end'>
                                                        <button
                                                            className='shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2'
                                                            onClick={handlePaymentPopUp}>
                                                            <i className="fa-solid fa-xmark"></i>
                                                        </button>
                                                    </div>
                                                    <h1 className='font-bold text-center text-xl md:text-3xl'>Subscription Plans</h1>
                                                    <div className='flex md:justify-end w-[100%] md:w-[90%] md:mx-auto mt-3'>
                                                        <div className='w-[100%] md:w-[40%] flex flex-col md:flex-row'>
                                                            <label className='md:mt-1.5 w-[80%] mx-auto md:mx-0 md:w-[40%]'>Select Currency: </label>
                                                            <Select
                                                                className='text-black w-[80%] mx-auto md:mx-0 md:w-[60%]'
                                                                value={selectedCurrency ? { value: selectedCurrency, label: CurrencyList.find(currency => currency.code === selectedCurrency).name } : null}
                                                                onChange={handleCurrencyChange}
                                                                options={CurrencyList.map((currency) => ({
                                                                    value: currency.code,
                                                                    label: currency.name,
                                                                }))}
                                                                styles={customStyles}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='flex flex-col md:flex-row justify-center 2xl:gap-20 gap-10 py-5'>


                                                        {plans.map(plan => (
                                                            <div key={plan.name} className="flip-card2">
                                                                <div className="flip-card-inner2">
                                                                    <div className="flip-card-front2 bg-white/10 rounded-2xl grid content-center border border-sky-700">
                                                                        <div className='flex justify-center'>
                                                                            <img src="/assets/Recuiterimg.png" alt={plan.imgAlt} />
                                                                        </div>
                                                                        <p className='mt-2 text-xl font-bold text-[#CD7F32]' style={{ color: plan.textColor }}>{plan.name}</p>
                                                                        <p className='text-start px-5 flex justify-around'>Purchase : <span className='font-bold text-lg'>{currencySymbol} {plan.price}</span></p>
                                                                    </div>
                                                                    <div className="flip-card-back2 p-5 border border-yellow-500 grid content-between gap-3 rounded-2xl">
                                                                        <div>
                                                                            <p className='text-lg font-bold'>{plan.name}</p>
                                                                            <ul className="list-inside list-disc text-gray-200 text-start text-sm mt-3">
                                                                                <p>This plan will reward you with a fantastic {plan.amount} D5ART tokens! Get ready to enjoy the amazing benefits coming your way!</p>
                                                                            </ul>
                                                                        </div>
                                                                        <div className='flex justify-center'>
                                                                            <button className='flex pt-2 pb-1 px-3 bg-black rounded-full group border border-sky-700 text-white' onClick={() => { handlePayment(plan.amount); }}>
                                                                                <button className='p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[64px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                                                <p className='mx-2 -translate-y-0.5 group-hover:-translate-x-5 transform-transition duration-500 ease-in-out'>Buy</p>
                                                                                <button className='p-2.5 rounded-full -translate-y-0.5 border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}


                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}


                                {isOpenBuy1 && (
                                    <div className='px-10 rounded-2xl z-10 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-lg '>
                                        <div className=' w-[100%] flex justify-center gap-2'>
                                            <div className='w-[50%] text-white border border-sky-700 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10 animate__animated animate__fadeInUp'>
                                                <div className='flex justify-end '>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform duration-500 ease-in-out hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' ><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                                <div className='flex '>
                                                    <div className='flex justify-center w-[30%]'><img src="/assets/image 22.png" className='w-[10rem]' />
                                                    </div>
                                                    <div className='w-[70%] pr-5'><p className='mt-2 text-center text-lg'>Purchased</p>
                                                        <p className="text-sm text-gray-300 text-center mt-5"> Token Purchased Successfully</p>
                                                    </div>
                                                </div>
                                                <div className='flex justify-center mt-5'>
                                                    <button className='flex pt-2 pb-1 px-3 bg-black rounded-full group border border-sky-700 text-white' onClick={() => { handleBtnpop(); handlePaymentCompletedButton(); }} >
                                                        <button className='p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[108px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button> <p className='mx-2 -translate-y-0.5 group-hover:-translate-x-5 transform-transition duration-500 ease-in-out text-sm'>Purchased</p> <button className='p-2.5 rounded-full -translate-y-0.5  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                    </button>
                                                    <Confetti active={Btn} config={confettiConfig} />


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {isOpenBuy2 && (
                                    <div className='px-2 md:px-10 rounded-2xl z-10 lg:w-full modal-overlay fixed inset-0 flex flex-col flex-row items-center justify-center bg-black bg-opacity-10 backdrop-blur-lg '>
                                        <div className=' w-[100%] flex justify-center gap-2'>
                                            <div className='md:w-[40%] text-white border border-sky-700 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10 animate__animated animate__fadeInUp'>
                                                <div className='flex justify-end '>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform duration-500 ease-in-out hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' ><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                                <div className='flex '>
                                                    <div className='flex justify-center items-center w-[40%]'><img src="/assets/image 22.png" className='' />
                                                    </div>
                                                    <div className='w-[60%] pr-5'><p className='mt-2 text-center text-lg'>Purchased</p>
                                                        <p className="text-sm text-gray-300 text-center mt-5">D5art Token Purchased Successfully</p>
                                                        <div className='flex justify-center mt-5'>
                                                            <button className='flex pt-2 pb-1 px-3 bg-black rounded-full group border border-sky-700 text-white' onClick={() => { handleBtnpop(); handlePaymentCompletedButton(); }}>
                                                                <button className='p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[108px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button> <p className='mx-2 -translate-y-0.5 group-hover:-translate-x-5 transform-transition duration-500 ease-in-out text-sm'>Purchased</p> <button className='p-2.5 rounded-full -translate-y-0.5  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                            </button>
                                                            <Confetti active={Btn} config={confettiConfig} />

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )}
                                {isOpenBuy3 && (
                                    <div className='px-10 rounded-2xl z-10 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-lg '>
                                        <div className=' w-[100%] flex justify-center gap-2'>
                                            <div className='w-[50%] text-white border border-sky-700 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10 animate__animated animate__fadeInUp'>
                                                <div className='flex justify-end '>
                                                    <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform duration-500 ease-in-out hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' ><i class="fa-solid fa-xmark " ></i></button>
                                                </div>
                                                <div className='flex '>
                                                    <div className='flex justify-center w-[30%]'><img src="/assets/image 22.png" className='w-[10rem]' />
                                                    </div>
                                                    <div className='w-[70%] pr-5'><p className='mt-2 text-center text-lg'>Purchased</p>
                                                        <p className="text-sm text-gray-300 text-center mt-5"> Token Purchased Successfully</p>
                                                    </div>
                                                </div>
                                                <div className='flex justify-center mt-5'>
                                                    <button className='flex pt-2 pb-1 px-3 bg-black rounded-full group border border-sky-700 text-white' onClick={() => { handleBtnpop(); handlePaymentCompletedButton(); }}>
                                                        <button className='p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[108px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button> <p className='mx-2 -translate-y-0.5 group-hover:-translate-x-5 transform-transition duration-500 ease-in-out text-sm'>Purchased</p> <button className='p-2.5 rounded-full -translate-y-0.5  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                    </button>
                                                    <Confetti active={Btn} config={confettiConfig} />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>


                    <div id='div6' style={{ display: active === 'div6' ? 'block' : 'none' }} className='md:absolute text-white md:w-[72%] right-0 top-32 scrollbar'>
                        <div className=' '>
                            <div className='mt-5 md:mt-2 md:m-5 md:mr-16 border-2 border-gray-800 rounded-3xl p-5 bg-[#101010] h-[39rem]  overflow-hidden overflow-y-scroll'>
                                <h1 className='text-center font-black mt-2 text-xl text-[#F29D38]'>Raise New Query</h1>
                                <div className='md:flex py-10'>
                                    <div className='md:w-[50%] md:pl-5 flex flex-col gap-5'>

                                        <input
                                            type='text'
                                            name='subject'
                                            placeholder='Subject'
                                            value={formData.subject}
                                            onChange={handleChange1}
                                            className='rounded-2xl px-5 py-2.5 bg-black border border-gray-700 outline-none'
                                        />
                                        <select
                                            name='category'
                                            value={formData.category}
                                            onChange={handleChange1}
                                            className='bg-black px-5 py-2.5 text-gray-400 outline-none rounded-2xl border border-gray-700'
                                        >
                                            <option value="Job Apply Issue">Job Apply Issue</option>
                                            <option value="Suggestion">Suggestion</option>
                                            <option value="Wallet Issue">Wallet Issue</option>
                                            <option value="others">Others</option>
                                        </select>
                                    </div>
                                    <div className='md:w-[50%] md:pl-10 md:pr-5 mt-5 md:mt-0'>
                                        <div className='border border-gray-700 px-3 py-1 rounded-2xl h-full'>
                                            <textarea
                                                name='description'
                                                placeholder='Explain your query in detail..'
                                                type="text"
                                                value={formData.description}
                                                onChange={handleChange1}
                                                className='outline-none h-[70%] w-full bg-transparent placeholder:text-sm'
                                            ></textarea>
                                            <div className='flex justify-end h-[30%]'>
                                                <div>
                                                    <button type='submit' onClick={handleSubmit} className='px-5 py-1 bg-[#0087A57D] rounded-lg text-xs'>
                                                        Raise a Ticket
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h1 className='flex justify-between px-5'>
                                    <h1 className='font-bold'>Previous Query</h1>
                                    <p className='flex gap-5 text-xs font-bold mt-1'>
                                        <p>Open: <span className='text-[#0EA4A9]'>{openCount}</span></p>
                                        <p>Resolved: <span className='text-[#0EA4A9]'>{resolvedCount}</span></p>
                                    </p>
                                </h1>

                                <div className='w-full  rounded-2xl p-5 '>
                                    <div className='flex justify-end'>
                                        <p className='text-end text-sm px-3 mb-2'>Show no of entity
                                            <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                                <option className='text-black' value="5">5</option>
                                                <option className='text-black' value="10">10</option>
                                                <option className='text-black' value="15">15</option>
                                            </select>
                                        </p>
                                    </div>
                                    <div className='mb-5 rounded-xl overflow-hidden overflow-x-auto'>
                                        <table className='w-full text-center '>
                                            <thead>
                                                <tr>
                                                    <th className='py-2  px-3 '>S.No</th>
                                                    <th className=''>Subject</th>
                                                    <th className=''>Date & time</th>
                                                    <th className=''>Token Id</th>
                                                    <th className='py-2 '>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentData.map((issue, index) => (
                                                    <tr key={index} className='text-sm bg-black'>
                                                        <td className='py-3 text-center'>{indexOfFirstItem + index + 1}.</td>
                                                        <td>{issue.subject}</td>
                                                        <td>{new Date(issue.date_created).toLocaleString()}</td>
                                                        <td># {issue.token_id}</td>
                                                        <td className={`text-${issue.status === 'Open' ? 'yellow-500' : 'green-500'}`}>
                                                            {issue.status}
                                                        </td>
                                                    </tr>
                                                ))}
                                                {/* Add empty rows if needed */}
                                                {Array.from({ length: emptyRowCount }).map((_, index) => (
                                                    <tr key={`empty-${index}`} className='h-16'>
                                                        <td colSpan="5">&nbsp;</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='mt-2 flex justify-between text-xs px-3'>
                                        <button className='bg-white/30 rounded-md px-5 py-1' onClick={handlePrevClickTicket} disabled={currentPage === 1}>Prev</button>
                                        <button className='bg-white/30 rounded-md px-5 py-1' onClick={handleNextClickTicket} disabled={currentData.length < entitiesPerPage}>Next</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div id='div7' style={{ display: active === 'div7' ? 'block' : 'none' }} className='md:absolute  text-white md:w-[72%] right-0 top-32 scrollbar '>


                        <div className='mt-3 md:mt-14 md:h-[36rem] 2xl:h-[36rem]'>
                            <h1 className='text-2xl font-bold'>My Assessments :</h1>
                            <div className='mt-5 md:m-5 md:mr-16 border-2 border-gray-800 rounded-3xl p-5 bg-[#101010] h-[36rem] overflow-hidden overflow-y-scroll'>
                                <h2 className='font-bold text-lg'>Filter <i class="fa-solid fa-filter text-sm text-gray-400 ml-2"></i></h2>
                                <div className='flex gap-5 mt-5'>
                                    <button className={`text-sm text-white rounded-full border border-[#00768B] ${(!displayPendingResults && !displayValidatedResults) ? 'bg-[#00768B]' : 'bg-opacity-50'} px-2 md:w-[12.5%] py-1`} onClick={() => { setDisplayPendingResults(false); setDisplayValidatedResults(false); sorting_validated_result_status_validator(0); }}>All</button>
                                    <button className={`text-sm text-white rounded-full border border-[#00768B] ${displayPendingResults ? 'bg-[#00768B]' : 'bg-opacity-50'} px-2 md:w-[12.5%] py-1`} onClick={() => { setDisplayPendingResults(true); setDisplayValidatedResults(false); sorting_validated_result_status_validator(1); }}>Pending</button>
                                    <button className={`text-sm text-white rounded-full border border-[#00768B] ${displayValidatedResults ? 'bg-[#00768B]' : 'bg-opacity-50'} px-2 md:w-[12.5%] py-1`} onClick={() => { setDisplayPendingResults(false); setDisplayValidatedResults(true); sorting_validated_result_status_validator(2); }}>Validated</button>
                                </div>
                                <div className='w-[100%] mt-5 '>
                                    <div className='w-full hidden md:block '>
                                        <div className='w-full flex text-center'>
                                            <div className='w-[40%]'><p className=''>Organization</p></div>
                                            <div className='w-[30%]'><p className=''>Assessment on</p></div>
                                            <div className='w-[15%]'><p className=''>Validation</p></div>
                                            <div className='w-[15%]'><p className=''>Result</p></div>
                                        </div>
                                    </div>

                                    {/* Render validated data */}
                                    {displayPendingResults && pendingData.map((data, index) => (
                                        <div key={index} className='rounded-2xl p-2 shadow-sm shadow-sky-300 flex flex-col md:flex-row gap-2 md:gap-0 mt-3 border border-sky-900'>
                                            <div className='md:w-[40%]'>
                                                <div className='flex '>
                                                    <div className='w-[20%] flex justify-center '>
                                                        <div className='flex items-center'><img src='/assets/D5ART_Fav icon.png' alt='logo'></img></div>
                                                    </div>
                                                    <div className='w-[80%]'>
                                                        <h1 className='text-lg'>{data.Set_name}</h1>
                                                        <p className='text-gray-400 text-sm'>Skill Level: {data.skill_level ? data.skill_level : '--NA--'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='md:w-[30%] flex items-center justify-center'><p>{new Date(data.submited_at).toLocaleString()}</p></div>
                                            <div className='text-[#FFBA00] md:w-[15%] flex items-center justify-center'><p>Pending</p></div>
                                            <div className='md:w-[15%] flex items-center justify-center'>
                                                <p className={`rounded-full px-3 py-1 ${data.status === 'Pending' ? 'text-[#FFBA00]' : 'text-[#0EA4A9]'}`}>
                                                    {data.result ? data.result : '--NA--'}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                    {/* Render pending data */}
                                    {displayValidatedResults && validatorValidatedData.map((data, index) => (
                                        <div key={index} className='rounded-2xl p-2 shadow-sm shadow-sky-300 flex flex-col md:flex-row gap-2 md:gap-0 mt-3 border border-sky-900'>
                                            <div className='md:w-[40%]'>
                                                <div className='flex '>
                                                    <div className='w-[20%] flex justify-center'>
                                                        <div className='flex items-center' ><img src='/assets/D5ART_Fav icon.png' alt='logo'></img></div>
                                                    </div>
                                                    <div className='w-[80%]'>
                                                        <h1 className='text-lg'>{data.Set_name}</h1>
                                                        <p className='text-gray-400 text-sm'>Skill Level: {data.skill_level ? data.skill_level : '--NA--'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='md:w-[30%] flex items-center justify-center'><p>{new Date(data.submited_at).toLocaleString()}</p></div>
                                            <div className='text-[#0EA4A9] md:w-[15%] flex items-center justify-center'><p>Validated</p></div>
                                            <div className='md:w-[15%] flex items-center justify-center'><p className=' text-[#FFBA00] rounded-full px-3 py-1'>{data.result ? data.result : '--NA--'}</p></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>




                    </div>
                </div >

            </div >
            );
            <Footer />
        </div >
    )

}