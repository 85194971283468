import React, { useEffect, useState } from 'react'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section5 from './Section5'
import Section4 from './Section4'
import Header from '../../../Header'
import { SERVER_URL } from '../../../Config'
import { useSelector } from 'react-redux'

export default function Result() {
     const email = useSelector((state) => state.seeker_email);
    

    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(`${SERVER_URL}/api/jordan_results/${email}`)
            .then((res) => res.json())
            .then((data) => setData(data))
            .catch((err) => console.error(err));
    }, [email]);

    console.log("data", data)

    if (!data) return <div><div className="flex justify-center items-center h-48">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-orange-600"></div>
    </div></div>;

    const { percentages, personalityData, highestPercentage, userResult } = data;


    return (
        <div className='w-full bg-[#1A1919] bg-Fabcbg bg-cover min-h-screen text-white'>
            <Header />
            <div className='pt-5 pb-10 grid gap-20 w-[90%] lg:w-[85%] mx-auto'>
                {/*Artistic*/}
                <Section1 highestPercentage={highestPercentage} personalityData={personalityData} personalityType={userResult?.personality_type} />
                {/*Detailed Summary*/}
                <Section2 percentages={percentages} />
                {/*Characteristics*/}
                <Section3 keyCharacteristics={personalityData?.keyCharacteristics} />
                <Section4 recommendedCareers={personalityData?.recommendedCareers} personalityType={userResult?.personality_type} />
                {/*Comingsoon*/}
                <Section5 />
            </div>
        </div>
    )
}
