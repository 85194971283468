import React from 'react'

export default function Section5() {
    return (
        <div className='section-5 relative'>
            <h1 className="text-3xl font-semibold">Recommended Jobs</h1>
            <div className='flex items-center justify-center'>
                <img src='/assets/Mascots/useravatar.svg' data-aos='fade-in'></img>
                <div className='absolute w-[90%] lg:w-[60%] bg-white/5 backdrop-blur-xl rounded-xl h-[15vh] lg:h-[30vh] grid place-content-center' data-aos='fade-up' data-aos-duration='1500'>
                    <h1 className='bg-clip-text text-transparent bg-gradient-to-r from-[#0EA4A9] to-[#B4BDFF] font-semibold text-xl lg:text-4xl font-Pixelify '>Coming Soon</h1>
                </div>
            </div>
        </div>
    )
}
