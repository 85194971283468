// import React from 'react'

// export default function Section4() {
//     const data = [
//         { label: "Creative arts", color: "from-[#F29D38] to-[#F29D38]" },
//         { label: "Performing arts", color: "from-[#F29D38] to-[#F29D38]" },
//         { label: "Writing and media", color: "from-[#F29D38] to-[#1CD9FF]" },
//         { label: "Design and innovation", color: "from-[#1CD9FF] to-[#1CD9FF]" },
//         { label: "Education and Community", color: "from-[#1CD9FF] to-[#1CD9FF]" },
//     ];

//     return (
//         <div className="section-4 relative">
//             <h1 className="text-3xl font-semibold">Recommended Career Path</h1>
//             <img src='/assets/C3.png' className='absolute top-10 lg:top-5 right-5 lg:right-[50%] w-8 h-8 fa-bounce'></img>
//             <img src='/assets/C1.png' className='absolute bottom-0 right-5 w-8 h-8 fa-bounce'></img>
//             <p className='my-7 leading-9'>Characteristics are distinguishing traits, qualities, or features that define an individual, object, or concept. They shape identity, behavior, and perception, often influencing functionality, interactions, and value in diverse contexts.</p>
//             <div className="relative flex justify-evenly items-start">
//                 <div className="absolute top-0 w-full h-[2px] bg-gradient-to-r from-[#F29D38] to-[#1CD9FF]"></div>
//                 {data.map((item, index) => (
//                     <div key={index} className="flex flex-col items-center">
//                         <div className={`w-1 ${index % 2 === 0 ? 'h-20' : 'h-40'} bg-gradient-to-r ${item.color}`}></div>
//                         <div className="w-4 h-4 bg-[#D9D9D9] rounded-full"></div>
//                         <p className="mt-2 text-center text-sm text-wrap">{item.label}</p>
//                     </div>
//                 ))}
//             </div>
//             <div className='bg-white/5 rounded-xl p-5 mt-12 lg:h-[30vh] flex flex-col lg:flex-row items-center justify-center gap-5 lg:gap-40'>
//                 <p>For the detailed summary kindly download the report</p>
//                 <button className='py-2 px-7 bg-[#D9D9D95C] rounded-full font-medium active:translate-y-1 duration-300'>View Full Report</button>
//             </div>
//         </div>
//     );
// }





import React from 'react';


export default function Section4({ recommendedCareers ,personalityType}) {


    

    const downloadPdf = async() => {
        const pdfFileName = `${personalityType.toLowerCase()}.pdf`; 
        const pdfUrl = `/assets/ResultPDF/${pdfFileName}`;
        console.log("pdfUrl",pdfUrl)
        const link =await document.createElement('a');
        link.href = pdfUrl;
        link.download =await pdfFileName; 
       await link.click(); 
    };

    // const downloadPdf = () => {
    //     const pdfFileName = `${personalityType.toLowerCase()}.pdf`; // e.g., artistic.pdf
    //     const pdfUrl = `/assets/ResultPDF/${pdfFileName}`; // Adjust the path if PDFs are stored elsewhere
    //     window.location.href = pdfUrl; // Triggers the file download
    // };



    const colors = [
        "from-[#F29D38] to-[#F29D38]",
        "from-[#F29D38] to-[#F29D38]",
        "from-[#F29D38] to-[#1CD9FF]",
        "from-[#1CD9FF] to-[#1CD9FF]",
        "from-[#1CD9FF] to-[#1CD9FF]",
        "from-[#1CD9FF] to-[#F29D38]"
    ];

    return (
        <div className="section-4 relative w-full overflow-hidden overflow-x-auto">
            <h1 className="text-3xl font-semibold">Recommended Career Path</h1>
            <img src='/assets/C3.png' className='absolute top-10 lg:top-5 right-5 lg:right-[50%] w-8 h-8 fa-bounce' alt="Decoration" />
            <img src='/assets/C1.png' className='absolute bottom-0 right-5 w-8 h-8 fa-bounce' alt="Decoration" />
            <p className='my-7 leading-9'>
                Characteristics are distinguishing traits, qualities, or features that define an individual, object, or concept. 
                They shape identity, behavior, and perception, often influencing functionality, interactions, and value in diverse contexts.
            </p>
            <div className="w-[100%] overflow-hidden overflow-x-auto relative flex justify-between items-start">
                <div className="absolute top-0 sm:w-full w-[550px] h-[2px] bg-gradient-to-r from-[#F29D38] to-[#1CD9FF]"></div>
                {recommendedCareers.map((career, index) => (
                    <div key={index} className="flex flex-col items-center">
                        <div className={`w-1 ${index % 2 === 0 ? 'h-20' : 'h-40'} bg-gradient-to-r ${colors[index % colors.length]}`}></div>
                        <div className="w-4 h-4 bg-[#D9D9D9] rounded-full"></div>
                        <p className="mt-2 text-center text-sm text-wrap">{career}</p>
                    </div>
                ))}
            </div>
            <div className='bg-white/5 rounded-xl p-5 mt-12 lg:h-[30vh] flex flex-col lg:flex-row items-center justify-center gap-5 lg:gap-40'>
                <p>For the detailed summary kindly download the report</p>
                <button className='py-2 px-7 bg-[#D9D9D95C] rounded-full font-medium active:translate-y-1 duration-300' onClick={downloadPdf}>View Full Report</button>
            </div>
        </div>
    );
}
