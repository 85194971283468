import React, { useState, useEffect } from 'react'
import Header from './LoginHeader';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Select from 'react-select';
import axios from 'axios';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import { toast, Toaster } from 'react-hot-toast';


export default function EditDetails() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [seekerDetails, setSeekerDetails] = useState(null);

    const location = useLocation();
    const email = new URLSearchParams(location.search).get('email');
    //const email = "sektrail@gmail.com"
    ////console.log("email", email)

    useEffect(() => {
        const fetchSeekerDetails = async () => {
            try {
                //console.log('Fetching seeker details...');
                const response = await axios.post(`https://ex.d5art.com/api/get_seekers_details`, { email });
                //console.log("response", response.data)
                if (response.data) {
                    const data = response.data;
                    //console.log("seeker data", data)
                    setSeekerDetails(data);
                } else {
                    console.error('Failed to fetch seeker details');
                }
            } catch (error) {
                console.error('Error fetching seeker details:', error);
            }
        };

        if (email) {
            fetchSeekerDetails();
        }
    }, [email]);

    useEffect(() => {
        //console.log("seekerDetails updated:", seekerDetails);
    }, [seekerDetails]);

    //console.log("seeker data111", seekerDetails);


    const [isEditing, setIsEditing] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const [isEditing3, setIsEditing3] = useState(false);
    const [isEditing4, setIsEditing4] = useState(false);
    const [isEditing5, setIsEditing5] = useState(false);


    const [editedName, setEditedName] = useState(seekerDetails ? seekerDetails.name : "");
    const [editedRole, setEditedRole] = useState(seekerDetails ? seekerDetails.jobrole : "");
    const [editedSummary, setEditedSummary] = useState(seekerDetails ? seekerDetails.description : "");

    const handleEditClick = () => {
        setEditedName(seekerDetails ? seekerDetails.name : "");
        setEditedRole(seekerDetails ? seekerDetails.jobrole : "");
        setEditedSummary(seekerDetails ? seekerDetails.description : "");
        setIsEditing(true);
    };

    const [editedSummary2, setEditedSummary2] = useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eius mod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliqui pex ea commodo consequat.");
    const [editableSkillName, setEditableSkillName] = useState('');
    const [editableSkillPercentage, setEditableSkillPercentage] = useState(0);

    // const [editedSSLCName, setEditedSSLCName] = useState("Petit");
    // const [editedSSLCYear, setEditedSSLCYear] = useState("2012");
    // const [editedSSLCPercentage, setEditedSSLCPercentage] = useState("89%");
    // const [editedSSLCSummary, setEditedSSLCSummary] = useState("Summary in SSLC");

    // const [editedHSCName, setEditedHSCName] = useState("St.Marys");
    // const [editedHSCYear, setEditedHSCYear] = useState("2014");
    // const [editedHSCPercentage, setEditedHSCPercentage] = useState("79%");
    // const [editedHSCSummary, setEditedHSCSummary] = useState("Summary in HSC");

    // const [editedUGName, setEditedUGName] = useState("SMVEC");
    // const [editedUGYear, setEditedUGYear] = useState("2018");
    // const [editedUGPercentage, setEditedUGPercentage] = useState("85%");
    // const [editedUGSummary, setEditedUGSummary] = useState("Summary in UG");

    const [editedSSLCName, setEditedSSLCName] = useState(seekerDetails ? seekerDetails.sslc_institution : "");
    const [editedSSLCYear, setEditedSSLCYear] = useState(seekerDetails ? seekerDetails.sslc_year : "");
    const [editedSSLCPercentage, setEditedSSLCPercentage] = useState(seekerDetails ? seekerDetails.sslc_percentage : "");
    const [editedSSLCSummary, setEditedSSLCSummary] = useState(seekerDetails ? seekerDetails.sslcDescription : "");

    const [editedHSCName, setEditedHSCName] = useState(seekerDetails ? seekerDetails.hsc_institution : "");
    const [editedHSCYear, setEditedHSCYear] = useState(seekerDetails ? seekerDetails.hsc_year : "");
    const [editedHSCPercentage, setEditedHSCPercentage] = useState(seekerDetails ? seekerDetails.hsc_percentage : "");
    const [editedHSCSummary, setEditedHSCSummary] = useState(seekerDetails ? seekerDetails.hscDescription : "");

    const [editedUGName, setEditedUGName] = useState(seekerDetails ? seekerDetails.cgpa_institution : "");
    const [editedUGYear, setEditedUGYear] = useState(seekerDetails ? seekerDetails.cgpa_year : "");
    const [editedUGPercentage, setEditedUGPercentage] = useState(seekerDetails ? seekerDetails.cgpa_percentage : "");
    const [editedUGSummary, setEditedUGSummary] = useState(seekerDetails ? seekerDetails.cgpaDescription : "");

    const [sslcFile, setSSLCFile] = useState(null);
    const [hscFile, setHSCFile] = useState(null);
    const [cgpaFile, setUGFile] = useState(null);

    const [editedPGName, setEditedPGName] = useState("Loyola");
    const [editedPGYear, setEditedPGYear] = useState("2020");
    const [editedPGPercentage, setEditedPGPercentage] = useState("83%");
    const [editedPGSummary, setEditedPGSummary] = useState("Summary in PG");



    const [skills, setSkills] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState(null);

    const [resumeFile, setResumeFile] = useState(null);

    // useEffect(() => {
    //     if (seekerDetails && seekerDetails.proficiency) {
    //         const proficiencyArray = JSON.parse(seekerDetails.proficiency);
    //         setSkills(proficiencyArray);
    //     }
    // }, [seekerDetails]);

    // useEffect(() => {
    //     if (seekerDetails && seekerDetails.proficiency) {
    //         try {
    //             const proficiencyArray = JSON.parse(seekerDetails.proficiency);
    //             setSkills(proficiencyArray);
    //         } catch (error) {
    //             console.error('Error parsing proficiency array:', error);
    //         }
    //     }
    // }, [seekerDetails]);

    // //console.log("skills", skills);


    const handleEditClick2 = () => {
        setResumeFile(null); // Reset the file state

        if (seekerDetails && seekerDetails.proficiency) {
            try {
                const proficiencyArray = JSON.parse(seekerDetails.proficiency);
                setSkills(proficiencyArray.map(skill => ({
                    skill: skill.skill,
                    percentage: skill.percentage
                })));
            } catch (error) {
                console.error('Error parsing proficiency array:', error);
                setSkills([]);
            }
        } else {
            setSkills([]);
        }


        setIsEditing2(true);

    };


    //console.log("skills", skills)
    const handleEditClick3 = () => {
        setIsEditing3(true);
    };
    const handleEditClick4 = () => {
        setEditedSSLCName(seekerDetails ? seekerDetails.sslc_institution : "");
        setEditedSSLCYear(seekerDetails ? seekerDetails.sslc_year : "");
        setEditedSSLCPercentage(seekerDetails ? seekerDetails.sslc_percentage : "");
        setEditedSSLCSummary(seekerDetails ? seekerDetails.sslcDescription : "");
        setSSLCFile(null); // Reset the file state

        setEditedHSCName(seekerDetails ? seekerDetails.hsc_institution : "");
        setEditedHSCYear(seekerDetails ? seekerDetails.hsc_year : "");
        setEditedHSCPercentage(seekerDetails ? seekerDetails.hsc_percentage : "");
        setEditedHSCSummary(seekerDetails ? seekerDetails.hscDescription : "");
        setHSCFile(null); // Reset the file state

        setEditedUGName(seekerDetails ? seekerDetails.cgpa_institution : "");
        setEditedUGYear(seekerDetails ? seekerDetails.cgpa_year : "");
        setEditedUGPercentage(seekerDetails ? seekerDetails.cgpa_percentage : "");
        setEditedUGSummary(seekerDetails ? seekerDetails.cgpaDescription : "");
        setUGFile(null); // Reset the file state

        setIsEditing4(true);
    };

    const handleEditClick5 = () => {
        setIsEditing5(true);
    };

    // const handleSaveClick = () => {
    //     setIsEditing(false);                        
    // };

    const handleSaveClick = async () => {
        try {
            const response = await axios.post('https://ex.d5art.com/api/updatePersonalInfo', {
                name: editedName,
                email: seekerDetails.email,
                jobrole: editedRole,
                description: editedSummary
            });
            //console.log('Personal info updated successfully:', response.data);
            setSeekerDetails(prevState => ({
                ...prevState,
                name: editedName,
                jobrole: editedRole,
                description: editedSummary
            }));
            setIsEditing(false);

            if (response) {
                //console.log('Personal info updated successfully:', response.data);
                
                toast.success('Personal info updated successfully');
                window.location.reload();
            } else {

                console.error('Personal info updated successfully');
            }
        } catch (error) {
            console.error('Error updating personal info:', error);
        }
    };

    // const handleSaveClick2 = () => {
    // setIsEditing2(false);
    // };


    const handleSaveClick2 = async () => {

        try {
            const formData = new FormData();
            formData.append('email', seekerDetails.email);
            formData.append('proficiency', JSON.stringify(skills));
            formData.append('resumeFile', resumeFile);

            const response = await axios.post('https://ex.d5art.com/api/updateSkillsInfo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            //console.log("response", response.data);
            if (response) {
                //console.log('Certificates info updated successfully:', response.data);
                
                toast.success('Certificates info updated successfully');
                window.location.reload();
            } else {

                console.error('Certificates info update error');
            }
        } catch (error) {
            console.error('Error updating skills info:', error);
            setSkills(prevSkills => prevSkills);
        }

        try {
            const formData = new FormData();
            formData.append('email', seekerDetails.email);
            formData.append('certificates', JSON.stringify(certificates));

            certificates.forEach((certificate, index) => {
                if (certificate.file instanceof File) { // Check if file is a File object
                    formData.append(`certificate${index + 1}File`, certificate.file);
                }
            });

            const response = await axios.post('https://ex.d5art.com/api/updateCertInfo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            //console.log('Certificates info updated successfully:', response.data);
            if (response) {
                //console.log('Certificates info updated successfully:', response.data);
                
                toast.success('Certificates info updated successfully');
                window.location.reload();
            } else {

                console.error('error in Certificates info update ');
            }
        } catch (error) {
            console.error('Error updating Certificates info:', error);
        }

        setIsEditing2(false);


    };


    const handleSaveClick3 = async () => {
        try {
            const response = await axios.post('https://ex.d5art.com/api/updateExpInfo', {
                email: seekerDetails.email,
                experiences: JSON.stringify(experienceList),
            });
            //console.log('experiences info updated successfully:', response.data);
            if (response) {
                //console.log('experiences info updated successfully:', response.data);
                
                toast.success('experiences info updated successfully');
                window.location.reload();
            } else {

                console.error('error in experiences info ');
            }
        } catch (error) {
            console.error('Error updating experiences info:', error);
        }
        setIsEditing3(false);

    };



    // const handleSaveClick4 = () => {
    // setIsEditing4(false);
    // };

    // const handleSaveClick4 = async () => {
    //     try {
    //         const response = await axios.post("https://ex.d5art.com/api/updateEducationInfo", {
    //             email: seekerDetails.email,
    //             sslcPercentage: editedSSLCPercentage,
    //             sslcInstitution: editedSSLCName,
    //             sslcYear: editedSSLCYear,
    //             sslcDescription: editedSSLCSummary,
    //             hscPercentage: editedHSCPercentage,
    //             hscInstitution: editedHSCName,
    //             hscYear: editedHSCYear,
    //             hscDescription: editedHSCSummary,
    //             cgpaPercentage: editedUGPercentage,
    //             cgpaInstitution: editedUGName,
    //             cgpaYear: editedUGYear,
    //             cgpaDescription: editedUGSummary
    //         });
    //         //console.log('Education info updated successfully:', response.data);
    //         setSeekerDetails(prevState => ({
    //             ...prevState,
    //             sslc_percentage: editedSSLCPercentage,
    //             sslc_institution: editedSSLCName,
    //             sslc_year: editedSSLCYear,
    //             sslcDescription: editedSSLCSummary,
    //             hsc_percentage: editedHSCPercentage,
    //             hsc_institution: editedHSCName,
    //             hsc_year: editedHSCYear,
    //             hscDescription: editedHSCSummary,
    //             cgpa_percentage: editedUGPercentage,
    //             cgpa_institution: editedUGName,
    //             cgpa_year: editedUGYear,
    //             cgpaDescription: editedUGSummary
    //         }));
    //         setIsEditing4(false);

    //     } catch (error) {
    //         console.error('Error updating education info:', error);
    //     }
    // };

    const handleSaveClick4 = async () => {
        try {
            const formData = new FormData();
            formData.append('email', seekerDetails.email);
            formData.append('sslcPercentage', editedSSLCPercentage);
            formData.append('sslcInstitution', editedSSLCName);
            formData.append('sslcYear', editedSSLCYear);
            formData.append('sslcDescription', editedSSLCSummary);
            if (sslcFile) {
                formData.append('sslcFile', sslcFile);
            }

            formData.append('hscPercentage', editedHSCPercentage);
            formData.append('hscInstitution', editedHSCName);
            formData.append('hscYear', editedHSCYear);
            formData.append('hscDescription', editedHSCSummary);
            if (hscFile) {
                formData.append('hscFile', hscFile);
            }

            formData.append('cgpaPercentage', editedUGPercentage);
            formData.append('cgpaInstitution', editedUGName);
            formData.append('cgpaYear', editedUGYear);
            formData.append('cgpaDescription', editedUGSummary);
            if (cgpaFile) {
                formData.append('cgpaFile', cgpaFile);
            }

            const response = await axios.post("https://ex.d5art.com/api/updateEducationInfo", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            //console.log('Education info updated successfully:', response.data);


            setSeekerDetails(prevState => ({
                ...prevState,
                sslc_percentage: editedSSLCPercentage,
                sslc_institution: editedSSLCName,
                sslc_year: editedSSLCYear,
                sslcDescription: editedSSLCSummary,
                sslc_file: sslcFile ? sslcFile.name : prevState.sslc_file, // Update sslc_file if a new file is selected, otherwise keep the previous value
                hsc_percentage: editedHSCPercentage,
                hsc_institution: editedHSCName,
                hsc_year: editedHSCYear,
                hscDescription: editedHSCSummary,
                hsc_file: hscFile ? hscFile.name : prevState.hsc_file, // Update hsc_file if a new file is selected, otherwise keep the previous value
                cgpa_percentage: editedUGPercentage,
                cgpa_institution: editedUGName,
                cgpa_year: editedUGYear,
                cgpaDescription: editedUGSummary,
                cgpa_file: cgpaFile ? cgpaFile.name : prevState.cgpa_file, // Update cgpa_file if a new file is selected, otherwise keep the previous value
            }));

            if (response) {
                //console.log('Education info updated successfully:', response.data);
                
                toast.success('Education info updated successfully');
                window.location.reload();
            } else {

                console.error('error in Education info ');
            }
            
            setIsEditing4(false);

            

        } catch (error) {
            console.error('Error updating education info:', error);
        }
    };


    const handleSaveClick5 = async () => {
        try {
            const formData = new FormData();
            formData.append('email', seekerDetails.email);
            formData.append('projects', JSON.stringify(projects));

            projects.forEach((project, index) => {
                if (project.projectFile instanceof File) { // Check if projectFile is a File object
                    formData.append(`project${index + 1}File`, project.projectFile);
                    //console.log("p", project.projectFile)
                }
            });
            formData.forEach((value, key) => {
                //console.log("formmmmmm", key, value);
            });
            const response = await axios.post('https://ex.d5art.com/api/updateProjetsInfo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            //console.log('Projects info updated successfully:', response.data);

            if (response) {
                //console.log('Projects info updated successfully:', response.data);
                
                toast.success('Projects info updated successfully');
                window.location.reload();
            } else {

                console.error('error in Projects info ');
            }
        } catch (error) {
            console.error('Error updating Projects info:', error);
        }
        setIsEditing5(false);
    };


    const handleCancelClick = () => {
        // Reset the edited values
        setEditedName(seekerDetails ? seekerDetails.name : "");
        setEditedRole(seekerDetails ? seekerDetails.jobrole : "");
        setEditedSummary(seekerDetails ? seekerDetails.description : "");
        setIsEditing(false);
    };
    const handleCancelClick2 = () => {
        // Reset the edited values
        setResumeFile(null); // Reset the file state


        setIsEditing2(false);
    };

    //console.log("resume file", resumeFile)
    const handleCancelClick3 = () => {
        setIsEditing3(false);
    };

    const handleCancelClick4 = () => {
        setEditedSSLCName(seekerDetails ? seekerDetails.sslc_institution : "");
        setEditedSSLCYear(seekerDetails ? seekerDetails.sslc_year : "");
        setEditedSSLCPercentage(seekerDetails ? seekerDetails.sslc_percentage : "");
        setEditedSSLCSummary(seekerDetails ? seekerDetails.sslcDescription : "");
        setSSLCFile(null); // Reset the file state

        setEditedHSCName(seekerDetails ? seekerDetails.hsc_institution : "");
        setEditedHSCYear(seekerDetails ? seekerDetails.hsc_year : "");
        setEditedHSCPercentage(seekerDetails ? seekerDetails.hsc_percentage : "");
        setEditedHSCSummary(seekerDetails ? seekerDetails.hscDescription : "");
        setHSCFile(null); // Reset the file state

        setEditedUGName(seekerDetails ? seekerDetails.cgpa_institution : "");
        setEditedUGYear(seekerDetails ? seekerDetails.cgpa_year : "");
        setEditedUGPercentage(seekerDetails ? seekerDetails.cgpa_percentage : "");
        setEditedUGSummary(seekerDetails ? seekerDetails.cgpaDescription : "");
        setUGFile(null); // Reset the file state

        setIsEditing4(false);
    };

    const handleCancelClick5 = () => {
        setIsEditing5(false);
    };
    const roleOptions = [
        { value: 'Frontend Developer', label: 'Frontend Developer' },
        { value: 'Backend Developer', label: 'Backend Developer' },
        { value: 'Tester', label: 'Tester' },
        { value: 'UIUX Designer', label: 'UIUX Designer' },
        { value: 'Digital Marketing', label: 'Digital Marketing' },
    ];

    const SkillOptions = [
        { value: 'Skill1', label: 'HTML' },
        { value: 'Skill2', label: 'React' },
        { value: 'Skill3', label: 'Node' },
        { value: 'Skill4', label: 'Next' },
        { value: 'Skill5', label: 'Bootstrap' },
        { value: 'Skill6', label: 'Tailwind' },
    ];


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#ffffff',
            borderRadius: '4px',
            color: 'black'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#fff',
            color: state.isSelected ? '#fff' : '#000', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#black', // Color of the selected option text
        }),
    };


    // const [skills, setSkills] = useState([
    //     {
    //         skill: "React",
    //         percentage: 80,
    //     },
    // ]);



    // const addSkill = () => {
    //     setSkills([...skills, { skill: '', percentage: 0 }]);
    // };

    const addSkill = () => {
        if (selectedSkill) {
            setSkills([...skills, { skill: selectedSkill.label, percentage: selectedSkill.percentage }]);
            setSelectedSkill(null); // Reset the selected skill
        }
    };

    const removeSkill = (index) => {
        const updatedSkills = [...skills];
        updatedSkills.splice(index, 1);
        setSkills(updatedSkills);
    };

    // const updateSkill = (index, key, value) => {
    //     const updatedSkills = [...skills];
    //     updatedSkills[index][key] = value;
    //     setSkills(updatedSkills);
    // };
    const updateSkill = (index, key, value) => {
        const updatedSkills = [...skills];
        updatedSkills[index][key] = value;
        setSkills(updatedSkills);
    };
    // const [certificates, setCertificates] = useState([
    //     {
    //         courseName: "UI/UX Design Certification",
    //         courseSummary: "Learned the fundamentals of UI/UX design...",
    //     },
    // ]);

    const [certificates, setCertificates] = useState([]);
    useEffect(() => {
        if (seekerDetails) {
            const certificatesData = [
                {
                    courseName: seekerDetails.certificate1 ? JSON.parse(seekerDetails.certificate1).name : '',
                    courseSummary: seekerDetails.certificate1 ? JSON.parse(seekerDetails.certificate1).source : '',
                    file: seekerDetails.certificate1 ? JSON.parse(seekerDetails.certificate1).file : null,
                },
                {
                    courseName: seekerDetails.certificate2 ? JSON.parse(seekerDetails.certificate2).name : '',
                    courseSummary: seekerDetails.certificate2 ? JSON.parse(seekerDetails.certificate2).source : '',
                    file: seekerDetails.certificate2 ? JSON.parse(seekerDetails.certificate2).file : null,
                },
                {
                    courseName: seekerDetails.certificate3 ? JSON.parse(seekerDetails.certificate3).name : '',
                    courseSummary: seekerDetails.certificate3 ? JSON.parse(seekerDetails.certificate3).source : '',
                    file: seekerDetails.certificate3 ? JSON.parse(seekerDetails.certificate3).file : null,
                },
            ];
            setCertificates(certificatesData.filter(cert => cert.courseName && cert.courseSummary));
        }
    }, [seekerDetails]);


    //console.log("certifictaes", certificates)


    const saveCertificates = (updatedCertificates) => {
        setCertificates(updatedCertificates);
    };

    const addCertificate = () => {
        const newCertificate = {
            courseName: '', // Default value for course name
            courseSummary: '', // Default value for course summary
            file: null, // Default value for file
        };

        const updatedCertificates = [...certificates, newCertificate];
        setCertificates(updatedCertificates);
    };

    const handleFileChange = (e, index) => {
        const updatedCertificates = [...certificates];
        updatedCertificates[index].file = e.target.files[0];
        setCertificates(updatedCertificates);
    };

    const removeCertificate = (index) => {
        const updatedCertificates = [...certificates];
        updatedCertificates.splice(index, 1);
        setCertificates(updatedCertificates);
    };


    const [experienceList, setExperienceList] = useState([]);

    // Assuming seekerDetails is the object containing the seeker data
    useEffect(() => {
        if (seekerDetails) {
            const experiences = [
                JSON.parse(seekerDetails.experience1 || '{}'),
                JSON.parse(seekerDetails.experience2 || '{}'),
                JSON.parse(seekerDetails.experience3 || '{}')
            ];

            setExperienceList(experiences.filter(exp => !!exp.role)); // Filter out experiences with empty roles
        }
    }, [seekerDetails]);

    //console.log("exp", experienceList)

    // Function to add a new experience
    const addExperience = () => {
        setExperienceList([...experienceList, { role: '', companyname: '', experienceYears: '' }]);
    };

    // Function to remove an experience
    const removeExperience = (index) => {
        const updatedExperienceList = [...experienceList];
        updatedExperienceList.splice(index, 1);
        setExperienceList(updatedExperienceList);
    };
    // const [projects, setProjects] = useState([
    //     {
    //         projectName: "Bally Website Research and Development",
    //         projectSummary: "UX case study gads asbdch bcja hbac hbjhsbc...",
    //     },
    // ]);

    // const addProject = () => {
    //     const newProject = {
    //         projectName: "",
    //         projectSummary: "",
    //     };

    //     setProjects([...projects, newProject]);
    // };

    // const removeProject = (index) => {
    //     const updatedProjects = [...projects];
    //     updatedProjects.splice(index, 1);
    //     setProjects(updatedProjects);
    // };
    const initialProjects = [
        {
            projectName: seekerDetails && seekerDetails.project1Name || "",
            projectSummary: seekerDetails && seekerDetails.project1Description || "",
            projectFile: null,
        },
        {
            projectName: seekerDetails && seekerDetails.project2Name || "",
            projectSummary: seekerDetails && seekerDetails.project2Description || "",
            projectFile: null,
        },
        {
            projectName: seekerDetails && seekerDetails.project3Name || "",
            projectSummary: seekerDetails && seekerDetails.project3Description || "",
            projectFile: null,
        },
    ];

    //console.log("I projects Info", initialProjects)
    const [projects, setProjects] = useState(initialProjects);
    //console.log("projects Info", projects)

    useEffect(() => {
        if (isEditing5) {
            setProjects(initialProjects);
        }
    }, [isEditing5]);

    //console.log("projects Info", projects)


    const addProject = () => {
        const newProject = {
            projectName: "",
            projectSummary: "",
            projectFile: null,
        };

        setProjects([...projects, newProject]);
    };
    const removeProject = (index) => {
        const updatedProjects = [...projects];
        updatedProjects.splice(index, 1);
        setProjects(updatedProjects);
    };



    const [sslFileUrl, setSSLFileUrl] = useState("");

    useEffect(() => {
        if (seekerDetails && seekerDetails.sslc_file) {
            setSSLFileUrl(seekerDetails.sslc_file);
        }
    }, [seekerDetails]);


    return (
        <div className='bg-[#373737] font-poppins '>
            <Header />
            <Toaster />
            <div className='Details flex justify-center bg-[#191919] text-white' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                <div className='w-[90%] md:flex py-5 md:py-10'>
                    <div className='md:w-[50%] p-5 md:p-10'>
                        <button className='px-3 pt-1 pb-1.5 bg-white text-black rounded-full text-xs font-bold' onClick={handleEditClick}>
                            Edit About <i className="fa-solid fa-pen pl-1 text-base"></i>
                        </button>

                        {isEditing ? (
                            <>
                                <h1 className='font-bold mt-5'>Hi!</h1>
                                <h1 className='flex font-bold text-3xl mt-5'>My Name Is <input className='bg-transparent border p-1 w-[50%] outline-none ml-2' type="text" value={editedName} onChange={(e) => setEditedName(e.target.value)} /></h1>
                                <p className='text-sm mt-5 font-bold'>
                                    <Select
                                        className='w-[15rem] text-center'
                                        value={roleOptions.find(option => option.value === editedRole)}
                                        onChange={(selectedOption) => setEditedRole(selectedOption.value)}
                                        options={roleOptions}
                                        styles={customStyles}
                                        placeholder='Select Role'
                                    />
                                </p>


                                <p className='text-xs mt-5'><textarea className='bg-transparent border p-1 h-20 w-[85%] outline-none' value={editedSummary} onChange={(e) => setEditedSummary(e.target.value)} /></p>

                                <div className='py-5 flex gap-20'>
                                    <button onClick={handleSaveClick} className='bg-white text-black rounded-full text-sm px-7 py-1 font-bold'>
                                        Save
                                    </button>
                                    <button onClick={handleCancelClick} className='bg-[#FF0000]/75 text-white rounded-full text-sm px-7 py-1 font-bold'>
                                        Cancel
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <h1 className='font-bold mt-5'>Hi!</h1>
                                <h1 className='font-bold text-3xl mt-5'>My Name Is {seekerDetails && seekerDetails.name}</h1>
                                <p className='text-sm mt-5 font-bold'>
                                    {seekerDetails && seekerDetails.jobrole
                                        ? (() => {
                                            try {
                                                const jobRoles = JSON.parse(seekerDetails.jobrole);
                                                return jobRoles.join(', ');
                                            } catch (e) {
                                                console.error('Error parsing job roles:', e);
                                                return 'Invalid job role data';
                                            }
                                        })()
                                        : 'No job role provided'}
                                </p>
                                <p className='text-xs mt-5'>{seekerDetails && seekerDetails.description}</p>


                            </>
                        )}
                    </div>
                    <div className='md:w-[50%]'>
                        <div>
                            <img src='/assets/User1.png'></img>
                        </div>
                    </div>
                </div>
            </div>

            <div className='MYSKILLS'>
                {isEditing2 ? (
                    <>
                        <div className=' flex justify-center bg-[#303030] text-white' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                            <div className='w-[85%] flex-col py-10'>
                                <div className='md:flex'>
                                    <div className='md:w-[50%] md:p-10'>
                                        <button className='px-3 pt-1 pb-1.5 bg-white text-black rounded-full text-xs font-bold' onClick={handleEditClick2}>
                                            Edit skill & certifictaes <i className="fa-solid fa-pen pl-1 text-base"></i>
                                        </button>
                                        <h1 className='text-3xl font-bold mt-5'>My skills & Hobbies 🚀 </h1>


                                        <p className='text-5xs mt-5'> Select Resume File:</p>

                                        <input type="file" accept=".pdf,.doc,.docx" onChange={(e) => setResumeFile(e.target.files[0])} />

                                    </div>
                                    <div className='md:w-[50%] md:px-20 py-10'>
                                        <div className='px-7'>

                                            {skills.length > 0 && skills.map((skill, index) => (
                                                <div key={index} className='mb-5'>
                                                    <p className='uppercase flex  justify-between'>
                                                        <input
                                                            type="text"
                                                            value={skill.skill}
                                                            onChange={(e) => updateSkill(index, 'skill', e.target.value)}
                                                            className="uppercase w-[80%] bg-transparent border p-1 outline-none"
                                                        />
                                                        <input
                                                            type="text"
                                                            value={skill.percentage}
                                                            onChange={(e) => updateSkill(index, 'percentage', parseInt(e.target.value, 10))}
                                                            className=" w-[10%] bg-transparent border p-1 outline-none"
                                                        />
                                                        <button onClick={() => removeSkill(index)} className='bg-white rounded-full w-4 h-4 flex justify-center items-center translate-y-1 text-black'> - </button>
                                                    </p>
                                                    <div className='py-3'>
                                                        <div className='h-2 rounded w-full bg-gray-100'>
                                                            <div
                                                                className='h-2 bg-yellow-500 rounded'
                                                                style={{ width: `${skill.percentage}%` }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className='flex justify-center py-5'>
                                                <Select
                                                    options={SkillOptions}
                                                    value={selectedSkill}
                                                    onChange={setSelectedSkill}
                                                    className="w-[80%]"
                                                />
                                                <input
                                                    type="text"
                                                    placeholder="Percentage"
                                                    value={selectedSkill ? selectedSkill.percentage : ''}
                                                    onChange={(e) => setSelectedSkill({ ...selectedSkill, percentage: parseInt(e.target.value, 10) })}
                                                    className="w-[10%] bg-transparent border p-1 outline-none"
                                                />
                                                <button onClick={addSkill} className='bg-white rounded-full w-4 h-4 flex justify-center items-center translate-y-1 text-black mr-2'> + </button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div className='md:px-10'>
                                    <h1 className='text-2xl font-bold'>Certifications: 🏅</h1>
                                    <div className='flex flex-col gap-5 md:flex-row justify-center md:py-10'>
                                        {certificates.map((certificate, index) => (
                                            <div key={index} className='md:w-[23%] p-5'>
                                                <div className='flex justify-end'>
                                                    <button onClick={() => removeCertificate(index)} className='bg-white rounded-full w-4 h-4 flex justify-center items-center translate-y-1 text-black'>
                                                        -
                                                    </button>
                                                </div>
                                                <div className='flex justify-center'>
                                                    <div className='bg-white rounded-full'>
                                                        <img src='/assets/badge.png' alt={`Certificate ${index + 1}`} />
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <p className='text-lg mt-5'>
                                                        <input
                                                            className='bg-transparent border p-1 w-[100%] outline-none ml-2'
                                                            type="text"
                                                            placeholder="Course Name"
                                                            value={certificate.courseName}
                                                            onChange={(e) => {
                                                                const updatedCertificates = [...certificates];
                                                                updatedCertificates[index].courseName = e.target.value;
                                                                setCertificates(updatedCertificates);
                                                            }}
                                                        />
                                                    </p>
                                                    <p className='text-xs mt-5'>
                                                        <input
                                                            className='bg-transparent border p-1 w-[100%] outline-none ml-2'
                                                            type="text"
                                                            placeholder="Course Summary"
                                                            value={certificate.courseSummary}
                                                            onChange={(e) => {
                                                                const updatedCertificates = [...certificates];
                                                                updatedCertificates[index].courseSummary = e.target.value;
                                                                setCertificates(updatedCertificates);
                                                            }}
                                                        />

                                                        <p className='text-xs mt-5'>
                                                            <input
                                                                type="file"
                                                                onChange={(e) => handleFileChange(e, index)}
                                                            />
                                                        </p>
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='flex justify-center py-5'>
                                        <button className='flex' onClick={() => addCertificate()}>
                                            <p className='bg-white rounded-full w-4 h-4 flex justify-center items-center translate-y-1 text-black mr-2'>+</p>
                                            Add Certificate
                                        </button>
                                    </div>
                                    <div className='py-5 flex justify-center gap-20'>
                                        <button onClick={handleSaveClick2} className='bg-white text-black rounded-full text-sm px-7 py-1 font-bold'>
                                            Save
                                        </button>
                                        <button onClick={handleCancelClick2} className='bg-[#FF0000]/75 text-white rounded-full text-sm px-7 py-1 font-bold'>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='MySkills flex justify-center bg-[#303030] text-white' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                            <div className='w-[85%] flex-col py-10'>
                                <div className='md:flex'>
                                    <div className='md:w-[50%] md:p-10'>
                                        <button className='px-3 pt-1 pb-1.5 bg-white text-black rounded-full text-xs font-bold' onClick={handleEditClick2}>
                                            Edit skill & certifictaes <i className="fa-solid fa-pen pl-1 text-base"></i>
                                        </button>
                                        <h1 className='text-3xl font-bold mt-5'>My skills & Hobbies 🚀</h1>
                                        <p className='text-5xs mt-6'> Resume Chosen File:  {seekerDetails && seekerDetails.resume_file} </p>
                                    </div>
                                    <div className='md:w-[50%] md:px-20 py-10'>
                                        <div className='px-7'>
                                            {skills.map((skill, index) => (
                                                <div key={index} className="mb-5">
                                                    <p className='uppercase flex justify-between'>
                                                        <p>{skill.skill}</p>
                                                        <p className='mr-10'>{skill.percentage}</p>
                                                    </p>
                                                    <div className='py-3'>
                                                        <div className='h-2 rounded w-full bg-gray-100'>
                                                            <div
                                                                className='h-2 bg-yellow-500 rounded'
                                                                style={{ width: `${skill.percentage}%` }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                                <div className='md:px-10'>
                                    <h1 className='text-2xl font-bold'>Certifications: 🏅</h1>
                                    <div className='flex flex-col gap-5 md:flex-row justify-center md:py-10'>
                                        {certificates.map((certificate, index) => (
                                            <div key={index} className='md:w-[23%] p-5'>
                                                <div className='flex justify-center'>
                                                    <div className='bg-white rounded-full'>
                                                        <img src='/assets/badge.png' alt="Certificate" />
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <p className='text-lg mt-5'>{certificate.courseName}</p>
                                                    <p className='text-xs mt-5'>{certificate.courseSummary}.</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className='EXPERIENCES. flex justify-center bg-[#191919] text-white' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                <div className='w-[90%] py-10'>
                    <h1 className='text-3xl font-bold text-center'>EXPERIENCES. ⏳</h1>
                    <button className='px-3 pt-1 pb-1.5 bg-white text-black rounded-full text-xs font-bold' onClick={handleEditClick3}>
                        Edit Experience <i className="fa-solid fa-pen pl-1 text-base"></i>
                    </button>
                    <>
                        {isEditing3 ? (
                            <>
                                {experienceList.map((experience, index) => (
                                    <div key={index} className='flex py-10'>
                                        <div className='w-[50%] flex justify-end px-10'>
                                            <div className='grid content-around'>
                                                <p className='text-end'>
                                                    <input
                                                        type="text"
                                                        className='bg-transparent border text-end p-1 w-[85%] outline-none'
                                                        placeholder="Role Experience"
                                                        value={experience.role}
                                                        onChange={(e) => {
                                                            const updatedList = [...experienceList];
                                                            updatedList[index].role = e.target.value;
                                                            setExperienceList(updatedList);
                                                        }}
                                                    />
                                                    <br />
                                                    <input
                                                        type="text"
                                                        className='bg-transparent border text-end mt-2 p-1 w-[85%] outline-none'
                                                        placeholder="Company"
                                                        value={experience.companyname}
                                                        onChange={(e) => {
                                                            const updatedList = [...experienceList];
                                                            updatedList[index].companyname = e.target.value;
                                                            setExperienceList(updatedList);
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className='h-20 w-[1px] bg-gray-100'>
                                            <div className='h-full w-3 grid content-around -translate-x-1.5'>
                                                <div className='p-1.5 rounded-full bg-yellow-500'></div>
                                            </div>
                                        </div>
                                        <div className='w-[50%] flex justify-start px-10'>
                                            <div className='grid content-around'>
                                                <p>
                                                    <input
                                                        type="text"
                                                        className='bg-transparent border text-start p-1 w-[85%] outline-none'
                                                        placeholder="Experience Years"
                                                        value={experience.experienceYears}
                                                        onChange={(e) => {
                                                            const updatedList = [...experienceList];
                                                            updatedList[index].experienceYears = e.target.value;
                                                            setExperienceList(updatedList);
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                onClick={() => removeExperience(index)}
                                                className='bg-white rounded-full w-4 h-4 flex justify-center items-center translate-y-1 text-black mr-2'
                                            >
                                                -
                                            </button>
                                        </div>
                                    </div>
                                ))}

                                <div className='flex justify-center'>
                                    <button className='flex' onClick={addExperience}>
                                        <p className='bg-white rounded-full w-4 h-4 flex justify-center items-center translate-y-1 text-black mr-2'>+</p>
                                        Add Experience
                                    </button>
                                </div>

                                <div className='py-5 flex justify-center gap-20'>
                                    <button onClick={handleSaveClick3} className='bg-white text-black rounded-full text-sm px-7 py-1 font-bold'>
                                        Save
                                    </button>
                                    <button onClick={handleCancelClick3} className='bg-[#FF0000]/75 text-white rounded-full text-sm px-7 py-1 font-bold'>
                                        Cancel
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                {experienceList.map((experience, index) => (
                                    <div key={index} className='flex '>
                                        <div className='w-[50%] flex justify-end px-10'>
                                            <div className='grid content-around'>
                                                <p className='text-end'>{experience.role}<br></br>{experience.companyname}</p>
                                            </div>
                                        </div>
                                        <div className='h-20 w-[1px] bg-gray-100'>
                                            <div className='h-full w-3 grid content-around -translate-x-1.5'>
                                                <div className='p-1.5 rounded-full bg-yellow-500'></div>
                                            </div>
                                        </div>
                                        <div className='w-[50%] flex justify-start px-10'>
                                            <div className='grid content-around'>
                                                <p>{experience.experienceYears}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                </div>
            </div>

            <div className='EDUCATION.. flex justify-center bg-[#303030] text-white' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                <div className='w-[90%] md:py-10'>
                    <h1 className='text-3xl font-bold text-center'>EDUCATION. 🎓</h1>
                    <button className='px-3 pt-1 pb-1.5 bg-white text-black rounded-full text-xs font-bold' onClick={handleEditClick4}>
                        Edit Education <i className="fa-solid fa-pen pl-1 text-base"></i>
                    </button>
                    <div>
                        {isEditing4 ? (
                            <>
                                <div className='md:flex justify-center py-10'>
                                    <div className='md:w-[80%] flex flex-col gap-10'>
                                        <div className='w-full flex gap-20 px-5 md:px-10'>
                                            <div className='w-[50%]'>
                                                <h1>Completed Secondary School Leaving Certificate at <span className='text-[#ffff00]'><input type="text" className='bg-transparent border p-1 w-[50%] outline-none' value={editedSSLCName} onChange={(e) => setEditedSSLCName(e.target.value)} /></span> in the year <span className='text-[#ffff00]'><input type="text" className='bg-transparent border p-1 w-[15%] outline-none' value={editedSSLCYear} onChange={(e) => setEditedSSLCYear(e.target.value)} /></span> and secured <span className='text-[#ffff00]'><input type="text" className='bg-transparent border p-1 w-[12%] outline-none' value={editedSSLCPercentage} onChange={(e) => setEditedSSLCPercentage(e.target.value)} /></span></h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32  h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'><textarea type="text" className='bg-transparent border p-1 h-14 w-[100%] outline-none' value={editedSSLCSummary} onChange={(e) => setEditedSSLCSummary(e.target.value)} /></p>
                                                <input type="file" accept=".pdf,.doc,.docx" onChange={(e) => setSSLCFile(e.target.files[0])} />
                                            </div>
                                            <div className='w-[50%]'>
                                                <h1>
                                                    Completed Higher Secondary Certificate at <span className='text-[#ffff00]'>
                                                        <input
                                                            type="text"
                                                            className='bg-transparent border p-1 w-[50%] outline-none'
                                                            value={editedHSCName}
                                                            onChange={(e) => setEditedHSCName(e.target.value)}
                                                        />
                                                    </span> in the year <span className='text-[#ffff00]'>
                                                        <input
                                                            type="text"
                                                            className='bg-transparent border p-1 w-[15%] outline-none'
                                                            value={editedHSCYear}
                                                            onChange={(e) => setEditedHSCYear(e.target.value)}
                                                        />
                                                    </span> and secured <span className='text-[#ffff00]'>
                                                        <input
                                                            type="text"
                                                            className='bg-transparent border p-1 w-[12%] outline-none'
                                                            value={editedHSCPercentage}
                                                            onChange={(e) => setEditedHSCPercentage(e.target.value)}
                                                        />
                                                    </span>
                                                </h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32 h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'>
                                                    <textarea
                                                        type="text"
                                                        className='bg-transparent border p-1 h-14 w-[100%] outline-none'
                                                        value={editedHSCSummary}
                                                        onChange={(e) => setEditedHSCSummary(e.target.value)}
                                                    />
                                                </p>
                                                <input type="file" accept=".pdf,.doc,.docx" onChange={(e) => setHSCFile(e.target.files[0])} />
                                            </div>
                                        </div>

                                        <div className='w-full flex gap-20 px-5 md:px-10 mt-5 md:mt-0'>
                                            <div className='w-[50%]'>
                                                <h1>
                                                    Completed Under Graduation at <span className='text-[#ffff00]'>
                                                        <input
                                                            type="text"
                                                            className='bg-transparent border p-1 w-[50%] outline-none'
                                                            value={editedUGName}
                                                            onChange={(e) => setEditedUGName(e.target.value)}
                                                        />
                                                    </span> in the year <span className='text-[#ffff00]'>
                                                        <input
                                                            type="text"
                                                            className='bg-transparent border p-1 w-[15%] outline-none'
                                                            value={editedUGYear}
                                                            onChange={(e) => setEditedUGYear(e.target.value)}
                                                        />
                                                    </span> and secured <span className='text-[#ffff00]'>
                                                        <input
                                                            type="text"
                                                            className='bg-transparent border p-1 w-[12%] outline-none'
                                                            value={editedUGPercentage}
                                                            onChange={(e) => setEditedUGPercentage(e.target.value)}
                                                        />
                                                    </span>
                                                </h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32 h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'>
                                                    <textarea
                                                        type="text"
                                                        className='bg-transparent border p-1 h-14 w-[100%] outline-none'
                                                        value={editedUGSummary}
                                                        onChange={(e) => setEditedUGSummary(e.target.value)}
                                                    />
                                                </p>
                                                <input type="file" accept=".pdf,.doc,.docx" onChange={(e) => setUGFile(e.target.files[0])} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='py-5 flex justify-center gap-20'>
                                    <button onClick={handleSaveClick4} className='bg-white text-black rounded-full text-sm px-7 py-1 font-bold'>
                                        Save
                                    </button>
                                    <button onClick={handleCancelClick4} className='bg-[#FF0000]/75 text-white rounded-full text-sm px-7 py-1 font-bold'>
                                        Cancel
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='md:flex justify-center py-10'>
                                    <div className='w-[80%] flex flex-col gap-10'>
                                        <div className='w-full flex gap-20 px-5 md:px-10'>
                                            {/* SSLC Section */}
                                            <div className="w-[50%]">
                                                <h1>Completed Secondary School Leaving Certificate at <span className='text-[#ffff00]'> {seekerDetails && seekerDetails.sslc_institution}</span> in the year <span className='text-[#ffff00]'> {seekerDetails && seekerDetails.sslc_year}</span> and secured <span className='text-[#ffff00]'> {seekerDetails && seekerDetails.sslc_percentage}%</span></h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32  h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'> {seekerDetails && seekerDetails.sslcDescription}</p>
                                                <span>SSLC chosen file: {seekerDetails && seekerDetails.sslc_file}</span>
                                            </div>

                                            {/* HSC Section */}
                                            <div className="w-[50%]">
                                                <h1>Completed Higher Secondary Certificate at <span className='text-[#ffff00]'>{seekerDetails && seekerDetails.hsc_institution}</span> in the year <span className='text-[#ffff00]'>{seekerDetails && seekerDetails.hsc_year}</span> and secured <span className='text-[#ffff00]'>{seekerDetails && seekerDetails.hsc_percentage}%</span></h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32  h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'>{seekerDetails && seekerDetails.hscDescription}</p>
                                                <span>HSC chosen file: {seekerDetails && seekerDetails.hsc_file}</span>
                                            </div>
                                        </div>

                                        <div className='w-full flex gap-20 px-5 md:px-10 mt-5 md:mt-0'>
                                            {/* UG Section */}
                                            <div className="w-[50%]">
                                                <h1 className=''>Completed Under Graduation at <span className='text-[#ffff00]'>{seekerDetails && seekerDetails.cgpa_institution}</span> in the year <span className='text-[#ffff00]'>{seekerDetails && seekerDetails.cgpa_year}</span> and secured <span className='text-[#ffff00]'>{seekerDetails && seekerDetails.cgpa_percentage}%</span></h1>
                                                <div className='py-3 pb-5'>
                                                    <div className='w-32  h-[2px] bg-[#0EA4A9]'></div>
                                                </div>
                                                <p className='text-xs text-gray-400'>{seekerDetails && seekerDetails.cgpaDescription}</p>
                                                <span>CGPA chosen file: {seekerDetails && seekerDetails.cgpa_file}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                    </div>
                </div>
            </div>
            <div className='Recent project flex justify-center bg-[#191919] text-white pb-10' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
                <div className='w-[90%] py-10'>
                    <div >
                        <h1 className='text-3xl font-bold text-center uppercase text-gray-300'>Recent project 💻</h1>
                        <div className='part-1'>
                            <button className='px-3 pt-1 pb-1.5 bg-white text-black rounded-full text-xs font-bold' onClick={handleEditClick5}>
                                Edit Projects <i className="fa-solid fa-pen pl-1 text-base"></i>
                            </button>
                            <div>
                                {isEditing5 ? (
                                    <div>
                                        <div className='flex justify-center'>
                                            {projects.map((project, index) => (
                                                <div key={index} className='md:w-[30%] flex gap-5 px-5 md:px-10'>
                                                    <div className='flex flex-col-reverse gap-3'>
                                                        <div className='grid content-between gap-2'>
                                                            <h1 className='font-bold mt-3 text-xl'>
                                                                <input
                                                                    type="text"
                                                                    className='bg-transparent border p-1 w-[100%] outline-none'
                                                                    placeholder="Project Name"
                                                                    value={project.projectName}
                                                                    onChange={(e) => {
                                                                        const updatedProjects = [...projects];
                                                                        updatedProjects[index].projectName = e.target.value;
                                                                        setProjects(updatedProjects);
                                                                    }}
                                                                />
                                                            </h1>
                                                            <div className='flex'>
                                                                <p className='w-[90%] italic text-xs 2xl:text-base'>
                                                                    <textarea
                                                                        type="text"
                                                                        className='bg-transparent border p-1 h-14 w-[100%] outline-none'
                                                                        placeholder="Project Summary"
                                                                        value={project.projectSummary}
                                                                        onChange={(e) => {
                                                                            const updatedProjects = [...projects];
                                                                            updatedProjects[index].projectSummary = e.target.value;
                                                                            setProjects(updatedProjects);
                                                                        }}
                                                                    />
                                                                </p>
                                                                <div className='w-[10%] flex items-end'>
                                                                    <input
                                                                        type="file"
                                                                        accept=".pdf,.doc,.docx"
                                                                        onChange={(e) => {
                                                                            const file = e.target.files[0];
                                                                            const updatedProjects = [...projects];
                                                                            updatedProjects[index].projectFile = file; // Update projectFile
                                                                            setProjects(updatedProjects);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <img src='/assets/website1.png' alt={`Project ${index + 1}`} />
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-end'>
                                                            <button onClick={() => removeProject(index)} className='bg-white rounded-full w-4 h-4 flex justify-center items-center translate-y-1 text-black '>
                                                                -
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='flex justify-center py-5'>
                                            <button className='flex' onClick={() => {
                                                setIsEditing5(true);
                                                addProject(); // Add a new project when switching to edit mode
                                            }}>
                                                <p className='bg-white rounded-full w-4 h-4 flex justify-center items-center translate-y-1 text-black mr-2'>+</p>
                                                Add Project
                                            </button>
                                        </div>
                                        <div className='py-5 flex justify-center gap-20'>
                                            <button onClick={handleSaveClick5} className='bg-white text-black rounded-full text-sm px-7 py-1 font-bold'>
                                                Save
                                            </button>
                                            <button onClick={handleCancelClick5} className='bg-[#FF0000]/75 text-white rounded-full text-sm px-7 py-1 font-bold'>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='md:flex justify-center py-10'>
                                            {projects.map((project, index) => (
                                                <div key={index} className='md:w-[30%] flex gap-5 px-5 md:px-10'>
                                                    <div className='flex flex-col-reverse gap-3'>
                                                        <div className='grid content-between gap-2'>
                                                            <h1 className='font-bold mt-3 text-xl'>{project.projectName}</h1>
                                                            <div className='flex'>
                                                                <p className='w-[90%] italic text-xs 2xl:text-base'>{project.projectSummary}</p>
                                                                <div className='w-[10%] flex items-end'>
                                                                    <button className='bg-white/10 px-1'>
                                                                        <i className="fa-solid fa-arrow-right-long text-2xl -rotate-45"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <img src='/assets/website1.png' alt={`Project ${index + 1}`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>

                        </div>

                    </div>

                    <div className='flex justify-center mt-5'>
                        <a href='/RMS/Seeker/SeekerDashboard'>
                            <button className='flex pt-3 pb-1 px-3 bg-black rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' >
                                <button className='p-2 rounded-full translate-y-0.5 group-hover:translate-x-[109px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f] mr-5'></button> <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-6 transform-transition duration-500 ease-in-out'>Chat </p> <button className='ml-5 p-3.5 rounded-full -translate-y-1  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}