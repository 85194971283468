import React from 'react'

export default function Section2({percentages}) {

    const Realisticpercentage = '75%';
    const Investigativepercentage = '60%';
    const Artisticpercentage = '80%';
    const Socialpercentage = '50%';
    const Enterprisingpercentage = '70%';
    const Conventionalpercentage = '40%';

    return (
        <div className='section-2 relative'>
            <h1 className='text-3xl font-semibold'>Detailed summary</h1>
            <img src='/assets/C1.png' className='absolute -top-10 lg:-top-5 lg:-left-10 w-8 h-8 fa-bounce'></img>
            <img src='/assets/C3.png' className='absolute top-5 right-0 lg:-right-5 w-8 h-8 fa-bounce'></img>
            <div className='flex flex-col lg:flex-row gap-10 items-center mt-7'>
                <div className='w-[100%] lg:w-[40%] space-y-4'>
                    <div className='flex flex-wrap justify-center lg:justify-start gap-2'>
                        <div className='rounded-xl w-24 lg:w-32 2xl:w-40 h-24 lg:h-32 2xl:h-40 grid place-content-center bg-gradient-to-r from-[#172c2e] to-[#2e313a]'><img src='/assets/jordanevent/R.svg'></img></div>
                        <div className='rounded-xl w-24 lg:w-32 2xl:w-40 h-24 lg:h-32 2xl:h-40 grid place-content-center bg-gradient-to-r from-[#172c2e] to-[#2e313a]'><img src='/assets/jordanevent/I.svg'></img></div>
                        <div className='rounded-xl w-24 lg:w-32 2xl:w-40 h-24 lg:h-32 2xl:h-40 grid place-content-center bg-gradient-to-r from-[#172c2e] to-[#2e313a]'><img src='/assets/jordanevent/A.svg'></img></div>
                        <div className='rounded-xl w-24 lg:w-32 2xl:w-40 h-24 lg:h-32 2xl:h-40 grid place-content-center bg-gradient-to-r from-[#172c2e] to-[#2e313a]'><img src='/assets/jordanevent/S.svg'></img></div>
                        <div className='rounded-xl w-24 lg:w-32 2xl:w-40 h-24 lg:h-32 2xl:h-40 grid place-content-center bg-gradient-to-r from-[#172c2e] to-[#2e313a]'><img src='/assets/jordanevent/E.svg'></img></div>
                        <div className='rounded-xl w-24 lg:w-32 2xl:w-40 h-24 lg:h-32 2xl:h-40 grid place-content-center bg-gradient-to-r from-[#172c2e] to-[#2e313a]'><img src='/assets/jordanevent/C.svg'></img></div>
                    </div>
                    <p className='leading-9'>The RIASEC model is a shorthand for the six personality types. It is often used to help individuals understand their own types and how these relate to potential career paths. </p>
                </div>
                <div className='w-[100%] lg:w-[60%]'>
                    <div className='bg-[#48484836] rounded-xl px-7 lg:px-10 py-7 space-y-6'>
                        <div>
                            <h1 className='font-bold text-lg'>Realistic</h1>
                            <div className='mt-2 bg-[#D9D9D921] rounded-md'>
                                <div className='bg-gradient-to-r from-[#002366] to-[#0087A5] rounded-md h-2' style={{ width: percentages?.Realistic }}></div>
                            </div>
                        </div>
                        <div>
                            <h1 className='font-bold text-lg'>Investigative</h1>
                            <div className='mt-2 bg-[#D9D9D921] rounded-md'>
                                <div className='bg-gradient-to-r from-[#002366] to-[#0087A5] rounded-md h-2' style={{ width: percentages?.Investigative }}></div>
                            </div>
                        </div>
                        <h1 className='font-bold text-lg'>Artistic</h1>
                        <div className='mt-2 bg-[#D9D9D921] rounded-md'>
                            <div className='bg-gradient-to-r from-[#002366] to-[#0087A5] rounded-md h-2' style={{ width: percentages?.Artistic }}></div>
                        </div>
                        <div>
                            <h1 className='font-bold text-lg'>Social</h1>
                            <div className='mt-2 bg-[#D9D9D921] rounded-md'>
                                <div className='bg-gradient-to-r from-[#002366] to-[#0087A5] rounded-md h-2' style={{ width: percentages?.Social }}></div>
                            </div>
                        </div>
                        <div>
                            <h1 className='font-bold text-lg'>Enterprising</h1>
                            <div className='mt-2 bg-[#D9D9D921] rounded-md'>
                                <div className='bg-gradient-to-r from-[#002366] to-[#0087A5] rounded-md h-2' style={{ width: percentages?.Enterprising }}></div>
                            </div>
                        </div>
                        <div>
                            <h1 className='font-bold text-lg'>Conventional</h1>
                            <div className='mt-2 bg-[#D9D9D921] rounded-md'>
                                <div className='bg-gradient-to-r from-[#002366] to-[#0087A5] rounded-md h-2' style={{ width: percentages?.Conventional }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
