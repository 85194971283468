import React, { useEffect, useState, useCallback, useRef } from 'react'
//import Header from './Header';
import LoginHeader from './LoginHeader';
import Footer from '../Footer';
import './App.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchRecruiterDashboardData } from './actions';
import { toast, Toaster } from 'react-hot-toast';
import { getMaxListeners } from 'process';
import { ethers } from 'ethers';
// import config from './config';
import abi from './abi.json';

import Moralis from 'moralis';
import { NavLink, useLocation } from 'react-router-dom';
// import { setRecruiterEmail } from '../store';
import Confetti from 'react-dom-confetti';
import ApexChart from './ApexChart';
import CountryList from "../CountryCode.json";
import StateList from "../States.json";

import CurrencyList from '../CurrencyList.json';
import Select from 'react-select';
import { useWeb3ModalProvider, useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers5/react';


export default function RecruiterDashboard() {
    const [recruiterName, setRecruiterName] = useState('');
    const [recruiterEmail, setRecruiterEmail] = useState('');
    const [recruiterOrganizationName, setRecruiterOrganizationName] = useState('');
    const [recruiterDesignation, setRecruiterDesignation] = useState('');
    const [recruiterIndustryType, setRecruiterIndustryType] = useState('');
    const [recruiterWebsiteURL, setRecruiterWebsiteURL] = useState('');
    const [recruiterWalletAddress, setRecruiterWalletAddress] = useState('');
    const [recruiterAvatar, setRecruiterAvatar] = useState('');
    const [recruiterMobileNumber, setRecruiterMobileNumber] = useState('');
    const [recruiterCountry, setRecruiterCountry] = useState('');
    const [recruiterState, setRecruiterState] = useState('');

    const [isDropdown, setDropdown] = useState(false);

    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);


    const [recruiterOfferDetails, setRecruiterOfferDetails] = useState([]);
    const [seekerOfferEmail, setSeekerOfferEmail] = useState('');


    const [balance, setBalance] = useState(null);
    const [mappedTransfers, setMappedTransfers] = useState([]);



    const [seekerName, setSeekerName] = useState([]);
    const [tokens, setTokens] = useState('');
    const [seekerContractType, setSeekerContractType] = useState('');
    const [seekerDetails, setSeekerDetails] = useState([]);
    const [SeekerEmail, setSeekerEmail] = useState('');
    const [cartId, setCartId] = useState(null);
    const [seekerAvatar, setSeekerAvatar] = useState('');



    console.log('888888888888', cartId);



    const [fullName, setFullName] = useState('');
    const [organization, setOrganization] = useState('');
    const [mailId, setMailId] = useState('');
    const [roleInHiring, setRoleInHiring] = useState('');
    const [location, setLocation] = useState('');
    const [destination, setDestination] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');


    const [data, setData] = useState([]);
    const [openCount, setOpenCount] = useState(0);
    const [closedCount, setclosedCount] = useState(0);
    const [resolvedCount, setResolvedCount] = useState(0);

    const [creditData, setCreditData] = useState([]);
    const [debitData, setDebitData] = useState([]);
    const [months, setMonths] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [fileError, setFileError] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    const [displayedTransactions, setDisplayedTransactions] = useState([]);

    const [filteredStateOptions, setFilteredStateOptions] = useState([]);




    const email = useSelector((state) => state.recruiter_email);
    //  console.log("Email Logged In", email)
    const dispatch = useDispatch();

    //  console.log("location", location)

    const Logout = () => {


        dispatch(setSeekerEmail(""));
        window.location = '/';
    };

    const handleDropdown = () => {
        setDropdown(!isDropdown);
    }

    const handleClickOutside = (event) => {
        if (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
            setDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdown]);



    //ticket


    const [formData, setFormData] = useState({
        mailId: email,
        subject: '',
        category: '',
        description: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://ex.d5art.com/api/RecruiterRaiseTickets', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {

                console.log('Ticket submitted successfully');
                toast.success('Ticket Submitted Successfully');
                window.location.reload();
            } else {

                console.error('Error submitting ticket');
            }
        } catch (error) {
            console.error('Error submitting ticket:', error);
        }
    };




    useEffect(() => {

        const fetchData1 = async () => {
            try {
                //    const mail_id = "createsomething100@gmail.com";
                const mail_id = email;
                const response = await fetch(`https://ex.d5art.com/api/SendDataRecruiterRaiseTickets/?mail_id=${mail_id}`);
                console.log('3333333333', mail_id)
                const result = await response.json();
                //console.log('666666',result)

                const openIssues = result.data.filter(item => item.status === 'Open');
                const openCount = openIssues.length;

                const closedIssues = result.data.filter(item => item.status === 'Closed');
                const closedCount = closedIssues.length;

                console.log('Number of Open Issues:', openCount);
                console.log('Number of Closed Issues:', closedCount);

                setData(result);
                setOpenCount(openCount);
                setclosedCount(closedCount);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        fetchData1();
    }, [])


    const handleTicket = (e) => {
        const file = e.target.files[0];
        console.log("file", file)
        if (file && file.size > 5 * 1024 * 1024) { // 5 MB limit
            setFileError('File size should be less than 5 MB');
            setSelectedFile(null);
            setFormData({ ...formData, file: null });
        } else {
            setFileError('');
            setSelectedFile(file);
            setFormData({ ...formData, file });
        }
    };


    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(5); // Initialize entitiesPerPage in state


    const indexOfLastItem = currentPage * entitiesPerPage;
    const indexOfFirstItem = indexOfLastItem - entitiesPerPage;
    const currentData = Array.isArray(data.data) ? data.data.slice(indexOfFirstItem, indexOfLastItem) : [];
    console.log("currentdata", currentData)
    const emptyRowCount = entitiesPerPage - currentData.length;
    console.log("emptyRowCount", emptyRowCount)

    const handleNextClickTicket = () => {
        if (currentPage < Math.ceil(data.data.length / entitiesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClickTicket = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset currentPage when entitiesPerPage changes
    };


    // connect wallet

    const { address, chainId, isConnected } = useWeb3ModalAccount();

    console.log("address, chainId, isConnected", address, chainId, isConnected);



    // my profile

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/recruiter_dashboard?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('5555', data.data.avatar);


                if (response.ok) {

                    setRecruiterName(data.data.name);
                    setRecruiterEmail(data.data.email);
                    setRecruiterOrganizationName(data.data.organizationName);
                    setRecruiterIndustryType(data.data.industryType);
                    setRecruiterWebsiteURL(data.data.websiteURL);
                    setRecruiterMobileNumber(data.data.mobileNumber);
                    setRecruiterDesignation(data.data.yourDesignation);
                    setRecruiterCountry(data.data.country)
                    setRecruiterState(data.data.state)

                    setRecruiterWalletAddress(data.data.walletaddress);
                    setRecruiterAvatar(data.data.avatar);

                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);






    useEffect(() => {
        setCountries(CountryList);
        setStates(StateList);

    }, []);

    useEffect(() => {
        if (location) {
            const filteredStates = states.filter(state => state.country_name === location);
            setFilteredStateOptions(filteredStates);
        }
    }, [location, states]);


    const handleUpdateClick = async () => {
        try {
            const response = await fetch(`https://ex.d5art.com/api/update_recruiter_details?email=${email}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fullName,
                    organization,
                    roleInHiring,
                    location,
                    destination,
                    mobileNumber,
                    originalWalletAddress: address,
                }),
            });

            const responseBody = await response.json();
            console.log('response body:', responseBody);

            if (response.ok) {
                console.log('Data updated successfully');
                toast.success('Data Updated Successfully');
                window.location.reload();
            } else {
                if (responseBody.error === 'Wallet Address already exists') {
                    toast.error('Wallet Address already exists');
                } else {
                    console.error('Error updating data:', response.statusText);
                    toast.error(`Error updating data: ${responseBody.message}`);
                }
            }
        } catch (error) {
            console.error('Error updating data:', error);
            toast.error('Error updating data. Please try again later.');
        }
    };

    console.log('Type of data:', typeof data);


    // my cart 

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/recruiter_display_mycart?recruiter_email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('66666777', data.data);

                const seeker_name = data.data.map(item => item.seeker_name);
                const tokens = data.data.map(item => item.tokens);
                const contractTypes = data.data.map(item => item.contractType);
                const seeker_email = data.data.map(item => item.seeker_email);
                const cart_id = data.data.map(item => item.id);
                const avatar = data.data.map(item => item.SeekerAvatar);
                console.log('Contract Types:', avatar);

                if (data && data.data) {
                    const seekerNames = data.data.map(item => item.seeker_name);
                    const tokens = data.data.map(item => item.tokens);
                    const contractTypes = data.data.map(item => item.contractType);
                    const seekerEmails = data.data.map(item => item.seeker_email);
                    const cartIds = data.data.map(item => item.id);
                    const seekerAvatar = data.data.map(item => item.SeekerAvatar);

                    setSeekerName(seekerNames);
                    setTokens(tokens);
                    setSeekerContractType(contractTypes);
                    setSeekerDetails(data.data);
                    setSeekerEmail(seekerEmails);
                    setCartId(cartIds);
                    setSeekerAvatar(seekerAvatar);

                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);

    // my offers page div-2

    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await fetch(`https://ex.d5art.com/api/recruiterOfferResponse?email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('99999999', data);
                console.log('99999999', data.data);


                const recruiter_offer_email = data.data.map(item => item.Recuiter_email);


                console.log('01', data.data[0].Seeker_email);

                console.log('00000000000011', data.data[0]);



                if (response.ok) {

                    // setSeekerOfferDetails(data.data);
                    setSeekerOfferEmail(data.data[0].Seeker_email);
                    setRecruiterOfferDetails(data.data);


                } else {
                    console.error('Error fetching data:', data.error);
                }




            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email]);













    //

    const [activeTab, setActiveTab] = useState('nft');
    const [active, setActive] = useState('div1');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const location_path = useLocation();

    useEffect(() => {


        const queryParams = new URLSearchParams(location_path.search);
        const tabParam = queryParams.get('tab');

        if (tabParam) {
            handleTabClick(tabParam);
        }
    }, [location_path]);


    const handleTabClick = (tab) => {
        setActiveTab(tab === activeTab ? null : tab);
        if (tab === 'user') {
            handleAnchorClick('div3');
        } else {
            switch (tab) {
                case 'offers':
                    handleAnchorClick('div2');
                    break;
                case 'chat':
                    handleAnchorClick('div4');
                    break;
                case 'wallet':
                    handleAnchorClick('div5');
                    break;
                case 'Ticket':
                    handleAnchorClick('div6');
                    break;
                default:
                    handleAnchorClick('div1');
            }
        }
    };

    const handleAnchorClick = (tab) => {
        setActive(tab);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const containerStyles = {
        width: '100%',
        paddingTop: '10px',
        color: 'white',
        backgroundImage: windowWidth <= 767 ? 'none' : 'url("./assets/image2.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const iconMap = {
        'nft': (
            <svg className="w-5 h-5 mr-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
            </svg>
        ),
        'offers': (
            <i className="fa-solid fa-envelope-open-text text-xl mr-5"></i>
        ),
        'user': (
            <i className="fa-solid fa-users text-xl mr-5"></i>
        ),
        'chat': (
            <i className="fa-regular fa-message text-xl mr-5"></i>
        ),
        'wallet': (
            <i className="fa-solid fa-wallet text-xl mr-5"></i>
        ),
        'Ticket': (
            <i className="fa-solid fa-question text-xl mr-5"></i>
        ),
        'Signout': (
            <i className="fa-solid fa-person-through-window text-xl mr-5"></i>
        )
    };

    const TabButton = ({ label, tab }) => {
        return (
            <button
                className={`flex pl-12 w-full py-2.5  ${tab === activeTab ? 'inner-shadow' : ''}`}
                onClick={() => handleTabClick(tab)}
            >
                {iconMap[tab]}
                <span className="ml-2">{label}</span>
            </button>
        );
    };
    const [isPartypopup, setPartypopup] = useState(false);
    const [isCongrats, setCongrats] = useState(false);

    const handlePartypopup = () => {
        setPartypopup(!isPartypopup)
    }

    const handleCongrats = () => {
        setCongrats(!isCongrats)
    }


    const [isOpenPayment, setOpenPayment] = useState(false);


    const handlePaymentPopUp = () => {
        setOpenPayment(!isOpenPayment)
    }
    const [isOpenBuy1, setOpenBuy1] = useState(false);
    const [isOpenBuy2, setOpenBuy2] = useState(false);
    const [isOpenBuy3, setOpenBuy3] = useState(false);

    // const handleBuy1 = () => {
    //     setOpenBuy1(!isOpenBuy1)
    // }
    // const handleBuy2 = () => {
    //     setOpenBuy2(!isOpenBuy2)
    // }
    // const handleBuy3 = () => {
    //     setOpenBuy3(!isOpenBuy3)
    // }
    const [windowDimension, setWindowDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [Btn, setBtn] = useState(false);

    const detectSize = () => {
        setWindowDimension({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    const handleBtnpop = () => {
        setBtn(!Btn);
    };

    // Effect for handling resize events
    useEffect(() => {
        const handleResize = () => {
            detectSize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array


    const confettiConfig = {
        angle: 90,
        spread: 360,
        startVelocity: 40,
        elementCount: 70,
        dragFriction: 0.12,
        duration: 6000,
        stagger: 3,
        width: '10px',
        height: '10px',
        perspective: '500px',
        colors: ['#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#9400D3'],
    };



    const handleButtonClick = () => {

        const newPath = '/RMS';
        window.location.href = newPath;
    };


    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };


    // const handleUpload = async () => {

    //     if (!file) {
    //         toast.error('Offer Letter is required');
    //         return;
    //     }

    //     if (file) {
    //         const seekerEmail = selectedSeekerEmail.seeker_email;
    //         //const seekerEmail = 'nitish@gmail.com';

    //         console.log("seekeremail345345", seekerName);

    //         const formData = new FormData();
    //         formData.append('pdfFile', file);
    //         formData.append('Seeker_email', seekerEmail);
    //         formData.append('Recuiter_email', email);
    //         formData.append('organizationName', recruiterOrganizationName);
    //         formData.append('industryType', recruiterIndustryType);
    //         formData.append('websiteURL', recruiterWebsiteURL);
    //         formData.append('seeker_name', selectedSeekerName.seeker_name);
    //         formData.append('seeker_contract_type', selectedSeekerContractType.contractType);
    //         formData.append('recruiterAvatar', recruiterAvatar);
    //         formData.append('seekerAvatar', seekerAvatar);



    //         console.log('formData', formData)

    //         try {
    //             const response = await fetch('https://ex.d5art.com/api/UploadOfferletter', {
    //                 method: 'POST',
    //                 body: formData,
    //             });

    //             const data = await response.json();
    //             console.log("upload response :", data);

    //             if (data.message) {
    //                 toast.success(data.message);
    //                 window.location.reload();
    //             } else {
    //                 console.warn('Response does not contain a "message" property:', data);
    //             }

    //         } catch (error) {
    //             console.error('Error uploading file:', error);
    //         }
    //     }
    // };


    const handleUpload = async () => {
        if (!file) {
            toast.error('Offer Letter is required');
            return;
        }

        if (file) {
            const seekerEmail = selectedSeekerEmail.seeker_email;
            // const seekerEmail = 'nitish@gmail.com';

            console.log("seekeremail345345", seekerName);

            const formData = new FormData();
            formData.append('pdfFile', file);
            formData.append('Seeker_email', seekerEmail);
            formData.append('Recuiter_email', email);
            formData.append('organizationName', recruiterOrganizationName);
            formData.append('industryType', recruiterIndustryType);
            formData.append('websiteURL', recruiterWebsiteURL);
            formData.append('seeker_name', selectedSeekerName.seeker_name);
            formData.append('seeker_contract_type', selectedSeekerContractType.contractType);
            formData.append('recruiterAvatar', recruiterAvatar);
            formData.append('seekerAvatar', seekerAvatar);

            console.log('formData', formData);

            const toastId = toast.loading('Sending offer letter, please wait...');

            try {
                const response = await fetch('https://ex.d5art.com/api/UploadOfferletter', {
                    method: 'POST',
                    body: formData,
                });

                const data = await response.json();
                console.log("upload response :", data);

                if (data.message) {
                    toast.success(data.message, { id: toastId });
                    window.location.reload();
                } else {
                    console.warn('Response does not contain a "message" property:', data);
                    toast.error('An error occurred while uploading the offer letter', { id: toastId });
                }

            } catch (error) {
                console.error('Error uploading file:', error);
                toast.error('Failed to upload offer letter', { id: toastId });
            } finally {
                toast.dismiss(toastId);
            }
        }
    };

    const [selectedSeekerName, setSelectedSeekerName] = useState('');
    const [selectedSeekerEmail, setSelectedSeekerEmail] = useState('');
    const [selectedSeekerContractType, setSelectedSeekerContractType] = useState('');
    const [selectedCartId, setSelectedCartId] = useState({ id: null });

    const [selectedRecruiterEmail, setSelectedRecruiterEmail] = useState('');
    const [selectedRecruiterOrganizationName, setSelectedRecruiterOrganizationName] = useState('');
    const [selectedRecruiterWebsiteURL, setSelectedRecruiterWebsiteURL] = useState('');
    const [selectedSeekerAvatar, setSelectedSeekerAvatar] = useState('');

    //   const [selectedSeekerEmail, setSelectedSeekerEmail] = useState('');

    const handleChatButtonClick = (seeker) => {

        console.log('Clicked Chat for:', seeker);
        handleAnchorClick('div4');
        setSelectedSeekerName(seeker);
        setSelectedSeekerEmail(seeker);
        setSelectedSeekerContractType(seeker);
        setSelectedCartId(seeker);
        setSelectedRecruiterEmail(seeker);
        setSelectedRecruiterOrganizationName(seeker);
        setSelectedRecruiterWebsiteURL(seeker);
        setSelectedSeekerAvatar(seeker);


    };

    const [isRemovepopup, setRemovepopup] = useState(false);

    const handleRemoveButtonClick = () => {
        setRemovepopup(!isRemovepopup)
    }





    const [active5, setActive5] = useState('div5');
    const [startIndex, setStartIndex] = useState(0);
    const transactionsPerPage = 5;

    const handleNextClick = () => {
        setStartIndex(prevIndex => prevIndex + transactionsPerPage);
    };

    const handlePrevClick = () => {
        setStartIndex(prevIndex => Math.max(0, prevIndex - transactionsPerPage));
    };





    // payment 
    const wallet_address = recruiterWalletAddress;



    const [payment, setpayment] = useState({
        name: 'Subscription Plan Name',
        img: 'Subscription Image URL',
        price: 100,
    });

    const [paymentData, setPaymentData] = useState(null);
    const [popupMessage, setPopupMessage] = useState('');
    const [loading, setLoading] = useState(false);






    const apiKey = "0b6f16b4d0563ae6f87117706bb9c15256911c5925dfa5c4a92523e269ac5a0f";
    // const razorpayKey = "rzp_test_YrzYxp9mOwo6N9"; // test key
    const razorpayKey = "rzp_live_W0JUBprFlbC0MA"; // live key
    



    const fetchPrice = async (currency) => {
        if (currency === 'USD') return 1;
        const response = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=${currency}&api_key=${apiKey}`);
        return response.data[currency] || null;
    };


    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [price, setPrice] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const [paymentPopupVisible, setPaymentPopupVisible] = useState(false);

    const updatePrice = useCallback(async () => {
        try {
            const newPrice = await fetchPrice(selectedCurrency);
            if (newPrice) {
                setPrice(newPrice);
                setErrorMessage('');
            } else {
                setErrorMessage(`Conversion rate for USD to ${selectedCurrency} is not available.`);
                setPrice(1);
            }
        } catch {
            setErrorMessage('Failed to fetch the price. Please try again later.');
            setPrice(1);
        }
    }, [selectedCurrency]);

    useEffect(() => {
        updatePrice();
    }, [selectedCurrency, updatePrice]);

    const handleCurrencyChange = (event) => {
        let newCurrency;
        if (event.target) {
            newCurrency = event.target.value;
        } else if (event.value) {
            newCurrency = event.value;
        }
        setSelectedCurrency(newCurrency);
        const currency = CurrencyList.find(currency => currency.code === newCurrency);
        setCurrencySymbol(currency ? currency.symbol : newCurrency);
    };

    const handlePayment = async (amount) => {
        try {
            const orderUrl = "https://ex.d5art.com/api/orders";
            const { data } = await axios.post(orderUrl, { amount: amount * price, currency: selectedCurrency });

            console.log("Order data:", data);

            if (data.data) {
                initPayment(data.data, amount);
            }
        } catch (error) {
            console.error("Error during payment initiation:", error);
            toast.error("Something went wrong during payment initiation. Please try again.");
        }
    };

    const initPayment = (data, amount) => {
        console.log("Payment initialization data:", data);
        const options = {
            key: razorpayKey,
            amount: data.amount,
            currency: data.currency,
            name: email,
            description: "LIVE Transaction",
            image: "/assets/d5art_white.png",
            order_id: data.id,
            handler: async (response) => {
                try {
                    console.log("Payment response:", response);

                    const verifyUrl = "https://ex.d5art.com/api/recruiter_verify";
                    const { data: verifyData } = await axios.post(verifyUrl, {
                        ...response,
                        amount: amount,
                        wallet_address,
                        data,
                        email
                    });

                    console.log("Verification response:", verifyData);

                    if (verifyData.status === "success") {
                        setTimeout(() => {
                            toast.success(verifyData.message);
                        }, 3000);
                        setOpenPayment(false);
                        setOpenBuy2(true);
                    } else {
                        toast.error("Something went wrong during payment verification. Please try again.");
                    }
                } catch (error) {
                    console.error("Error during payment handling:", error);
                    toast.error("Something went wrong during payment handling. Please try again.");
                }
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };








    const togglePaymentPopup = () => {
        setPaymentPopupVisible(prev => !prev);
    };

    const plans = [
        { name: 'Genesis', amount: 100, price: price * 100, imgAlt: 'Genesis Plan', textColor: '#CD7F32' },
        { name: 'Exodus', amount: 500, price: price * 500, imgAlt: 'Exodus Plan', textColor: '#C0C0C0' },
        { name: 'Zenith', amount: 1000, price: price * 1000, imgAlt: 'Zenith Plan', textColor: '#FFD700' },
    ];









    ////------------------------------------------

    const handlePaymentCompletedButton = () => {

        // handleAnchorClick('div5');

        setTimeout(() => {
            window.location = '/RMS/Recruiter/RecruiterDashboard?tab=wallet';
        }, 3000);
    };





    const [displayMessages, setDisplayMessages] = useState([]);
    const [typedMessage, setTypedMessage] = useState('');
    // chat window 

    console.log("000000000000000", selectedCartId.id);


    // const chat_id = selectedCartId.id;
    const chat_id = selectedCartId.id;
    console.log("000000000000000", chat_id);

    // const recruiter_email = email;
    // const recruiterOrgName = selectedRecruiterOrganizationName.organizationName;
    // const recruiterURL = selectedRecruiterWebsiteURL.websiteURL;
    // const seeker_name = selectedSeekerName.seeker_name;
    // const seeker_email = selectedSeekerEmail.seeker_email;


    const sendMessage = async () => {
        //const recruiterEmail = email;
        try {
            if (!typedMessage.trim()) {
                console.log('Empty Message cannot be sent...Please type your message');
                return false;
            }

            //   // Determine the sender based on whether the user is a recruiter
            // const sender = recruiterEmail === sender_email ? 'recruiter' : 'seeker';

            const data = {
                chat_id: chat_id,
                message_content: typedMessage,
                sender: 'recruiter',

            }
            console.log(data)
            await axios.post(`https://ex.d5art.com/api/sendMessage`, data).then((resp) => {
                if (resp.data.status == 'success') {
                    setTypedMessage('');
                } else {
                    console.log('something went wrong');
                    return false
                }
            })

        } catch (error) {
            console.error('Validation error:', error);
        }
    };





    useEffect(() => {

        if (chat_id) {

            const getChatMessages = async () => {
                try {
                    const response = await fetch(`https://ex.d5art.com/api/getChatMessages/${chat_id}`, {
                        method: 'GET'
                    });

                    if (response.ok) {
                        const data = await response.json();
                        if (data.status === 'success') {
                            if (Array.isArray(data.data)) {

                                setDisplayMessages(data.data);
                            } else {

                                setDisplayMessages([]);
                            }
                        } else {

                            console.log('Failed to fetch chat messages:', data.message);
                            setDisplayMessages([]);
                        }
                    } else {

                        console.error('Failed to fetch chat messages:', response.status);
                        setDisplayMessages([]);
                    }
                } catch (err) {

                    console.error('Error fetching chat messages:', err);
                    setDisplayMessages([]);
                }
            };


            getChatMessages();

            // Fetch messages periodically
            const intervalId = setInterval(getChatMessages, 5000);

            // Cleanup function to clear interval
            return () => clearInterval(intervalId);
        }
    }, [chat_id]);


    const convertDate = (sent_at) => {
        const createdAt = new Date(sent_at);
        const currentDate = new Date();
        const timeDifference = currentDate - createdAt;

        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const years = Math.floor(days / 365);

        if (seconds < 60) {
            return seconds + ' seconds ago';
        } else if (minutes < 60) {
            return minutes + ' minutes ago';
        } else if (hours < 24) {
            return hours + ' hours ago';
        } else if (days < 365) {
            return days + ' days ago';
        } else {
            return years + ' years ago';
        }

    }










    const [selectedSeekerName1, setSelectedSeekerName1] = useState(null);
    const [offerStatus, setOfferStatus] = useState(0);

    const handleSeekerNameClick = (name) => {
        setSelectedSeekerName1(name);
    };



    const Offers = async (num) => {
        const value = num;
        try {
            const response = await fetch(`https://ex.d5art.com/api/recruiterOfferResponse?email=${email}`);
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();

            const res = data.data;
            console.log("offerdetails", res)
            if (value === 0) {
                setOfferStatus(0);
                setRecruiterOfferDetails(res);
            }
            else if (value === 1) {
                setOfferStatus(1);
                const final = res.filter(trans => trans.offer_status === "pending");
                setRecruiterOfferDetails(final);
            }
            else if (value === 2) {
                setOfferStatus(2);
                const final = res.filter(trans => trans.offer_status === "accepted");
                setRecruiterOfferDetails(final);
            } else {
                console.error('Invalid value for offers:', value);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }



    // div 4

    const getLastMessageTime = () => {
        if (displayMessages.length === 0) {
            return 'nill';
        }

        const lastMessage = displayMessages[displayMessages.length - 1];

        const lastMessageTime = convertDate(lastMessage.sent_at);
        return lastMessageTime;
    };




    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: '#ccc', // Color of the placeholder text
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#090909', // Background color of the dropdown menu
            border: '1px solid gray',
            borderRadius: '10px',
        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: '#090909', // Background color of the options list
            borderRadius: '10px',
            height: '200px',
        }),
    };


    ////changing price




    // const [selectedCurrency, setSelectedCurrency] = useState("USD");
    // const [currencySymbol, setCurrencySymbol] = useState("$");
    // const [price, setPrice] = useState(1); // Default to 1 for USD
    // const [errorMessage, setErrorMessage] = useState("");
    // const [totalAmount, setTotalAmount] = useState(null);
    // const [paymentStoreData, setPaymentStoreData] = useState({
    //   email,
    //   courseName: "",

    //   PaidMoney: "",
    //   TransactionID: "",
    //   PaymentStatus: "success",
    //   orderID: "",
    // });

    // const apiKey = "0b6f16b4d0563ae6f87117706bb9c15256911c5925dfa5c4a92523e269ac5a0f";

    // useEffect(() => {
    //   const fetchPrice = async () => {
    //     if (selectedCurrency && selectedCurrency !== "USD") {
    //       try {
    //         const response = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=${selectedCurrency}&api_key=${apiKey}`);
    //         if (response.data && response.data[selectedCurrency]) {
    //           const fetchedPrice = response.data[selectedCurrency];
    //           setPrice(fetchedPrice);
    //           setErrorMessage("");
    //         } else {
    //           setErrorMessage(`Conversion rate for USD to ${selectedCurrency} is not available.`);
    //           setPrice(1); // Reset to 1 if there's an error
    //         }
    //       } catch (error) {
    //         setErrorMessage("Failed to fetch the price. Please try again later.");
    //         setPrice(1); // Reset to 1 if there's an error
    //       }
    //     } else {
    //       setPrice(1); // USD default conversion rate
    //     }
    //   };

    //   fetchPrice();
    // }, [selectedCurrency, apiKey]);

    // const handleCurrencyChange = (event) => {
    //   const newCurrency = event.target.value;
    //   setSelectedCurrency(newCurrency);

    //   const currency = CurrencyList.find(currency => currency.code === newCurrency);
    //   setCurrencySymbol(currency ? currency.symbol : newCurrency);
    // };



    // const genesisPrice = Math.round(price * 100); 
    // const exodusPrice = Math.round(price * 500); 
    // const zenithPrice = Math.round(price * 1000); 



    // Remove nft cv


    // const RemoveNft = async (seeker) => {
    //     try {

    //         const seekerEmail = seeker.seeker_email;
    //         const response = await axios.get(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${seekerEmail}`);
    //         console.log("response", response);
    //         const responseData = response.data;

    //         if (responseData && responseData.data && responseData.data.length > 0) {

    //             const data = responseData.data[0];

    //             const seeker_address = data.walletAddress;
    //             console.log("dattaaaa", data.walletAddress);


    //             const removeResponse = await axios.post('https://ex.d5art.com/api/removeNft', {
    //                 seekerAddress: seeker_address,
    //                 tokenId: seeker.seeker_token_id,
    //                 recruiterWalletAddress: recruiterWalletAddress,
    //                 seeker_email: seekerEmail,
    //                 recruiter_email: email

    //             });
    //             console.log('NFT removed successfully:', removeResponse.data);
    //         } else {
    //             console.error('Invalid response structure:', data);
    //         }
    //     } catch (error) {
    //         console.error('Error in removing NFT:', error);
    //     }
    // };

    const RemoveNft = async (seeker) => {

        const loadingToast = toast.loading('Please wait while we remove the NFT...');

        try {
            const seekerEmail = seeker.seeker_email;
            const response = await axios.get(`https://ex.d5art.com/api/fetch_details_for_seeker?email=${seekerEmail}`);
            console.log("response", response);
            const responseData = response.data;

            if (responseData && responseData.data && responseData.data.length > 0) {
                const data = responseData.data[0];
                const seeker_address = data.walletAddress;
                console.log("seeker_address", seeker_address);

                const removeResponse = await axios.post('https://ex.d5art.com/api/removeNft', {
                    seekerAddress: seeker_address,
                    tokenId: seeker.seeker_token_id,
                    recruiterWalletAddress: recruiterWalletAddress,
                    seeker_email: seekerEmail,
                    recruiter_email: email
                });

                console.log('NFT removed successfully:', removeResponse.data);


                toast.success('NFT removed successfully!');

                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            } else {
                console.error('Invalid response structure:', responseData);

                toast.error('Failed to remove NFT: Invalid response structure.');
            }
        } catch (error) {
            console.error('Error in removing NFT:', error);

            toast.error('Error in removing NFT. Please try again.');
        } finally {

            toast.dismiss(loadingToast);
        }
    };

    const customStylesState = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #0a3840',
            boxShadow: '0 0 2px #0a3840, inset 0 0 2px #0a3840, 0 0 5px #0a3840, 0 0 5px #0a3840, 0 0 2px #0a3840',
            backgroundColor: '#090909',
            borderRadius: '4px',
            color: 'white'
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#08f' : '#090909',
            color: state.isSelected ? '#fff' : '#fff', // Color of the option text
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the selected option text
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#000', // Background color of the dropdown menu

        }),
        menuList: (provided, state) => ({
            ...provided,
            backgroundColor: '#000', // Background color of the options list
        }),
    };


    // redirect of seeker profile in mycart 

    const handleNavLinkClick = (seeker_token_id) => {
        window.location.href = `/RMS/Recruiter/userdetails?tokenId=${seeker_token_id}`;
    };

    //  fetch details from the recruiter transactions table

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ex.d5art.com/api/fetch_recruiter_transactions?recruiter_email=${email}`);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

                const data = await response.json();
                console.log('fetch_seeker_transactions', data.data);

                if (response.ok) {
                    const transactions = data.data;

                    // Sort transactions by created_at in descending order
                    transactions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));


                    const creditedTransactions = data.data.filter(item => item.status === 'Credited');
                    const debitedTransactions = data.data.filter(item => item.status === 'Debited');

                    // Sum the amounts for both credited and debited transactions
                    const totalCredited = creditedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount || 0), 0);
                    const totalDebited = debitedTransactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount || 0), 0);

                    // -------  Calculate balance  -------
                    const balance = totalCredited - totalDebited;


                    const currentTransactions = transactions.slice(startIndex, startIndex + transactionsPerPage);

                    const monthlyCredited = {};
                    const monthlyDebited = {};

                    const today = new Date();
                    const sixMonthsAgo = new Date();
                    sixMonthsAgo.setMonth(today.getMonth() - 6);

                    transactions.forEach(item => {
                        const transactionDate = new Date(item.created_at);
                        if (transactionDate >= sixMonthsAgo) {
                            const monthYear = transactionDate.toLocaleString('default', { year: 'numeric', month: 'short' }); // Get short month name
                            const value = parseFloat(item.amount) || 0;

                            if (item.status === "Credited") {
                                monthlyCredited[monthYear] = (monthlyCredited[monthYear] || 0) + value;
                            }

                            if (item.status === "Debited") {
                                monthlyDebited[monthYear] = (monthlyDebited[monthYear] || 0) + value;
                            }
                        }
                    });

                    // Create arrays for the last six months
                    const months = [];
                    const creditData = [];
                    const debitData = [];

                    // last six months for display
                    for (let i = 0; i < 6; i++) {
                        const monthDate = new Date();
                        monthDate.setMonth(today.getMonth() - i);
                        const monthYear = monthDate.toLocaleString('default', { year: 'numeric', month: 'short' });
                        months.unshift(monthYear); // Add to the beginning of the array for correct order
                        creditData.unshift(monthlyCredited[monthYear] || 0);
                        debitData.unshift(monthlyDebited[monthYear] || 0);
                    }


                    setBalance(balance);
                    setDisplayedTransactions(currentTransactions);
                    setCreditData(creditData);
                    setDebitData(debitData);
                    setMonths(months);
                    setDataLoaded(true);

                    console.log(`Monthly Credited:`, monthlyCredited);
                    console.log(`Monthly Debited:`, monthlyDebited);
                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email, startIndex]);


    console.log("displayedTransactions", displayedTransactions.length)



    const formatTimestamp = (timestamp) => {
        return new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).format(new Date(timestamp));
    };


    // open signed offer letter

    const [isOpenSignedOfferLetter, setisOpenSignedOfferLetter] = useState(false);


    const handleOpenSignedOfferLetter = () => {
        setisOpenSignedOfferLetter(!isOpenSignedOfferLetter)
    }

    //  view signed offer letter 

    const handleViewSignedOffer = async () => {
        const seekerEmail = seekerOfferEmail;
        const recruiterEmail = email;

        try {
            const response = await fetch(`https://ex.d5art.com/api/signedSeekerOfferLetterDownload?seeker_email=${seekerEmail}&recuiter_email=${recruiterEmail}`, {
                method: 'GET',
            });
            //   console.log("response offer", response)
            if (response.ok) {
                const blob = await response.blob();

                const url = window.URL.createObjectURL(blob);
                //   console.log("url", url);

                // Create a temporary link element
                const a = document.createElement('a');
                a.href = url;
                a.download = 'SignedOffer.pdf';
                a.click();
            } else {
                console.error('Error downloading PDF:', response.status);
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    return (
        <div className=' bg-[#191919] font-poppins ' style={{ backgroundImage: 'url("/assets/FABC.png")' }}>
            <LoginHeader />
            <Toaster />
            <div className='p-5 lg:flex pb-20 '>
                <div class="hidden md:block w-[25%] ">
                    <nav className='h-[39.5rem] 2xl:h-[40rem] bg-black border border-sky-700 rounded-3xl text-white grid content-around'>
                        <div>
                            <div className='flex py-5'>
                                <div className='w-[35%] flex justify-end'><div>
                                    <img className='w-[4rem]' src={recruiterAvatar ? (recruiterAvatar.startsWith('./') ? recruiterAvatar.slice(1) : recruiterAvatar) : ''} />
                                    {/* <img className='w-[4rem]' src='/assets/avatar.png'></img> */}
                                </div>
                                </div>
                                <div className='w-[65%] pl-2 pt-3'>
                                    <h1 className='text-lg font-bold'>{recruiterName}</h1>
                                    <p className='text-xs text-gray-400'> {recruiterOrganizationName}</p>

                                </div>
                            </div>
                            <div>
                                <TabButton label='My Profile' tab='nft' />
                                <TabButton label='Offers' tab='offers' />
                                <TabButton label='My Cart' tab='user' />
                                <TabButton label='Chat window' tab='chat' />
                                <TabButton label='Wallet' tab='wallet' />
                                <TabButton label='Tickets / Queries' tab='Ticket' />

                                <div />

                            </div>
                        </div>
                        <div className=''>
                            {/* <TabButton
                                label='Sign out'
                                tab='Signout'
                                onClick={() => {
                                    handleTabClick('Signout');
                                   
                                }}
                            /> */}
                        </div>
                    </nav>
                </div>
                <div className='block md:hidden'>
                    <button onClick={handleDropdown} ref={buttonRef}>
                        <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h14M1 6h14M1 11h7" />
                        </svg>
                    </button>
                    {isDropdown && (
                        <nav className='w-[80%] z-10 absolute border border-sky-700 rounded-3xl bg-black text-white grid content-around animate__animated animate__fadeInLeft' ref={dropdownRef}>
                            <div>
                                <div className='flex py-5'>
                                    <div className='w-[35%] flex justify-end'><div><img className='w-[4rem]' src={recruiterAvatar ? (recruiterAvatar.startsWith('./') ? recruiterAvatar.slice(1) : recruiterAvatar) : ''}></img></div>
                                    </div>
                                    <div className='w-[65%] pl-2 pt-3'>
                                        <h1 className='text-lg font-bold'>{recruiterName}</h1>
                                        <p className='text-xs text-gray-400'>  {recruiterOrganizationName}</p>

                                    </div>
                                </div>
                                <div className='py-5'>
                                    <TabButton label='My Profile' tab='nft' />
                                    <TabButton label='Offers' tab='offers' />
                                    <TabButton label='My Cart' tab='user' />
                                    <TabButton label='Chat window' tab='chat' />
                                    <TabButton label='Wallet' tab='wallet' />
                                    <TabButton label='Tickets / Queries' tab='Ticket' />

                                    <div />

                                </div>
                            </div>
                            <div className=''>
                                {/* <TabButton
                                    label='Sign out'
                                    tab='Signout'
                                    onClick={() => {
                                        handleTabClick('Signout');
                                        
                                    }}
                                /> */}
                            </div>
                        </nav>
                    )}
                </div>
                <div id='div1' style={{ display: active === 'div1' ? 'block' : 'none' }} className='md:absolute h-screen md:w-[72%] text-white right-0 top-32 scrollbar '>
                    <div className='flex justify-center mt-3 md:mt-28'>
                        <div className='w-[90%] md:w-[65%] h-[25rem] rounded-2xl  overflow-hidden group bg-center ' style={{ backgroundImage: `url('/assets/proofile card 2.png')` }}>
                            <div className='flex justify-center pt-5'>
                                {/* <img src='/assets/image 3yr.png' className='w-[22.5rem] absolute'></img>       */}
                                <img className='w-[21rem] absolute' src={recruiterAvatar ? (recruiterAvatar.startsWith('./') ? recruiterAvatar.slice(1) : recruiterAvatar) : ''} />                      </div>
                            <div className='flex justify-center'>
                                <div className='w-[80%] h-[18rem] bg-white/40 backdrop-blur-md p-3 md:p-4 md:px-7 rounded-2xl translate-y-[19.5rem] group-hover:translate-y-10 transform-transition duration-700 ease-in-out'>
                                    <div className='flex justify-between '>
                                        <p><p className='font-black'>{recruiterName} </p>
                                            <p className='text-[10px]'>{recruiterEmail}</p>
                                        </p>
                                        <div>
                                            <button className='px-2  py-1 rounded-lg bg-white'>
                                                <i className='fa-solid fa-paintbrush text-black'></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='flex gap-3 md:gap-0 py-5 text-xs md:text-sm'>
                                        <div className='w-[50%] flex flex-col gap-5 md:pr-5  '>
                                            <div className='flex border-b '>
                                                <i class="fa-solid fa-pen-clip mt-1"></i>
                                                <input
                                                    type='text'
                                                    className='w-[95%] pb-1 bg-transparent outline-none pl-3 text-white  placeholder-white'
                                                    // placeholder={`${recruiterName.slice(0,10) || ''}    (Full Name)`}
                                                    placeholder={`${recruiterName || 'Full Name'}   `}
                                                    value={fullName}
                                                    onChange={(e) => setFullName(e.target.value)}
                                                ></input>
                                            </div>
                                            <div className='flex border-b'>
                                                <i class="fa-solid fa-building-user mt-1"></i>
                                                <input
                                                    type='text'
                                                    className='w-[95%] pb-1 bg-transparent outline-none pl-3 text-white  placeholder-white'
                                                    placeholder={`${recruiterOrganizationName || 'Organization'} `}
                                                    value={organization}
                                                    onChange={(e) => setOrganization(e.target.value)}
                                                ></input>
                                            </div>
                                            {/*     <div className='flex border-b'>
                                                <i class="fa-solid fa-envelope mt-1"></i>
                                                <input
                                                    type='text'
                                                    className='w-[95%] pb-1 bg-transparent outline-none pl-3 text-white  placeholder-white'
                                                    placeholder='Mail ID'
                                                    value={mailId}
                                                    onChange={(e) => setMailId(e.target.value)}
                                                ></input>
                    </div> */}
                                            <div className='flex border-b'>
                                                <i class="fa-solid fa-people-group mt-1"></i>
                                                <input
                                                    type='text'
                                                    className='w-[95%] pb-1 bg-transparent outline-none pl-3 text-white  placeholder-white'
                                                    placeholder={recruiterDesignation || 'Role in Hiring'}
                                                    value={roleInHiring}
                                                    onChange={(e) => setRoleInHiring(e.target.value)}></input>
                                            </div>

                                        </div>
                                        <div className='w-[50%]  md:pl-5 flex flex-col gap-5 '>
                                            <select class="md:py-0.5 w-full text-white  bg-transparent border-b outline-none overflow-hidden overflow-y-scroll"
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                            >
                                                <option className='bg-black'>{recruiterCountry || 'Location'}</option>
                                                {countries.map((country, index) => (
                                                    <option className='bg-black' key={`${country.country_code}-${country.label}-${index}`} value={country.label}>
                                                        {country.label} {country.country_flag}
                                                    </option>
                                                ))}

                                            </select>
                                            <select
                                                className="py-0.5 w-full text-white bg-transparent border-b outline-none"
                                                value={destination}
                                                onChange={(e) => setDestination(e.target.value)}
                                            >
                                                <option className='bg-black'>{recruiterState || 'States'}</option>
                                                {filteredStateOptions.map((state, index) => (
                                                    <option className='bg-black' key={`${state.state_code}-${state.name}-${index}`} value={state.name}>
                                                        {state.name}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className='flex border-b'>
                                                <i class="fa-solid fa-phone mt-1"></i>
                                                <input
                                                    type='text'
                                                    className='w-[95%] pb-1 bg-transparent outline-none pl-3 text-white  placeholder-white'
                                                    placeholder={recruiterMobileNumber || 'Mobile Number'}
                                                    value={mobileNumber}
                                                    onChange={(e) => setMobileNumber(e.target.value)}
                                                ></input>
                                            </div>
                                            {/* <button  className='text-center'>
                                                <i className='fa-solid fa-wallet pr-3'></i>Connect wallet
                                            </button> */}

                                        </div>
                                    </div>
                                    <div className='flex flex-col-reverse md:flex-row gap-3 justify-between'>
                                        <div className='flex gap-3 justify-center'>
                                            <div className='flex items-center w-0 md:w-[5%]'><i className='fa-solid fa-wallet hidden md:block'></i>
                                            </div>
                                            <div className='' >
                                                <w3m-button balance="hide" />
                                            </div>
                                        </div>
                                        <div className='m-auto'>
                                            <button className='bg-[#4E67E7] text-white py-2 px-4 rounded-full hover:bg-blue-700' onClick={handleUpdateClick}>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id='div2' style={{ display: active === 'div2' ? 'block' : 'none' }} className='md:absolute text-white h-screen  md:w-[72%] right-0 top-32 '>
                    <div className='mt-3'>
                        <h1 className='text-2xl font-bold'>My Offers:</h1>
                        <div className='mt-5 md:m-5 md:mr-16 border-2 border-gray-800 rounded-3xl p-5 bg-[#101010] backdrop-blur-md h-[36rem] scrollbar  overflow-hidden overflow-y-scroll'>
                            <h2 className='font-bold text-lg'>Filter <i class="fa-solid fa-filter text-sm text-gray-400 ml-2"></i></h2>
                            <div className='flex gap-5 mt-5'>
                                <button className={`text-sm text-white rounded-full bg-[#00768B] ${offerStatus === 0 ? 'bg-[#00768B]' : 'bg-opacity-50'} px-2 md:w-[12.5%] py-1`} onClick={() => Offers(0)}>All</button>
                                <button className={`text-sm text-white rounded-full ${offerStatus === 1 ? 'bg-[#00768B]' : 'border border-[#00768B]'} px-2 md:w-[12.5%] py-1`} onClick={() => Offers(1)}>Pending</button>
                                <button className={`text-sm text-white rounded-full ${offerStatus === 2 ? 'bg-[#00768B]' : 'border border-[#00768B]'} px-2 md:w-[12.5%] py-1`} onClick={() => Offers(2)}>Accepted</button>
                            </div>
                            <div className='w-[100%] mt-5 '>
                                <div className='w-full flex text-center'>
                                    <div className='w-[40%]'><p className='text-center'>Seeker</p></div>
                                    <div className='w-[30%]'><p className='text-center'>Offers sent on</p></div>
                                    <div className='w-[15%]'><p className='text-center'>Status</p></div>
                                    <div className='w-[15%]'><p className=''>Offer</p></div>
                                </div>

                                {recruiterOfferDetails.map((seeker, index) => (
                                    <div key={index} className='rounded-2xl p-2 shadow-sm shadow-sky-300 flex mt-3 border border-sky-900 '>
                                        <div className='w-[40%]'>
                                            <div className='flex '>
                                                <div className='w-[20%] flex justify-center'>
                                                    <div>
                                                        {/* <img src='/assets/image2.png' className='p-2'></img> */}
                                                        <img src={seeker.SeekerAvatar ? (seeker.SeekerAvatar.startsWith('./') ? seeker.SeekerAvatar.slice(1) : seeker.SeekerAvatar) : ''} className='p-2' />
                                                    </div>

                                                </div>
                                                <div className='w-[80%] pl-2 pt-2'>
                                                    <h1 className='text-lg'>{seeker.seeker_name.slice(0, 7)}</h1>
                                                    <p className='text-gray-400'>{seeker.seeker_contract_type}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-[30%] flex items-center justify-center'>
                                            <p>
                                                {new Date(seeker.created_at).toLocaleString('en-US', {
                                                    timeZone: 'Asia/Kolkata', // IST time zone
                                                })}

                                            </p>
                                        </div>

                                        <div className='text-[#FFBA00] w-[15%] flex items-center justify-center'><p>{seeker.offer_status}</p></div>
                                        <div className='md:w-[15%] flex items-center justify-center'>
                                            <button
                                                className={`text-xs text-white rounded-full bg-[#00768B] px-3 py-1 ${seeker.signed_offerLetter === null ? 'cursor-not-allowed opacity-50' : ''}`}
                                                onClick={seeker.signed_offerLetter === null ? null : handleOpenSignedOfferLetter} // Disable click when offer letter is null
                                                disabled={seeker.signed_offerLetter === null} // Disable button when offer letter is null
                                            >
                                                View Offer
                                            </button>
                                        </div>

                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>

                {
                    isOpenSignedOfferLetter && (
                        <div className="z-10 w-full lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-50 lg:backdrop-blur-sm ">
                            <div className='lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handleOpenSignedOfferLetter}></i></div>
                                <div className='flex h-[17rem] 2xl:[20rem]'>
                                    <div className='w-[40%]'>
                                        <div >
                                            <img src='/assets/avatar.png' className='absolute h-[22.5rem] -translate-y-[90px] -translate-x-16 2xl:-translate-x-0'></img>
                                        </div>
                                    </div>
                                    <div className='w-[60%]  pr-7 text-white grid content-around'>
                                        <p className='flex justify-between text-lg'><p>Congratulations! 🎉 </p><span className='text-[#00F4FC]'>{ }</span></p>
                                        <p className='uppercase text-sm'>
                                            Your offer has been officially accepted by the seeker!<br /><br />
                                            Thank you for extending the opportunity to join your team. <br />

                                        </p>

                                        <div className='flex justify-between'>


                                            <div>
                                                <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg mr-5'>
                                                    <button className="bg-black px-4 text-sm py-1 rounded-lg" onClick={handleViewSignedOffer} >Download Offer</button>
                                                </button>




                                                <Confetti active={Btn} config={confettiConfig} />


                                            </div>


                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    )
                }



                <div id='div3' style={{ display: active === 'div3' ? 'block' : 'none' }} className='md:absolute  text-white h-screen   md:w-[72%] right-0 top-32 scrollbar '>
                    <div className='mt-3 '>
                        <h1 className='text-2xl font-bold'>My Cart :</h1>
                        <div className='mt-5 md:m-5 md:mr-16  overflow-hidden overflow-y-scroll border-2 border-gray-800 rounded-3xl p-5 bg-[#101010] backdrop-blur-md h-[36rem] '>
                            <div className='w-[100%] mt-5 '>
                                <div className='w-full flex text-sm'>
                                    <div className='w-[40%] '><p className='text-center'>Seeker</p></div>
                                    <div className='w-[15%] '><p className='text-center'>Tokens</p></div>
                                    <div className='w-[15%] '><p className='text-center'>View Profile</p></div>
                                    <div className='w-[15%] '><p className='text-center'>Chat</p></div>
                                    <div className='w-[15%] '><p className='text-center'>Remove profile</p></div>
                                </div>
                                {seekerDetails.map((seeker, index) => (
                                    <div key={index} className='rounded-2xl p-2 shadow-sm shadow-sky-300 flex flex-col md:flex-row gap-3 md:gap-0 mt-3 border border-sky-900'>
                                        <div className='md:w-[40%] '>
                                            <div className='flex '>
                                                <div className='w-[20%] flex justify-center'>
                                                    <div><img src={seeker.SeekerAvatar ? (seeker.SeekerAvatar.startsWith('./') ? seeker.SeekerAvatar.slice(1) : seeker.SeekerAvatar) : ''} /></div>
                                                </div>
                                                <div className='w-[80%] pl-2 pt-2'>
                                                    <h1 className='text-lg'>{seeker.seeker_name.slice(0, 7)}</h1>
                                                    <p className='text-gray-400 text-sm'>{seeker.contractType}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:w-[15%] flex items-center justify-center'><p>{seeker.tokens} D5ART Tokens</p></div>
                                        <div className='md:w-[15%] flex items-center justify-center'>

                                            <a
                                                href={`/RMS/Recruiter/userdetails?tokenId=${seeker.seeker_token_id}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleNavLinkClick(seeker.seeker_token_id);
                                                }}
                                                className='w-full md:w-auto flex justify-center '
                                            >
                                                <button className='bg-[#00768B] w-[50%] md:w-auto px-7 text-sm py-1 rounded-xl'>Profile</button>
                                            </a>

                                        </div>
                                        <div className=' md:w-[15%] flex items-center justify-center '>
                                            <a onClick={() => handleChatButtonClick(seeker)} className='w-full md:w-auto flex justify-center '>
                                                <button className='bg-[#00768B] w-[50%] md:w-auto px-7 text-sm py-1 rounded-xl'>Chat</button>
                                            </a>
                                        </div>

                                        <div className='md:w-[15%] flex items-center justify-center '>
                                            <button onClick={handleRemoveButtonClick} className='bg-[#FF0000]/50  w-[50%] md:w-auto rounded-xl py-1 text-sm px-3'>Remove <i className="fa-solid fa-circle-xmark  text-[10px]"></i></button>
                                        </div>
                                        {isRemovepopup && (
                                            <div className="z-10 w-full lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-50 lg:backdrop-blur-sm ">
                                                <div className='lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                                                    <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handleRemoveButtonClick}></i></div>
                                                    <div className='w-[100%]  px-5 pb-5 text-white grid content-around'>
                                                        <h1 className='text-[#13BDB3] flex'>NFT ID:<p className='pl-2 text-white'>{seeker.seeker_name.slice(0, 10)}</p>
                                                        </h1>
                                                        <p className=' text-sm text-center mt-3'>Here by you confirm to release the NFT cv.
                                                        </p>
                                                        <div className='flex justify-center mt-3'>
                                                            <button className="bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px]  rounded-lg">
                                                                <button className="bg-black px-4 text-sm py-1 rounded-lg" onClick={() => RemoveNft(seeker)} >Confirm</button>
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>


                <div id='div4' style={{ display: active === 'div4' ? 'block' : 'none' }} className='md:absolute  text-white md:w-[72%] right-0 top-32 scrollbar '>
                    <div className='mt-3 h-[39rem]'>
                        <div className='flex gap-5 px-5 overflow-x-scroll overflow-hidden  h-[8rem]'>
                            {seekerDetails.map((seeker, index) => (
                                <button className='flex-shrink-0 ' key={index} onClick={() => handleChatButtonClick(seeker)}>
                                    <div className='border-[4px] border-[rgb(14,164,169)] rounded-full p-1'>
                                        {/* <img src='/assets/image2.png' className='w-[4.5rem]' alt={`Seeker ${index}`} /> */}
                                        <img src={seeker.SeekerAvatar ? (seeker.SeekerAvatar.startsWith('./') ? seeker.SeekerAvatar.slice(1) : seeker.SeekerAvatar) : ''} className='w-[4.5rem]' alt={`Seeker ${index}`} />
                                    </div>
                                    <p className='text-center text-sm mt-2'>{(seeker.seeker_name).slice(0, 7)}</p>
                                </button>
                            ))}
                        </div>

                        <div className='mt-5 md:m-5 md:mt-2 md:mr-16 border-2 border-gray-800 rounded-3xl p-3 md:p-5  bg-[#101010] backdrop-blur-md h-[30.5rem] grid content-between'>
                            <div className='flex items-center border-b border-gray-700 pb-5 '>
                                <div className='w-[10%]'>
                                    <div className='px-[4px] '><div className='bg-white w-[14px] h-[14px] rounded-full absolute flex justify-center items-center'><div className='bg-green-600 w-3 h-3 rounded-full'></div></div><div>
                                        {/* <img src='/assets/image2.png' ></img> */}
                                        <img src={selectedSeekerName.SeekerAvatar ? (selectedSeekerName.SeekerAvatar.startsWith('./') ? selectedSeekerName.SeekerAvatar.slice(1) : selectedSeekerName.SeekerAvatar) : ''} />
                                    </div></div>
                                </div>
                                <div className='w-[60%] pl-5 '>
                                    <div>
                                        <h1 className='text-xl font-bold'>{(selectedSeekerName.seeker_name)?.slice(0, 7)}
                                        </h1>
                                        <p className='text-xs md:text-base md:flex'><p className='flex'> <div className='mx-2 w-2 h-2 rounded mt-2 bg-white'></div></p>
                                            <p> Last seen {getLastMessageTime()}</p></p>
                                    </div>
                                </div>
                                <div className='w-[30%]'>
                                    <button className='font-play px-2 md:px-5 py-2 border border-sky-800 bg-gradient-to-r from-[#0E2DA78A] to-[#0EA4A98A] rounded-xl ' onClick={handlePartypopup}><i class="fa-solid fa-envelope-open-text mr-2"></i>Offer Letter</button>
                                </div>
                            </div>


                            {/* <div className='h-[18rem] grid content-end'>
                                <div className='flex justify-start'>
                                    <div>
                                        <div className='px-5 py-2 bg-gray-200 text-black rounded-b-xl rounded-tr-xl'>Hi there, happy to see you here</div>
                                        <p className='text-[10px] text-gray-300 mt-1'>Yesterday ,7.10 pm </p>
                                    </div>
                                </div>
                                <div className='flex justify-end'>
                                    <div>
                                        <div className='px-5 py-2 bg-[#0EA4A9] rounded-t-xl rounded-bl-xl'>Hello</div>
                                        <p className='text-[10px] text-gray-300'>Today ,8.02 am <i class="fa-solid fa-check-double text-lg ml-2 text-white"></i></p>
                                    </div>
                                </div>
                                </div> */}


                            <div className='h-[18rem] flex grid  content-start overflow-hidden overflow-y-scroll scrollbar'>
                                {displayMessages.map(message => (
                                    <div className={`flex justify-${message.sender === 'recruiter' ? 'end' : 'start'}`} key={message.id}>
                                        <div> <div className={` px-5 my-2 py-2 rounded-tr-xl ${message.sender === 'recruiter' ? 'xl' : 'bl'} rounded-b-xl ${message.sender === 'recruiter' ? 'xl' : 'tr'} rounded-b-xl ${message.sender === 'recruiter' ? 'bg-[#0EA4A9] text-white ml-auto' : 'bg-gray-200 text-black'}`}>
                                            {message.message_content}
                                        </div>
                                            <p className={`text-[10px] text-gray-300 rounded-t-xl rounded-bl-xl ${message.sender === 'recruiter' ? 'ml-2' : 'mr-2'}`}>
                                                {convertDate(message.sent_at)} <i className="fa-solid fa-check-double text-lg"></i>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>




                            <div className='flex gap-2'>
                                <div className='w-[80%] lg:w-[90%] '>
                                    <div className='flex bg-white/20 px-3 md:px-5 rounded-xl'>
                                        <input
                                            type='text'
                                            value={typedMessage}
                                            onChange={(e) => setTypedMessage(e.target.value)}
                                            className='w-[100%] bg-transparent py-2 md:py-4 px-5 text-white outline-none'
                                            placeholder='Type your message here ...'
                                        />
                                        {/*<i class="fa-regular fa-face-smile-beam text-xl md:text-2xl mt-1 md:mt-3"></i>*/}
                                    </div>
                                </div>

                                <div className='w-[20%] lg:w-[10%] md:p-1'>
                                    <button className='bg-[#0EA4A9] w-full h-full text-xl md:text-2xl rounded-lg' onClick={sendMessage} ><i class="fa-solid fa-paper-plane"></i></button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                {isPartypopup && (
                    <div className="z-10 w-full fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm ">
                        <div className='w-[95%] lg:w-[50%]  bg-[#101010] rounded-3xl shadow-md shadow-sky-700 border border-sky-700 mt-5 lg:mt-0 animate__animated animate__fadeInUp' >
                            <div className='flex justify-end'><i className='cursor-pointer fa-solid fa-close text-lg text-white px-3 py-1' onClick={handlePartypopup}></i></div>
                            <div className='flex flex-col lg:flex-row gap-5 lg:gap-0 lg:h-[17rem] 2xl:[20rem] px-3'>
                                <div className='w-[100%] lg:w-[40%]'>
                                    <div >
                                        <img src='/assets/avatar.png' className='w-[50%] lg:w-auto mx-auto lg:absolute lg:h-[22.5rem] lg:-translate-y-[90px] lg:-translate-x-16 2xl:-translate-x-0'></img>
                                    </div>
                                </div>
                                <div className='w-[100%] lg:w-[60%] pb-3 md:pr-7 text-white grid content-around gap-2 '>
                                    <p className='flex justify-between text-lg'><p>Send Offer Letter: </p><span className='text-[#00F4FC]'>{(selectedSeekerName.seeker_name)?.slice(0, 7)}</span></p>
                                    <p className='uppercase text-sm'>We are excited to confirm that we have sent the offer letter to the candidate. Looking forward to their positive response!
                                    </p>
                                    <div className='flex justify-between '>

                                        <div className='flex flex-col gap-2'>
                                            <button className='bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px] rounded-lg' onClick={async () => {

                                                // await updateInterestCount(email);
                                                handleCongrats();
                                            }} >
                                                <button className='bg-black px-4 text-sm py-1 rounded-lg' >
                                                    <input type="file" accept=".pdf" onChange={handleFileChange} />
                                                </button></button>
                                            <div className='flex justify-center'>
                                                <button
                                                    className="bg-gradient-to-r from-[#13BDB3] to-[#0E14A9] p-[2px]  rounded-lg"
                                                    onClick={handleUpload}
                                                >
                                                    <button className="bg-black px-4 text-sm py-1 rounded-lg">Upload</button>
                                                </button>
                                            </div>


                                        </div>


                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                )}

                <div id='div5' style={{ display: active === 'div5' ? 'block' : 'none' }} className='md:absolute  text-white  md:w-[72%] right-0 top-32 scrollbar '>
                    <div className='mt-3 '>
                        <h1 className='text-2xl font-bold'>Wallet:</h1>
                        <div className='mt-5 md:m-5 md:mr-16 border-2 border-gray-800 rounded-3xl py-5 pb-10 md:pb-0 px-2 md:p-5 bg-[#101010] backdrop-blur-md md:h-[36rem] '>
                            <div className='flex flex-col gap-5 md:gap-0 md:flex-row'>
                                <div className='md:w-[55%]'>
                                    <h1 className='font-bold text-xl p-3 text-gray-300'>Recent Activities</h1>
                                    <div>
                                        {displayedTransactions.map((transaction, index) => (
                                            <div key={index} className='flex-col'>
                                                <div className='flex py-5 px-3'>
                                                    <div className='w-[10%]'>
                                                        <div>
                                                            <img
                                                                src={transaction.status === 'Credited' ? '/assets/Green.png' : '/assets/Red.png'}
                                                                alt={transaction.status === 'Credited' ? 'Green' : 'Red'}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='w-[70%] px-5'>
                                                        <div>
                                                            <p className='font-bold text-lg'>
                                                                {transaction.status === 'Credited' ? 'Credited' : 'Debited'}
                                                            </p>
                                                            {transaction.created_at && (
                                                                <p className='text-xs text-gray-500'>{formatTimestamp(transaction.created_at)}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='w-[20%]'>
                                                        <p>{transaction.amount} <span>Tokens</span></p>
                                                    </div>
                                                </div>
                                                {index < displayedTransactions.length - 1 && <div className='w-full h-[1px] bg-gray-800'></div>}
                                            </div>
                                        ))}
                                    </div>



                                    <div className='w-full h-[1px] bg-gray-800'></div>
                                    <div className='py-3 flex justify-between px-5'>
                                        <button className='px-5 rounded-full border border-[#0E2DA7]' onClick={handlePrevClick}>Prev</button>
                                        <button className='px-5 rounded-full border border-[#0E2DA7]' onClick={handleNextClick}>Next</button>
                                    </div>
                                </div>
                                <div className='md:w-[45%] grid gap-10 md:gap-0 md:content-around'>
                                    <div className='p-[1px] rounded-2xl bg-gradient-to-l from-[#0E2DA7] to-[#0EA4A9] mx-7'>
                                        <div className='flex bg-black rounded-2xl p-5'>
                                            <div className='w-[60%] flex items-center '>
                                                <div className='pl-3 translate-y-5'>
                                                    <p className='text-xl font-bold'>{balance} Tokens</p>
                                                    <p>1 token = 1$</p>
                                                    <button className='bg-[#0893d1] px-3.5 py-2 rounded-full translate-y-6 md:translate-y-6' onClick={handlePaymentPopUp}>
                                                        <i className='fa-solid fa-plus text-xl'></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className='w-[40%] flex items-center'>
                                                <div><img src='/assets/d5art_white.png' alt='Logo' /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='text-black'>
                                            {dataLoaded && (
                                                <ApexChart
                                                    creditData={creditData}
                                                    debitData={debitData}
                                                    months={months}
                                                />
                                            )}
                                            {!dataLoaded && <p className='text-white' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {isOpenPayment && (
                        <div className='px-5 py-10 md:px-10 md:py-0 z-20 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center bg-black bg-opacity-10 backdrop-blur-md overflow-y-auto'>
                            {loading ? (
                                <div className="flex justify-center items-center w-full h-full">
                                    <div className="loader"></div>
                                </div>
                            ) : (
                                <div className='w-full flex justify-center gap-2'>
                                    <div className='w-[100%] md:w-[90%] h-full text-white border border-sky-700 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10'>
                                        <div className='flex justify-end'>
                                            <button
                                                className='shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2'
                                                onClick={handlePaymentPopUp}>
                                                <i className="fa-solid fa-xmark"></i>
                                            </button>
                                        </div>
                                        <h1 className='font-bold text-center text-xl md:text-3xl'>Subscription Plans</h1>
                                        <div className='flex md:justify-end w-[100%] md:w-[90%] md:mx-auto mt-3'>
                                            <div className='w-[100%] md:w-[40%] flex flex-col md:flex-row'>
                                                <label className='md:mt-1.5 w-[80%] mx-auto md:mx-0 md:w-[40%]'>Select Currency: </label>
                                                <Select
                                                    className='text-black w-[80%] mx-auto md:mx-0 md:w-[60%]'
                                                    value={selectedCurrency ? { value: selectedCurrency, label: CurrencyList.find(currency => currency.code === selectedCurrency).name } : null}
                                                    onChange={handleCurrencyChange}
                                                    options={CurrencyList.map((currency) => ({
                                                        value: currency.code,
                                                        label: currency.name,
                                                    }))}
                                                    styles={customStyles}
                                                />
                                            </div>
                                        </div>

                                        <div className='flex flex-col md:flex-row justify-center 2xl:gap-20 gap-10 py-5'>


                                            {plans.map(plan => (
                                                <div key={plan.name} className="flip-card2">
                                                    <div className="flip-card-inner2">
                                                        <div className="flip-card-front2 bg-white/10 rounded-2xl grid content-center border border-sky-700">
                                                            <div className='flex justify-center'>
                                                                <img src="/assets/Recuiterimg.png" alt={plan.imgAlt} />
                                                            </div>
                                                            <p className='mt-2 text-xl font-bold text-[#CD7F32]' style={{ color: plan.textColor }}>{plan.name}</p>
                                                            <p className='text-start px-5 flex justify-around'>Purchase : <span className='font-bold text-lg'>{currencySymbol} {plan.price}</span></p>
                                                        </div>
                                                        <div className="flip-card-back2 p-5 border border-yellow-500 grid content-between gap-3 rounded-2xl">
                                                            <div>
                                                                <p className='text-lg font-bold'>{plan.name}</p>
                                                                <ul className="list-inside list-disc text-gray-200 text-start text-sm mt-3">
                                                                    <p>This plan will reward you with a fantastic {plan.amount} D5ART tokens! Get ready to enjoy the amazing benefits coming your way!</p>
                                                                </ul>
                                                            </div>
                                                            <div className='flex justify-center'>
                                                                <button className='flex pt-2 pb-1 px-3 bg-black rounded-full group border border-sky-700 text-white' onClick={() => { handlePayment(plan.amount); }}>
                                                                    <button className='p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[64px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                                    <p className='mx-2 -translate-y-0.5 group-hover:-translate-x-5 transform-transition duration-500 ease-in-out'>Buy</p>
                                                                    <button className='p-2.5 rounded-full -translate-y-0.5 border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}







                    {isOpenBuy1 && (
                        <div className='px-2 md:px-10 rounded-2xl z-10 lg:w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-10 backdrop-blur-lg '>
                            <div className=' w-[100%] flex justify-center gap-2'>
                                <div className='md:w-[40%] text-white border border-sky-700 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10 animate__animated animate__fadeInUp'>
                                    <div className='flex justify-end '>
                                        <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform duration-500 ease-in-out hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' ><i class="fa-solid fa-xmark " ></i></button>
                                    </div>
                                    <div className='flex '>
                                        <div className='flex justify-center items-center w-[40%]'><img src="/assets/image 22.png" className='' />
                                        </div>
                                        <div className='w-[60%] pr-5'>
                                            <p className='mt-2 text-center text-lg'>Purchased</p>
                                            <p className="text-sm text-gray-300 text-center mt-5">D5art Token Purchased Successfully</p>
                                            <div className='flex justify-center mt-5'>
                                                <button className='flex pt-2 pb-1 px-3 bg-black rounded-full group border border-sky-700 text-white' onClick={() => { handleBtnpop(); handlePaymentCompletedButton(); }} >
                                                    <button className='p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[108px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button> <p className='mx-2 -translate-y-0.5 group-hover:-translate-x-5 transform-transition duration-500 ease-in-out text-sm'>Purchased</p> <button className='p-2.5 rounded-full -translate-y-0.5  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                </button>
                                                <Confetti active={Btn} config={confettiConfig} />


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}
                   
                    {isOpenBuy2 && (
                        <div className='px-2 md:px-10 rounded-2xl z-10 lg:w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-10 backdrop-blur-lg '>
                            <div className=' w-[100%] flex justify-center gap-2'>
                                <div className='md:w-[40%] text-white border border-sky-700 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10 animate__animated animate__fadeInUp'>
                                    <div className='flex justify-end '>
                                        <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform duration-500 ease-in-out hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' ><i class="fa-solid fa-xmark " ></i></button>
                                    </div>
                                    <div className='flex '>
                                        <div className='flex justify-center items-center w-[40%]'><img src="/assets/image 22.png" className='' />
                                        </div>
                                        <div className='w-[60%] pr-5'>
                                            <p className='mt-2 text-center text-lg'>Purchased</p>
                                            <p className="text-sm text-gray-300 text-center mt-5">D5art Token Purchased Successfully</p>
                                            <div className='flex justify-center mt-5'>
                                                <button className='flex pt-2 pb-1 px-3 bg-black rounded-full group border border-sky-700 text-white' onClick={() => { handleBtnpop(); handlePaymentCompletedButton(); }}>
                                                    <button className='p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[108px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button> <p className='mx-2 -translate-y-0.5 group-hover:-translate-x-5 transform-transition duration-500 ease-in-out text-sm'>Purchased</p> <button className='p-2.5 rounded-full -translate-y-0.5  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                                </button>
                                                <Confetti active={Btn} config={confettiConfig} />

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )}
                    
                    {isOpenBuy3 && (
                        <div className='px-10 rounded-2xl z-10 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-lg '>
                            <div className=' w-[100%] flex justify-center gap-2'>
                                <div className='w-[50%] text-white border border-sky-700 shadow-sky-400 rounded-3xl shadow-md bg-black bg-opacity-90 group-hover:transition-transform duration-500 pb-10 animate__animated animate__fadeInUp'>
                                    <div className='flex justify-end '>
                                        <button className=' shadow-xl rounded-lg text-xl text-white font-bold    transition-transform duration-500 ease-in-out hover:-translate-y-1 hover:shadow-lg mt-3 mr-2' ><i class="fa-solid fa-xmark " ></i></button>
                                    </div>
                                    <div className='flex '>
                                        <div className='flex justify-center w-[30%]'><img src="/assets/image 22.png" className='w-[10rem]' />
                                        </div>
                                        <div className='w-[70%] pr-5'><p className='mt-2 text-center text-lg'>Purchased</p>
                                            <p className="text-sm text-gray-300 text-center mt-5"> Token Purchased Successfully</p>
                                        </div>
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <button className='flex pt-2 pb-1 px-3 bg-black rounded-full group border border-sky-700 text-white' onClick={() => { handleBtnpop(); handlePaymentCompletedButton(); }}>
                                            <button className='p-1.5 rounded-full translate-y-0.5 group-hover:translate-x-[108px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button> <p className='mx-2 -translate-y-0.5 group-hover:-translate-x-5 transform-transition duration-500 ease-in-out text-sm'>Purchased</p> <button className='p-2.5 rounded-full -translate-y-0.5  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></button>
                                        </button>
                                        <Confetti active={Btn} config={confettiConfig} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>



                <div id='div6' style={{ display: active === 'div6' ? 'block' : 'none' }} className='md:absolute text-white md:w-[72%] right-0 top-32 scrollbar'>
                    <div >
                        <div className='mt-5 md:mt-2 md:m-5  md md:mr-16 border-2 border-gray-800 rounded-3xl p-5 bg-[#101010] backdrop-blur-md h-[39rem] overflow-hidden overflow-y-scroll'>
                            <h1 className='text-center font-black mt-2 text-xl text-[#F29D38]'>Raise New Query</h1>
                            <div className='md:flex py-10'>
                                <div className='md:w-[50%] md:pl-5 flex flex-col gap-5'>
                                    {/* <input
                                        type='email'
                                        name='mailId'
                                        placeholder='Mail ID'
                                        value={formData.mailId}
                                        onChange={handleChange}
                                        className='rounded-2xl px-5 py-2 bg-black border border-gray-700 outline-none'
                                    /> */}
                                    <input
                                        type='text'
                                        name='subject'
                                        placeholder='Subject'
                                        value={formData.subject}
                                        onChange={handleChange}
                                        className='rounded-2xl px-5 py-2 bg-black border border-gray-700 outline-none'
                                    />
                                    <select
                                        name='category'
                                        value={formData.category}
                                        onChange={handleChange}
                                        className='bg-black px-5 text-gray-400 outline-none py-2 rounded-2xl border border-gray-700'
                                    >
                                        <option value="Job Apply Issue">Job Apply Issue</option>
                                        <option value="Suggestion">Suggestion</option>
                                        <option value="Wallet Issue">Wallet Issue</option>
                                        <option value="others">Others</option>
                                    </select>


                                </div>
                                <div className='md:w-[50%] md:pl-10 md:pr-5 mt-5 md:mt-0'>
                                    <div className='border border-gray-700 p-3 rounded-2xl h-full'>
                                        <textarea
                                            name='description'
                                            placeholder='Explain your query in detail..'
                                            type="text"
                                            value={formData.description}
                                            onChange={handleChange}
                                            className='outline-none h-[70%] w-full bg-transparent'
                                        ></textarea>
                                        <div className='flex justify-end h-[30%]'>
                                            <button type='submit' onClick={handleSubmit} className='px-5 py-1 bg-[#0087A57D] rounded-lg text-xs'>
                                                Raise a Ticket
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 className='flex justify-between px-5 overflow-hidden overflow-y-scroll'>
                                <h1 className='font-bold'>Previous Query</h1>
                                <p className='flex gap-5 text-xs font-bold mt-1'>
                                    <p>Open: <span className='text-[#0EA4A9]'>{openCount}</span></p>
                                    <p>Resolved: <span className='text-[#0EA4A9]'>{closedCount}</span></p>
                                </p>
                            </h1>

                            {/*                             
                            <div className='py-5 md:mx-7 '>
                                
                                <table className='w-[100%] rounded-xl bg-black h-[15rem] '>
                                    <thead>
                                        <tr className='bg-gray-400 rounded-t-xl text-sm text-black '>
                                            <th className='py-2 rounded-tl-xl px-3'>S.No</th>
                                            <th className='text-start'>Subject</th>
                                            <th className='text-start'>Date & time</th>
                                            <th className='text-start'>Token Id</th>
                                            <th className='py-2 text-start rounded-tr-xl'>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.data && data.data.map((issue, index) => (
                                            <tr key={index} className='text-sm bg-black '>
                                                <td className='py-3 text-center'>{index + 1}.</td>
                                                <td>{issue.subject}</td>
                                                <td>{new Date(issue.date_created).toLocaleString()}</td>
                                                <td># {issue.token_id}</td>
                                                <td className={`text-${issue.status === 'Open' ? '#FFBA00' : '#36A90E'}`}>
                                                    {issue.status}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </div> */}


                            <div className='w-full  rounded-2xl p-5 '>
                                <div className='flex justify-end'>
                                    <p className='text-end text-sm px-3 mb-2'>Show no of entity
                                        <select className='ml-2 outline-none rounded-md bg-transparent border-[1px]' onChange={handleDropdownChange} value={entitiesPerPage}>
                                            <option className='text-black' value="5">5</option>
                                            <option className='text-black' value="10">10</option>
                                            <option className='text-black' value="15">15</option>
                                        </select>
                                    </p>
                                </div>
                                <div className='mb-5 rounded-xl overflow-hidden overflow-x-auto'>
                                    <table className='w-full text-center'>
                                        <thead>
                                            <tr>
                                                <th className='py-2  px-3'>S.No</th>
                                                <th className=''>Subject</th>
                                                <th className=''>Date & time</th>
                                                <th className=''>Token Id</th>
                                                <th className='py-2 '>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((issue, index) => (
                                                <tr key={index} className='text-sm bg-black'>
                                                    <td className='py-3 text-center'>{indexOfFirstItem + index + 1}.</td>
                                                    <td>{issue.subject}</td>
                                                    <td>{new Date(issue.date_created).toLocaleString()}</td>
                                                    <td># {issue.token_id}</td>
                                                    <td className={`text-${issue.status === 'Open' ? 'yellow-500' : 'green-500'}`}>
                                                        {issue.status}
                                                    </td>
                                                </tr>
                                            ))}
                                            {/* Add empty rows if needed */}
                                            {Array.from({ length: emptyRowCount }).map((_, index) => (
                                                <tr key={`empty-${index}`} className='h-16'>
                                                    <td colSpan="5">&nbsp;</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className='mt-2 flex justify-between text-xs px-3'>
                                    <button className='bg-white/30 rounded-md px-5 py-1' onClick={handlePrevClickTicket} disabled={currentPage === 1}>Prev</button>
                                    <button className='bg-white/30 rounded-md px-5 py-1' onClick={handleNextClickTicket} disabled={currentData.length < entitiesPerPage}>Next</button>
                                </div>
                            </div>





                            {/* <div className='py-3 flex justify-between px-5'>
        <button
          className='px-5 rounded-full border border-[#0E2DA7]'
          onClick={handlePrevClickTicket}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <button
          className='px-5 rounded-full border border-[#0E2DA7]'
          onClick={handleNextClickTicket}
          disabled={currentPage >= Math.ceil(data.length / itemsPerPage)}
        >
          Next
        </button>
      </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}