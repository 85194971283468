import React from 'react'

export default function Section1({highestPercentage,personalityData,personalityType}) {


    const downloadPdf = async() => {
        const pdfFileName = `${personalityType.toLowerCase()}.pdf`; 
        const pdfUrl = `/assets/ResultPDF/${pdfFileName}`;
        console.log("pdfUrl",pdfUrl)
        const link =await document.createElement('a');
        link.href = pdfUrl;
        link.download =await pdfFileName; 
       await link.click(); 
    };


    return (
        <div className='section-1 flex items-center w-full'>
            <div className='flex flex-col lg:flex-row gap-10 lg:gap-0 items-center' style={{ zIndex: 1 }}>
                <div className='w-[100%] lg:w-[50%]'>
                    <div className='flex justify-center'>
                        <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-[#F29D38] to-[#1CD9FF] font-medium text-xl lg:text-4xl'>{personalityType}</h1>
                    </div>
                    <div><img src='/assets/Mascots/useravatar.svg' className='w-[70%] mx-auto'></img></div>
                </div>
                <div className='w-[100%] lg:w-[50%] space-y-4'>
                    <h1 className='text-3xl font-semibold'>My result</h1>
                    <div className='flex justify-start'>
                        <h1 className='text-transparent bg-clip-text bg-gradient-to-r from-[#0EA4A9] to-[#B4BDFF] font-medium text-xl lg:text-3xl'>{highestPercentage}</h1>
                    </div>
                    <p className=' w-full leading-9'>
                        {personalityData?.overview}                    
                            </p>
                    <button className='bg-[#D9D9D95C] px-7 py-2 rounded-full font-medium'  onClick={downloadPdf}>View full Report</button>
                </div>
            </div>
            <div className='absolute md:mt-0 mt-52 flex flex-col lg:flex-row justify-around left-1/2 -translate-x-1/2 z-0'>
                <div className='bg-[#00FFC2]/5 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
                <div className='bg-[#7B00FF]/10 blur-3xl w-[20rem] h-[20rem] rounded-full'></div>
                <div className='bg-[#4B53FC]/15 blur-3xl w-[20rem] h-[20rem] rounded-full translate-y-32'></div>
            </div>
            <img src='/assets/C2.png' className='absolute top-[25%] right-[10%] w-8 h-8 fa-bounce'></img>
        </div>
    )
}
